import React, { useEffect, useState } from "react";
import styles from "./kyc.module.css";
import Modal, { ModalChild } from "../modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { verifyBvn } from "../../store/KYC/kycAction";
import Lottie from "lottie-react";
import loader from "../../assets/lottie/loader.json";

const BVN = () => {
  const dispatch = useDispatch();

  const [openInfo, setOpenInfo] = useState(false);
  const [bvnNumber, setBvnNumber] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [dob, setDob] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  // ===================================================== REDUX STATES
  const isLoading = useSelector((state) => state.userKyc.loading);

  console.log(isLoading);
  const handleProceed = (e) => {
    e.preventDefault();
    dispatch(verifyBvn({ dob, bvnNumber, middleName }));
    setBvnNumber("");
    setDob("");
  };

  useEffect(() => {
    if (dob !== "" && bvnNumber !== "" && bvnNumber.length === 11) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [bvnNumber, dob]);

  useEffect(() => {
    if (isLoading === true) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isLoading]);

  return (
    <div className={styles.bvn}>
      <ModalChild canClose modalOpen={openInfo} setModalOpen={setOpenInfo}>
        <div className={styles.whyWrap}>
          <h4 className={styles.why}>Why do you need my BVN?</h4>
          <p className={styles.whyContent}>
            Your BVN is required for compliance purposes, to increase your
            withdrawal limit, and for you to have access to more features e.g a
            virtual bank account. <br /> <br /> Please note that your BVN is
            never shared and it does not give us access to your bank account.
            Your details are safe with us.
          </p>
        </div>
      </ModalChild>
      <h2>Upgrade Account</h2>
      <p className={styles.subHead}>
        Upgrade your Astro account with more <br /> personal information
      </p>
      <form>
        <label>Date of Birth (D.O.B) </label>
        <input
          type="date"
          value={dob}
          onChange={(e) => setDob(e.target.value)}
          placeholder="Enter BVN"
        />
        <br />
        <label>Bank Verification Number(BVN) </label>
        <input
          type="number"
          value={bvnNumber}
          onChange={(e) => setBvnNumber(e.target.value)}
          placeholder="Enter BVN"
        />
        <br />
        <label>Middle Name (optional)</label>
        <input
          type="text"
          value={middleName}
          onChange={(e) => setMiddleName(e.target.value)}
          placeholder="Enter Middle Name"
        />
        <p style={{
                  fontSize: 10,
            textAlign: "left"
        }}>Please only enter your middle name if you have a middle name attached to your bvn, and if so, please use the exact middle name attached to your bvn</p>
        <div className={styles.question}>
          <p className={styles.questionMark}>?</p>
          <p onClick={() => setOpenInfo(true)} className={styles.questionLink}>
            Why do you need my BVN?
          </p>
        </div>
        {loading === true ? (
          <div className={styles.lottieWrap}>
            <Lottie
              style={{
                width: "70px",
                height: "70px",
                color: "#fff",
                padding: "0px",
                margin: "0px",
              }}
              animationData={loader}
              loop={true}
            />
          </div>
        ) : (
          <button
            onClick={handleProceed}
            disabled={isDisabled}
            className={styles.proceed}
          >
            Upgrade Now
          </button>
        )}
      </form>
    </div>
  );
};

export default BVN;
