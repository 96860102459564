import React, { useState } from "react";
import style from "./emailVerify.module.css";
import { Link } from "react-router-dom";
import PinInput from "react-pin-input";
import logo from "../../../assets/images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { getUserById, resendOtp, verifyEmailWithOtp } from "../../../store/auth/authActions";
import { useEffect } from "react";
import Lottie from "lottie-react";
import loader from "../../../assets/lottie/loader.json";

const EmailVerify = () => {
  const dispatch = useDispatch();

  const [filledPin, setFilledPin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");

  const isLoading = useSelector((state) => state.auth.loading);
  const userData = useSelector((state) => state.auth.user);
  const otherEmail = useSelector((state) => state.auth.fullUser.email);


  const handleVerify = (e) => {
    e.preventDefault();
    dispatch(
      verifyEmailWithOtp({
        otp,
      })
    );
    setTimeout(() => {
      dispatch(getUserById())
    }, 2000)
  };

  const handleResendCode = () => {
    dispatch(resendOtp());
  };

  useEffect(() => {
    if (isLoading === true) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isLoading]);



  return (
    <div className={style.login}>
      <Link to="/">
        <div className={style.logo}>
          <img src={logo} alt="astroafrica.site/v1" />
        </div>
      </Link>
      <div className={style.log}>
        <div className={style.left}>
          <div className={style.contLeft}>
            <h2>Verify Your Email Address</h2>
            <p>
              A 6-digit code has been sent to{" "}
              <span style={{
                color: "#40196D"
              }} >
                {userData.email ? userData.email : otherEmail}
              </span>
            </p>
            <p>Kindly input the code below</p>

            <div className={style.form}>
              <form action="">
                <div className={style.inputs}>
                  <PinInput
                    length={6}
                    initialValue=""
                    secret={true}
                    onChange={(value, index) => {
                      // setPinFilled( pinFilled === true ? false : false)
                    }}
                    type="numeric"
                    inputMode="number"
                    style={{ padding: "0px" }}
                    inputStyle={{ border: "1px solid", borderColor: "#909090" }}
                    inputFocusStyle={{
                      border: "2px solid",
                      borderColor: "#40196D",
                    }}
                    onComplete={(value, index) => {
                      setFilledPin(true);
                      setOtp(value);
                      console.log(value);
                    }}
                    autoSelect={true}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                  />
                </div>
                {/* <p className={style.resendCode} > Didn't get a code? <a href="#">Resend Code</a> </p> */}
                {loading === true ? (
                  <div className={style.lottieWrap}>
                    <Lottie
                      style={{
                        width: "70px",
                        height: "70px",
                        color: "#fff",
                        padding: "0px",
                        margin: "0px",
                      }}
                      animationData={loader}
                      loop={true}
                    />
                  </div>
                ) : (
                  <button
                    onClick={handleVerify}
                    className={style.pay}
                    type="submit"
                    disabled={!filledPin}
                  >
                    Verify
                  </button>
                )}
              </form>
              <p className={style.resendCode}>
                {" "}
                Didn't get code ?{" "}
                <span onClick={handleResendCode}>Resend Code</span>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default EmailVerify;
