import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCryptoTransactionsById,
  getGiftcardTransactionsById,
} from "../../store/transactions/transActions";
import { Icon } from "@iconify/react";
import IfEmpty from "../ifEmpty/IfEmpty";
import style from "./transactions.module.css";
import empty1 from "../../assets/images/empty1.svg";
// import empty2 from "../../assets/images/empty2.svg";
import Lottie from "lottie-react";
import loader from "../../assets/lottie/loader.json";
import moment from "moment";

const Crypto = ({ setTransBarOpen }) => {
  const [loading, setLoading] = useState(false);
  const [currNo, setCurrNo] = useState(0);

  const dispatch = useDispatch();

  const isLoading = useSelector(
    (state) => state.crypto.cryptoInwardTransLoading
  );
  const cryptoInwardTransactions = useSelector(
    (state) => state.crypto.cryptoInwardTransactions
  );
  const walletData = useSelector((state) => state.wallet.data);

  const handleTransactionId = (transId) => {
    setTransBarOpen(true);
    console.log(transId);
    dispatch(fetchCryptoTransactionsById({ transId }));
  };

  useEffect(() => {
    if (isLoading === true) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isLoading]);

  return (
    <>
      {loading === true ? (
        <div className={style.lottieWrap}>
          <Lottie
            style={{
              width: "70px",
              height: "70px",
              color: "#fff",
              padding: "0px",
              margin: "0px",
            }}
            animationData={loader}
            loop={true}
          />
        </div>
      ) : (
        <div className={style.table}>
          {cryptoInwardTransactions.transactions &&
          cryptoInwardTransactions.transactions.length > 0 ? (
            <div>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      <div className={style.tableHead}>
                        <h5>Coin </h5>{" "}
                        <Icon
                          className={style.arrowCon}
                          icon="ph:arrows-down-up-fill"
                        />{" "}
                      </div>
                    </th>
                    <th>
                      <div className={style.tableHead}>
                        <h5>Date</h5>{" "}
                        <Icon
                          className={style.arrowCon}
                          icon="ph:arrows-down-up-fill"
                        />{" "}
                      </div>
                    </th>
                    <th>
                      <div className={style.tableHead}>
                        <h5>Reference ID</h5>{" "}
                        <Icon
                          className={style.arrowCon}
                          icon="ph:arrows-down-up-fill"
                        />{" "}
                      </div>
                    </th>
                    <th>
                      <div className={style.tableHead}>
                        <h5>Coin Amount</h5>{" "}
                        <Icon
                          className={style.arrowCon}
                          icon="ph:arrows-down-up-fill"
                        />
                      </div>{" "}
                    </th>
                    <th>
                      <div className={style.tableHead}>
                        <h5>Payout Amount</h5>{" "}
                        <Icon
                          className={style.arrowCon}
                          icon="ph:arrows-down-up-fill"
                        />
                      </div>{" "}
                    </th>
                    <th>
                      <div className={style.tableHead}>
                        <h5>Status</h5>{" "}
                        <Icon
                          className={style.arrowCon}
                          icon="ph:arrows-down-up-fill"
                        />{" "}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {cryptoInwardTransactions.transactions?.map((item) => (
                    <tr
                      className={style.fullRow}
                      onClick={(transId) => {
                        handleTransactionId((transId = item.id));
                      }}
                      key={item.id}
                    >
                      <td className={style.user}>
                        <div className={style.image}>
                          {/* <img src={item.GiftCard.image} alt="" /> */}
                        </div>
                      </td>
                      <td>
                        <div className={style.name}>
                          <h6>{item.CoinType.name}</h6>
                          <p>{item.wallet_address}</p>
                        </div>
                      </td>
                      <td>
                        <div className={style.date}>
                          <h6>
                            {item.created_at
                              ? moment(item.created_at).format("Do, MMM YYYY")
                              : ""}
                          </h6>
                          <p>
                            at{" "}
                            {item.created_at
                              ? moment(item.created_at).format("hh:mm a")
                              : ""}
                          </p>
                        </div>
                      </td>
                      <td>
                        <h6 className={style.invoice}>{item.reference}</h6>
                      </td>
                      <td>
                        <h6 className={style.amount}>
                          {new Intl.NumberFormat("NG", {
                            style: "currency",
                            currency: "USD",
                          }).format(item.amount)}
                        </h6>
                      </td>
                      <td>
                        <h6 className={style.amount}>
                          {new Intl.NumberFormat("NG", {
                            style: "currency",
                            currency: item.CreditCurrency.code,
                          }).format(item.payout_amount)}
                        </h6>
                      </td>
                      <td>
                        {item.status === "DONE" && (
                          <p className={style.success}>Successful</p>
                        )}
                        {item.status === "SUBMITTED" && (
                          <p className={style.pending}>Pending</p>
                        )}
                        {item.status === "PROCESSING" && (
                          <p className={style.pending}>Pending</p>
                        )}
                        {item.status === "FAILED" && (
                          <p className={style.failed}>Declined</p>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* =====================================  THE MOBILE VIEW STARTS */}
              <div className={style.transList}>
                {cryptoInwardTransactions.transactions?.map((item) => (
                  <div className={style.eachTrans}>
                    <div className={style.et}>
                      <div className={style.etPic}>
                        {/* <img
                          src={item.GiftCard.image ? item.GiftCard.image : ""}
                          alt="astrofrica.site/giftcard"
                        /> */}
                      </div>
                      <div className={style.etTitle}>
                        <h4>{item.CoinType.name ? item.CoinType.name : ""}</h4>
                        <p>Sell Crypto</p>
                      </div>
                      <div className={style.etTime}>
                        <p>{item.created_at.slice(0, 10)}</p>
                        <span>
                          at {item.created_at.slice(11, 16)}{" "}
                          {item.created_at.slice(11, 13) > 11 ? " PM" : " AM"}
                        </span>
                      </div>
                    </div>
                    <div className={style.etDet}>
                      <p>Wallet Address</p>
                      <p>{item.wallet_address}</p>
                    </div>
                    <div className={style.etDet}>
                      <p>Amount</p>
                      <p>
                        {new Intl.NumberFormat("NG", {
                          style: "currency",
                          currency: "USD",
                        }).format(item.amount)}
                      </p>
                    </div>
                    <div className={style.etDet}>
                      <p>Status</p>
                      {item.status === "DONE" && (
                        <p className={style.success}>Successful</p>
                      )}
                      {item.status === "SUBMITTED" && (
                        <p className={style.pending}>Pending</p>
                      )}
                      {item.status === "PROCESSING" && (
                        <p className={style.pending}>Pending</p>
                      )}
                      {item.status === "FAILED" && (
                        <p className={style.failed}>Failed</p>
                      )}
                    </div>
                    <div className={style.etDet2}>
                      {/* <p></p> */}
                      <p>{item.comment === null ? "" : item.comment}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className={style.emptyCont}>
              <IfEmpty
                title="sorry, you do not have any crypto transactions"
                image={empty1}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Crypto;
