import React, { useEffect, useState } from "react";
import style from "./settings.module.css";
import { Icon } from "@iconify/react";
import Modal from "../modal/Modal";
import { AddCircle, ArrowDown2, CloseCircle } from "iconsax-react";
import { useDispatch, useSelector } from "react-redux";
import {
  addBankAccount,
  delBankAccount,
  getAccountName,
  getBankAccounts,
  getBanks,
} from "../../store/settings/settingsActions";
import { clearAllBanks } from "../../store/settings/settingsSlice";
import Lottie from "lottie-react";
import loader from "../../assets/lottie/loader.json";
import IfEmpty from "../ifEmpty/IfEmpty"
import empty2 from '../../assets/images/empty2.svg'
import Prompt from "../prompt/Prompt";
import { ghAccounts, mobileMoney } from './data';

const CryptoBank = ({ openPrompt, setOpenCryptoPrompt, bankID, setBankID }) => {
  const [formModalOpen, setFormModalOpen] = useState(false);
  const [bank, setBank] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [acctNo, setAcctNo] = useState("");
  const [openBank, setOpenBank] = useState(false);
  const [bankType, setBankType] = useState("");
  const [openBankType, setOpenBankType] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nameLoading, setNameLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [acctLoading, setAcctLoading] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [nameDisabled, setNameDisabled] = useState(true);
  const [isFull, setIsFull] = useState(false);
  const [loadModal, setLoadModal] = useState(false);
  // const [openPrompt, setOpenPrompt] = useState(false);

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.settings.loading);
  const isNameLoading = useSelector((state) => state.settings.nameLoading);
  const isSaveLoading = useSelector((state) => state.settings.saveLoading);
  const isAcctLoading = useSelector((state) => state.settings.acctLoading);
  const allBanks = useSelector((state) => state.settings.allBanks);
  const allAccounts = useSelector((state) => state.settings.allAccounts);
  const allCryptoAccounts = useSelector(
    (state) => state.settings.allCryptoAccounts
  );
  const accountDets = useSelector((state) => state.settings.accountDets);

  console.log(allAccounts);

  useEffect(() => {
    if (isLoading === true) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    if (isNameLoading === true) {
      setNameLoading(true);
    } else {
      setNameLoading(false);
    }

    if (isSaveLoading === true) {
      setSaveLoading(true);
      setLoadModal(true);
    } else {
      setSaveLoading(false);
      setLoadModal(false);
    }

    if (isAcctLoading === true) {
      setAcctLoading(true);
    } else {
      setAcctLoading(false);
    }

    if (bankType === "ng-account") {
      setNameDisabled(true);
    } else if (bankType === "gh-mobile" || bankType === "gh-account") {
      setNameDisabled(false);
    }
  }, [isNameLoading, isLoading, isSaveLoading, isAcctLoading, bankType]);

  const toggleBank = () => {
    setOpenBank(!openBank);
  };

  const handleBank = (bankname, code) => {
    setOpenBank(false);
    setBank(bankname);
    setBankCode(code);
  };

  const toggleBankType = () => {
    setOpenBankType(!openBankType);
  };

  const handleBankType = (title) => {
    setOpenBankType(false);
    setBankType(title);
    setBank("");

    if (title === "ng-account") {
      dispatch(getBanks({ title: "NG" }));
    } else if (title === "gh-account" || title === "gh-mobile") {
      dispatch(clearAllBanks());
    }
  };

  const handleAccountChange = (e) => {
    setAcctNo(e.target.value);
    setAccountName("");
  };

  const handleDelete = (bid) => {
    dispatch(delBankAccount({ bid }));
    dispatch(getBankAccounts());
  };

  const handleAcctName = (e) => {
    setAccountName(e.target.value);
  };

  const handleSave = (e) => {
    e.preventDefault();
    setFormModalOpen(false);

    dispatch(
      addBankAccount({
        accountNumber: acctNo,
        accountName: accountName,
        bankCode: bankCode,
        bankName: bank,
        accountType: bankType,
      })
    );

    setAcctNo("");
    setAccountName("");
    setBankType("");
    setBank("");
  };

  useEffect(() => {
    if (accountDets !== "") {
      setAccountName(accountDets.accountName);
    }
  }, [accountDets]);

  return (
    <div className={style.accounts}>
      {acctLoading === true && (
        <div className={style.lottieWrap}>
          <Lottie
            style={{
              width: "70px",
              height: "70px",
              color: "#fff",
              padding: "0px",
              margin: "0px",
            }}
            animationData={loader}
            loop={true}
          />
        </div>
      )}
      {allCryptoAccounts.cryptotAccounts &&
      allCryptoAccounts.cryptotAccounts.length > 0 ? (
        allCryptoAccounts.cryptotAccounts.map((item) => (
          <div className={style.account}>
            <div className={style.acLeft}>
              <h6>{item.coin_type}</h6>
              <p>{item.wallet_address}</p>
              <span>{item.coin_name}</span>
            </div>
            <div className={style.acRight}>
              <Icon
                className={style.acCon}
                onClick={() => {
                  setBankID(item.id);
                  setOpenCryptoPrompt(true);
                  console.log(bankID);
                }}
                icon="material-symbols:delete-forever-outline-rounded"
              />
            </div>
          </div>
        ))
      ) : (
        <IfEmpty
          image={empty2}
          title="You haven't added any crypto address, would you like to add one?"
        />
      )}
    </div>
  );
};

export default CryptoBank