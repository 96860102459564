import React, { useEffect, useRef, useState } from "react";
import styles from "./kyc.module.css";
import { ArrowDown2, Camera, TickCircle, TickSquare } from "iconsax-react";
import { browserName } from "react-device-detect";
import Lottie from "lottie-react";
import loader from "../../assets/lottie/loader.json";
import { baseUrl } from "../../store/config";
import cogoToast from "cogo-toast";
import { useDispatch, useSelector } from "react-redux";
import { identityTypes } from "./data";
import { verifyIdentity } from "../../store/KYC/kycAction";
import { resetHasVerifiedId } from "../../store/KYC/kycSlice";

const IdVerification = ({ setOpenModal3 }) => {
  const selfieRef = useRef();
  const idRef = useRef();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [openIdType, setOpenIdType] = useState(false);
  const [selfieLoading, setSelfieLoading] = useState(false);
  const [selfieLink, setSelfieLink] = useState("");
  const [idLoading, setIdLoading] = useState(false);
  const [idLink, setIdLink] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [idType, setIdType] = useState("");
  const [idParam, setIdParam] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  // ===================================================== REDUX STATES
  const token = useSelector((state) => state.auth.token);
  const isLoading = useSelector((state) => state.userKyc.loading);
  const verifiedId = useSelector((state) => state.userKyc.hasVerifiedId);

  const getToken = () => {
    if (token !== "") {
      return token;
    } else {
      cogoToast.error("Could Not get Token");
    }
  };

  const toggleBank = () => {
    setOpenIdType((prev) => {
      if (prev === false) {
        return true;
      } else if (prev === true) {
        return false;
      }
    });
  };

  const handleUpgrade = (e) => {
    e.preventDefault();
    dispatch(verifyIdentity({ idNumber, idParam, selfieLink, idLink }));
    setIdLink("");
    setSelfieLink("");
    setIdNumber("");
    setIdParam("");
    setIdType("");
  };

  // ===================================================== SELFIE CHANGE HANDLER AND UPLOAD FILE FUNCTIONS

  const selfieChangeHandler = () => {
    let files = selfieRef.current.files[0];
    setSelfieLoading(true);
    uploadSelfie(files);
    console.log(files);
  };
  //  THE SELFIE UPLOAD FUNCTION
  const uploadSelfie = (file) => {
    // - the base url plus the api
    const url = `${baseUrl}/misc/upload-file`;
    const xhr = new XMLHttpRequest();
    const fd = new FormData();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    // --(This is for the token passed on headers check yours)
    xhr.setRequestHeader("x-auth-token", `${getToken()}`);

    xhr.onreadystatechange = (e) => {
      // console.log(xhr)
      if (xhr.readyState === 4 && xhr.status === 201) {
        const response = JSON.parse(xhr.responseText);
        setSelfieLoading(false);
        cogoToast.success("Image uploaded successfully!", {
          position: "top-right",
        });
        // dispatch and add card images - i dipatched here to my redux store for the response gotten from the backend api
        setSelfieLink(response.data.publicUrl);
        // dispatch(uploadProfilePic(response));
      }
      //   cors errors
      if (xhr.readyState === 4 && xhr.status === 0) {
        console.log(xhr);
        setSelfieLoading(false);
        cogoToast.warn(
          "There might be an issue with this image, please optimize or try another image."
        );
      }
      if (xhr.status === 413 || xhr.status === 422) {
        cogoToast.warn(
          "There might be an issue with this image, please optimize or try another image."
        );
        setSelfieLoading(false);
      }
    };

    fd.append("tags", "browser_upload");
    fd.append("file", file);
    xhr.send(fd);
  };

  // ===================================================== SELFIE CHANGE HANDLER AND UPLOAD FILE FUNCTIONS

  const idChangeHandler = () => {
    let files = idRef.current.files[0];
    setIdLoading(true);
    uploadId(files);
    console.log(files);
  };
  //  THE ID UPLOAD FUNCTION
  const uploadId = (file) => {
    // - the base url plus the api
    const url = `${baseUrl}/misc/upload-file`;
    const xhr = new XMLHttpRequest();
    const fd = new FormData();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    // --(This is for the token passed on headers check yours)
    xhr.setRequestHeader("x-auth-token", `${getToken()}`);

    xhr.onreadystatechange = (e) => {
      // console.log(xhr)
      if (xhr.readyState === 4 && xhr.status === 201) {
        const response = JSON.parse(xhr.responseText);
        setIdLoading(false);
        cogoToast.success("Image uploaded successfully!", {
          position: "top-right",
        });
        // dispatch and add card images - i dipatched here to my redux store for the response gotten from the backend api
        setIdLink(response.data.publicUrl);
        console.log(response.data.publicUrl);
        // dispatch(uploadProfilePic(response));
      }
      //   cors errors
      if (xhr.readyState === 4 && xhr.status === 0) {
        console.log(xhr);
        setIdLoading(false);
        cogoToast.warn(
          "There might be an issue with this image, please optimize or try another image."
        );
      }
      if (xhr.status === 413 || xhr.status === 422) {
        cogoToast.warn(
          "There might be an issue with this image, please optimize or try another image."
        );
        setIdLoading(false);
      }
    };

    fd.append("tags", "browser_upload");
    fd.append("file", file);
    xhr.send(fd);
  };

  const handleIdentity = (e) => {
    setOpenIdType(false);
    setIdType(e.target.textContent);
  };

  // HANDLE CLEAR FORM
  useEffect(() => {
    if (verifiedId === true) {
      setOpenModal3(false);
      setTimeout(() => {
        dispatch(resetHasVerifiedId());
      }, 5000);
    }
  }, [verifiedId]);

  // HANDLE DISABLED BUTTON
  useEffect(() => {
    if (
      idLink !== "" &&
      selfieLink !== "" &&
      idType !== "" &&
      idNumber !== ""
    ) {
      setIsDisabled(false);
    } else setIsDisabled(true);
  }, [idLink, selfieLink, idType, idNumber]);

  // HANDLE BANK CLOSE ON CLICK OUT
  useEffect(() => {
    let handler = (e) => {
      if (e.target.id !== "bank") {
        setOpenIdType(false);
      } else if (e.target.id === "bank") {
        toggleBank();
      }
      if (e.target.id === "bankDrop") {
        setOpenIdType(true);
      }
      console.log(e.target.id);
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  // HANDLE SET LOADING
  useEffect(() => {
    if (isLoading === true) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isLoading]);

  return (
    <div className={styles.idVerification}>
      <h2 className={styles.idvTitle}>Upgrade Account</h2>
      <p className={styles.idvSubtitle}>
        Upgrade your Astro account with more <br /> personal information
      </p>
      <div className={styles.uploadSelfieWrap}>
        <label>
          Upload Selfie<span>*</span>
        </label>
        <div className={styles.contentIcon}>
          <p className={styles.idContent}>
            Please take or upload your current <br /> Passport photo
          </p>
          <button
            style={{ position: "relative" }}
            className={styles.changePhoto}
          >
            {selfieLoading === true ? (
              <div className={styles.lottieWrap}>
                <Lottie
                  style={{
                    width: "70px",
                    height: "70px",
                    color: "#fff",
                    padding: "0px",
                    margin: "0px",
                  }}
                  animationData={loader}
                  loop={true}
                />
              </div>
            ) : selfieLink !== "" ? (
              <TickCircle color="#22C55E" variant="Bold" />
            ) : (
              <Camera size="20" variant="Bulk" color="#5f177f" />
            )}
            <input
              type="file"
              ref={selfieRef}
              accept="image/png, image/jpg, image/jpeg"
              onChange={() => selfieChangeHandler()}
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                background: "skyblue",
                opacity: "0",
                cursor: "pointer",
              }}
            />
          </button>
        </div>
      </div>

      <hr
        style={{
          width: "100%",
          border: "none",
          margin: "24px 0",
          background: "#eeeeee",
          height: 1,
        }}
      />
      <div className={styles.form}>
        <form>
          <div className={styles.labelPut}>
            <label htmlFor="bank"> ID Type</label>
            <div className={styles.select}>
              <div
                style={{ pointerEvents: loading === true ? "none" : "" }}
                className={styles.selectInput}
              >
                <input
                  id="bank"
                  name="bank"
                  type="text"
                  disabled={browserName === "Firefox" ? false : true}
                  value={idType}
                  placeholder="Select ID type"
                />
                {loading === true ? (
                  <div className={styles.lottieWrapDrop}>
                    <Lottie
                      style={{
                        width: "70px",
                        height: "70px",
                        color: "#fff",
                        padding: "0px",
                        margin: "0px",
                      }}
                      animationData={loader}
                      loop={true}
                    />
                  </div>
                ) : (
                  <ArrowDown2
                    style={{
                      transform:
                        openIdType === true
                          ? "translateY( -50%) rotate(-0deg)"
                          : "translateY( -50%) rotate(-90deg)",
                      pointerEvents: "none",
                    }}
                    className={styles.dropDown}
                    size="20"
                    color="#909090"
                  />
                )}
              </div>
              <div
                style={{
                  height: openIdType === true ? "120px" : "0px",
                }}
                className={styles.option}
              >
                {identityTypes?.map((item) => (
                  <p
                    key={item.id}
                    id="bankDrop"
                    onClick={(e) => {
                      handleIdentity(e);
                      setIdParam(item.param);
                      console.log(item.param);
                    }}
                  >
                    {item.name}
                  </p>
                ))}
              </div>
              {/* <input type="text" placeholder='Enter Last Name' /> */}
            </div>
          </div>

          {/* ID NUMBER */}
          <div className={styles.labelPut}>
            <label htmlFor="number">ID Number</label>
            <div className={styles.input}>
              <input
                onChange={(e) => {
                  setIdNumber(e.target.value);
                }}
                type="number"
                placeholder="Enter ID Number"
                value={idNumber}
              />
            </div>
          </div>

          {/* UPLOAD ID IMAGE */}
          <div className={styles.uploadSelfieWrap} style={{ marginBottom: 30 }}>
            <label>
              Upload ID Image (Front)<span>*</span>
            </label>
            <div className={styles.contentIcon}>
              <p className={styles.idContent}>
                Upload a copy of your <br /> International Passport, Driver’s
                license,
                <br /> NIN Card or Voter’s Card.
              </p>
              <button
                style={{ position: "relative" }}
                className={styles.changePhoto}
              >
                {idLoading ? (
                  <div className={styles.lottieWrap}>
                    <Lottie
                      style={{
                        width: "70px",
                        height: "70px",
                        color: "#fff",
                        padding: "0px",
                        margin: "0px",
                      }}
                      animationData={loader}
                      loop={true}
                    />
                  </div>
                ) : idLink !== "" ? (
                  <TickCircle color="#22C55E" variant="Bold" />
                ) : (
                  <Camera size="20" variant="Bulk" color="#5f177f" />
                )}
                <input
                  type="file"
                  ref={idRef}
                  accept="image/png, image/jpg, image/jpeg"
                  onChange={() => idChangeHandler()}
                  style={{
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                    width: "100%",
                    height: "100%",
                    background: "skyblue",
                    opacity: "0",
                    cursor: "pointer",
                  }}
                />
              </button>
            </div>
          </div>
          {loading === true ? (
            <div className={styles.lottieWrap}>
              <Lottie
                style={{
                  width: "70px",
                  height: "70px",
                  color: "#fff",
                  padding: "0px",
                  margin: "0px",
                }}
                animationData={loader}
                loop={true}
              />
            </div>
          ) : (
            <button
              disabled={isDisabled}
              onClick={(e) => {
                handleUpgrade(e);
              }}
              className={styles.proceed}
            >
              Upgrade Now
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default IdVerification;
