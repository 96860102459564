import { Icon } from "@iconify/react";
import React from "react";
import { Children } from "react";
import style from "./modal.module.css";
import { Add } from "iconsax-react";



const Modal2 = ({ children, modalOpen, setModalOpen, canClose, closeFunct }) => {
  return (
    <div
      className={modalOpen === false ? style.modal : style.modalFade}
      // style={{
      //   // display: modalOpen === true ? "flex" : "none"
      // }}
    >
      <div className={style.popUp2}>
        {canClose === true ? (
          <Add size="32"
            className={style.closeModalCon2}
            onClick={closeFunct}
            icon="ic:round-close"
          />
        ) : (
          <></>
        )}
        {children}
      </div>
    </div>
  );
};

export default Modal2;
