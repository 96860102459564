import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { baseUrl } from '../config'
import cogoToast from "cogo-toast";


// ========================================================= SIGNUP / CREATE USER


export const userSignUp = createAsyncThunk(
  "userSignUp",
  async (values, { rejectWithValue, dispatch, getState }) => {
    console.log(values);
    try {
      const res = await axios.post(`${baseUrl}/user-account`, {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        username: values.username,
        phoneNumber: values.phoneNumber,
        country: values.country,
        countryTag: values.countryTag,
        referral_code: values.referral,
      });
      if (res.status === 201) {
          cogoToast.success('Your account has been created successfully, Please check your email for a verification link!')
          localStorage.setItem("emailAstro", values.email )
          return res.data;
        }
        console.log(res)
    } catch (error) {
        console.log(error.response)
        if (error.response.status === 400) {
          cogoToast.error("Email or Username Already Exists")
          return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

// ========================================================= LOGIN 

export const userSignIn = createAsyncThunk(
  "userSignIn",
  async (values, { rejectWithValue, dispatch }) => {
    console.log(values);
    try {
      const res = await axios.post(
        `${baseUrl}/user-account/sign-in`,
        {
          email: values.email,
          password: values.password,
        }
      );
      if (res.status === 200) {
        cogoToast.success("User Successfully Logged in")
        console.log(res)
        return res.data;
      }
    } catch (error) {
        console.log(error)
        if (error.response.status === 400) {
          console.log(error.response.data.i)
          return rejectWithValue(error.response);
        } else if (error.response.status === 410) {
            cogoToast.warn("Please verify your email")
          // window.location = '/email-verify';
            return rejectWithValue(error.response);
        } else if (error.response.status === 404) {
          cogoToast.error("Please ensure your credentials are correct");
          return rejectWithValue(error.response);
        } else if (error.code === "ERR_NETWORK") {
          cogoToast.error("Please Make sure you're connected to the internet");
          alert("Please connect to the internet");
          return rejectWithValue(error.response);
        } else {
          return rejectWithValue(error.response);
        }
    }
  }
);

// ========================================================= VERIFY OTP 

export const verifyEmailWithOtp = createAsyncThunk(
  "verifyEmailWithOtp",
  async (values, { rejectWithValue, dispatch, getState }) => {
    const userId = getState().auth.user.user_id
    try {
      const res = await axios.post(`${baseUrl}/user-account/${userId}/verify-email-ref`,
      {
        otpCode: values.otp.toString()
          // token: getState().auth.token
        },
        { 
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": getState().auth.token.toString()
          },
        } 
        );
        console.log(res)
      if (res.status === 200) {
          return res;
        }
        cogoToast.success("Your Email has been successfully verified")
        return res;
    } catch (error) {
      console.log(error);
      if (error.response.status === 400) {
        cogoToast.error("Email already in Use")
      } else if (error.response.status === 406) { 
        cogoToast.error("The OTP Code is incorrect")
      }else if(error.response.status === 401){
        dispatch({ type: 'auth/logout' })
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

// ================================================== VERIFY EMAIL CODE
export const verifyEmailCode = createAsyncThunk(
  "verifyEmailCode",
  async (values, { rejectWithValue, dispatch, getState }) => {
    try {
      const res = await axios.post(`${baseUrl}/user-account/${values.userId}/verify-email-ref`,
      {
        otpCode: values.code
        },
        { 
          headers: {
            "Content-Type": "application/json",
          },
        } 
        );
      if (res.status === 200) {
          return res;
        }
        return res;
    } catch (error) {
      if (error.response.status === 400) {
        cogoToast.error(`${error.response.data.message}`)
        return rejectWithValue(`Oops, looks like the email has been verified or an invalid link!`);
      } else if (error.response.status === 404) { 
        cogoToast.error(`${error.response.data.message}`)
        return rejectWithValue(error.response.data.message);
      }
      else if (error.response.status === 406) { 
        cogoToast.error(`${error.response.data.message}`)
        return rejectWithValue(error.response.data.message);
      }
      else if (error.response.status === 410) { 
        cogoToast.error(`${error.response.data.message}`)
        return rejectWithValue(error.response.data.message);
      }
      else if(error.response.status === 401){
        dispatch({ type: 'auth/logout' })
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(`Oops, looks like the email has been verified or an invalid link!`);
      }
    }
  }
);

// ========================================================= RESET PASSWORD ACTION

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (values, { rejectWithValue, dispatch, getState }) => {
    try {
        const res = await axios.post(
          `${baseUrl}/user-account/reset-password`,
          {
            email: values.email,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "x-auth-token": getState().auth.token,
            },
          }
        );
        if (res.status === 200 || res.status === 201 ) {
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 404) {
        cogoToast.error(
          "This user does not exist, please ensure email is correct"
        );
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else if (error.code === "ERR_NETWORK") {
        alert(error.code);
        cogoToast.error("Please ensure you have a stable network connection");
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

// ========================================================= GET NEW USER

export const getUser = createAsyncThunk(
  "getUser",
  async (values, { rejectWithValue, dispatch, getState }) => {
    console.log(values);
    try {
        const res = await axios.post(
          `${baseUrl}/user-account/reset-password`,
          {
            email: values.email,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "x-auth-token": getState().auth.token,
            },
          }
        );
        if (res.status === 200) {
          console.log(res)
        return res.data;
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 404) {
        console.log(error.response.data.message);
        cogoToast.error(
          "This user does not exist, please ensure email is correct"
        );
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);


// ========================================================= GET NEW USER

export const completeReset = createAsyncThunk(
  "completeReset",
  async (values, { rejectWithValue, dispatch, getState }) => {
    console.log(values);
    try {
        const res = await axios.post(
          `${baseUrl}/user-account/complete-password-reset`,
          {
            resetCode: values.resetCode,
            newPassword: values.newPassword.toString(),
          },
          {
            headers: {
              "Content-Type": "application/json",
              "x-auth-token": getState().auth.token,
            },
          }
        );
        if (res.status === 200) {
          console.log(res)
          cogoToast.success("Password successfully reset")
        return res.data;
      }
    } catch (error) {
      console.log(error);
      if (error.response.data.message === "OTP not found") {
        cogoToast.error("Please check, Link may be invalid or expired");
        return rejectWithValue(error.response);
      } else if (error.response.data.message === "Invalid incomplete/input") {
        cogoToast.error("Please input a longer password")
        return rejectWithValue(error.response);
      } else if (error.response.status === 404) {
        console.log(error.response.data.message);
        cogoToast.error(
          "This user does not exist, please ensure email is correct"
        );
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);


// ========================================================= GET NEW USER

export const updateUserDetails = createAsyncThunk(
  "updateUserDetails",
  async ({firstName, lastName, phoneNo}, { rejectWithValue, dispatch, getState }) => {
    const id = getState().auth.user.user_id;
    const country = getState().auth.user.country;
    const countryTag = getState().auth.user.country_tag;
    const userName = getState().auth.fullUser.username;
    const stateImage = getState().settings.profileImage.length > 0 ? getState().settings.profileImage[0].publicUrl : null;
    console.log(stateImage);
    try { 
        const res = await axios.put(
          `${baseUrl}/user-account/${id}`, {
            firstName: firstName,
            lastName: lastName,
            username: userName,
            phoneNumber: phoneNo,
            country: country,
            countryTag: countryTag,
            imageUrl: stateImage
          },{
            headers: {
              "Content-Type": "application/json",
              "x-auth-token": getState().auth.token,
            },
          }
        );
        if (res.status === 200) {
          console.log(res)
          cogoToast.success("Profile details Updated Successfully", {
            position:"top-right"
          })
        return res.data;
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        console.log(error.response.data.message);
        cogoToast.error("This page has expired, please try signing in");
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);


// ========================================================= RESEND EMAIL OTP

export const resendOtp = createAsyncThunk(
  "resendOtp",
  async (values, { rejectWithValue, dispatch, getState }) => {
    try { 
        const res = await axios.post(
          `${baseUrl}/user-account/${values.userId}/resend-email-otp`, 
          {

          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (res.status === 200) {
          cogoToast.success("Verification link sent successfully!, please check your email")
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 403) {
        console.log(error.response.data.message);
        return rejectWithValue(error.response);
      }
      else if (error.response.status === 410) { 
        cogoToast.error(`${error.response.data.message}`)
        return rejectWithValue(error.response.data.message);
      }
       else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);


// ========================================================= GET USER BY ID

export const getUserById = createAsyncThunk(
  "getUserById",
  async (values, { rejectWithValue, dispatch, getState }) => {
    const id = getState().auth.user.user_id;
    console.log(id);
    try { 
        const res = await axios.get(
          `${baseUrl}/user-account/${id}`, {
            headers: {
              "Content-Type": "application/json",
              "x-auth-token": getState().auth.token,
            },
          }
        );
        if (res.status === 200 || res.status === 201) {
          console.log(res);
          return res;
        }
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        console.log(error.response.data.message);
        cogoToast.error("This page has expired, please try signing in");
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);