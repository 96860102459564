import { ArrowDown2 } from 'iconsax-react'
import React, { useState } from 'react'
import style from './bills.module.css'

const wallets = [
    { id: 1, title:"GTB"},
    { id: 2, title:"First Bank"},
    { id: 3, title:"UBA"},
    { id: 4, title:"Polaris"},
    { id: 5, title:"Stanbic"},
    { id: 6, title:"KeyStone"},
]

const Cable = () => {

  const [openWallet, setOpenWallet] = useState(false)
  const [wallet, setWallet] = useState("")

  const [openCableTv, setOpenCableTv] = useState(false)
  const [cableTv, setCableTv] = useState("")

  const [openPlan, setOpenPlan] = useState(false)
  const [plan, setPlan] = useState("")
  
   const toggleWallet = () => { 
    setOpenWallet(!openWallet)
  }
  const handleWallet = (e) => { 
    setOpenWallet(false);
    setWallet(e.target.textContent);
  }

// ----------------------------------------- cable TV functions

  const toggleCableTv = () => { 
    setOpenCableTv(!openCableTv)
  }
  const handleCableTv = (e) => { 
    setOpenCableTv(false);
    setCableTv(e.target.textContent);
  }

  // ----------------------------------------- plan functions

  const togglePlan = () => { 
    setOpenPlan(!openPlan)
  }
  const handlePlan = (e) => { 
    setOpenPlan(false);
    setPlan(e.target.textContent);
  }

  return (
    <div className={style.buttom} >
        <div className={style.form} > 
          <form action="">

            <div className={style.labelPut} >
                <label htmlFor="country"> Select Wallet</label>
                <div className={style.select} >
                      <div onClick={toggleWallet} className={style.selectInput} >
                          <input className={ openWallet === true ? style.selectActive : style.selectDormant } name="wallet" type="text" disabled={true} value={wallet} placeholder='Please select a Wallet' />
                          <ArrowDown2 
                          style={{
                              transform: openWallet === true ? "translateY( -50%) rotate(-540deg)" : "translateY( -50%) rotate(0deg)" 
                          }}
                           className={style.dropDown} size="20" color="#909090" variant="Bold"/>
                      </div>
                      <div 
                      style={{
                          height: openWallet === true ? "120px" : "0px"
                      }}
                      className={style.option}>
                          {
                              wallets?.map(wallet => (
                                  <p key={wallet.id} onClick={handleWallet} > { wallet.title } </p>
                              ))
                          }
                      </div>
                    {/* <input type="text" placeholder='Enter Last Name' /> */}
                </div>
            </div>
            
          {/* ============================================== select a cable Tv */}
          
          
          
          <div className={style.labelPut} >
                <label htmlFor="country"> Select Cable TV</label>
                <div className={style.select} >
                      <div onClick={toggleCableTv} className={style.selectInput} >
                          <input className={ openCableTv === true ? style.selectActive : style.selectDormant } name="cableTv" type="text" disabled={true} value={cableTv} placeholder='Please select cable TV' />
                          <ArrowDown2 
                          style={{
                              transform: openCableTv === true ? "translateY( -50%) rotate(-540deg)" : "translateY( -50%) rotate(0deg)" 
                          }}
                           className={style.dropDown} size="20" color="#909090" variant="Bold"/>
                      </div>
                      <div 
                      style={{
                          height: openCableTv === true ? "120px" : "0px"
                      }}
                      className={style.option}>
                          {
                              wallets?.map(cable => (
                                  <p key={cable.id} onClick={handleCableTv} > { cable.title } </p>
                              ))
                          }
                      </div>
                    {/* <input type="text" placeholder='Enter Last Name' /> */}
                </div>
            </div>
  
            {/* ============================================== select a cable Tv */}
          
          
          <div className={style.labelPut} >
                <label htmlFor="country"> Select Plan</label>
                <div className={style.select} >
                      <div onClick={togglePlan} className={style.selectInput} >
                          <input className={ openPlan === true ? style.selectActive : style.selectDormant } name="plan" type="text" disabled={true} value={plan} placeholder='Please select cable TV' />
                          <ArrowDown2 
                          style={{
                              transform: openPlan === true ? "translateY( -50%) rotate(-540deg)" : "translateY( -50%) rotate(0deg)" 
                          }}
                           className={style.dropDown} size="20" color="#909090" variant="Bold"/>
                      </div>
                      <div 
                      style={{
                          height: openPlan === true ? "120px" : "0px"
                      }}
                      className={style.option}>
                          {
                              wallets?.map(plan => (
                                  <p key={plan.id} onClick={handlePlan} > { plan.title } </p>
                              ))
                          }
                      </div>
                    {/* <input type="text" placeholder='Enter Last Name' /> */}
                </div>
            </div>
  
            {/* ============================================== select a cable Tv */}
            
            <div className={style.labelPut} >
              <label htmlFor="amount">Select Wallet</label>
              <div className={style.input} >
                <input type="number" placeholder='0.00' />
              </div>
            </div>

            <div className={style.labelPut} >
              <label htmlFor="smartcard">Smartcard Number</label>
              <div className={style.input} >
                <input type="number" placeholder='0.00' />
              </div>
            </div>

            



            <button className={style.pay} >Pay</button>
          </form>
        </div>
      </div>
  )
}

export default Cable