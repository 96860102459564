import { ArrowDown2 } from "iconsax-react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAllCards,
  getCardByUid,
  getFiatCurrencies,
} from "../../store/Calc/calcActions";
import { setHeader } from "../../store/settings/settingsSlice";
import style from "./rates.module.css";
import logo from "../../assets/images/logo2.png";
import Lottie from "lottie-react";
import loader from "../../assets/lottie/loader.json";
import { clearCardDetails } from "../../store/Calc/calcSlice";
import CryptoCalc from "../calculator/CryptoCalc";
import CryptoRates from "./CryptoRates";
import cogoToast from "cogo-toast";
import {browserName} from 'react-device-detect'

const Rates = ({ calcRoute, setCalcRoute }) => {
  const [currency, setCurrency] = useState("");
  const [openCurrency, setOpenCurrency] = useState(false);
  const [card, setCard] = useState("");
  const [cardImg, setCardImg] = useState("");
  const [openCard, setOpenCard] = useState(false);
  const [amount, setAmount] = useState();
  const [cardCat, setCardCat] = useState("");
  const [openCardCat, setOpenCardCat] = useState(false);
  const [rate, setRate] = useState(0);
  const [currRate, setCurrRate] = useState(0);
  const [cardRate, setCardRate] = useState("");
  const [totalRate, setTotalRate] = useState("");
  const [currNo, setCurrNo] = useState(0);
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);

  const empty = "";

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const calcData = useSelector((state) => state.calc.data);
  const calcLoading = useSelector((state) => state.calc.cardCatLoading);
  const calcCards = useSelector((state) => state.calc.cards);
  const calcCardDetails = useSelector((state) => state.calc.cardDetails);
  const walletData = useSelector((state) => state.wallet.data);

  const currRef = useRef();


  /* THIS IS WHERE THE CURRIENCIES ARE CALLED AND THIS IS HAPPENING ONCE
    THE RATE CALCULATOR PAGE IS OPENED ONR RELOADED */
  useEffect(() => {
    dispatch(getFiatCurrencies());
    dispatch(getAllCards());
  }, []);

  // HANDLE CURRENCY CLOSE ON CLICK OUT
  useEffect(() => {
    let handler = (e, id) => {
      if (e.target.id !== "curr") {
        setOpenCurrency(false);
        console.log(openCurrency);
      } else if (e.target.id === "curr") {
        toggleCurrency();
        console.log("Okay we feel you are the id")
      }
      if (e.target.id === "currDrop") {
        setOpenCurrency(true);
      }
      console.log(e.target.id)
    };
    document.addEventListener("mousedown", handler);


    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  // HANDLE CARD CLOSE ON CLICK OUT
  useEffect(() => {
    let handler = (e) => {
      if (e.target.id !== "card") {
        setOpenCard(false);
      } else if (e.target.id === "card") {
        toggleCard();
      }
      if (e.target.id === "cardDrop") {
        setOpenCard(true);
      }
      // console.log(currRef.current.name)
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  // HANDLE CARDCAT CLOSE ON CLICK OUT
  useEffect(() => {
    let handler = (e) => {
      if (e.target.id !== "cardCat") {
        setOpenCardCat(false);
      } else if (e.target.id === "cardCat") {
        toggleCardCat();
      }
      if (e.target.id === "cardCatDrop") {
        setOpenCardCat(true);
      }
      // console.log(currRef.current.name)
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  // useEffect(() => {
  //   let handler = (e) => {
  //     if (e.target.name === "acctType") {
  //       setOpenCurrency(false);
  //       console.log("Yepp, this is it ", openCurrency  );
  //       }
  //     }
  //   document.addEventListener("mousedown", handler)

  //   return () => {
  //     document.removeEventListener("mousedown", handler)
  //   }
  // }, []);

  // useEffect(() => {
  //   let handler = (e) => {
  //     if (e.target.id !== "cardDrop") {
  //         setOpenCard(false)
  //       console.log(e.target.id)
  //     }
  //         // console.log(currRef.current.name)
  //       }
  //   document.addEventListener("mousedown", handler)

  //   return () => {
  //     document.removeEventListener("mousedown", handler)
  //   }
  //   }, []);
  // useEffect(() => {
  //   let handler = (e) => {
  //     if (e.target.id !== "card-cat-id") {
  //         setOpenCardCat(false);
  //       console.log(e.target.id)
  //     }
  //         // console.log(currRef.current.name)
  //       }
  //   document.addEventListener("mousedown", handler)

  //   return () => {
  //     document.removeEventListener("mousedown", handler)
  //   }
  //   }, []);

  useEffect(() => {
    if (calcLoading === true) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [calcLoading]);

  const toggleCurrency = () => {
    setOpenCurrency((prevCurr) => {
      if (prevCurr === true) {
        return false;
      } else if (prevCurr === false) {
        return true;
      }
    });
  };

  const handleChangeCard = () => {};

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleCurrency = (code, rate) => {
    setCurrency(code);
    setCurrRate(rate);
    setOpenCurrency(false);
  };

  const toggleCard = () => {
    setOpenCard((prevCard) => {
      if (prevCard === true) {
        return false;
      } else if (prevCard === false) {
        return true;
      }
    });
  };

  const handleCard = (image, name, uid) => {
    setOpenCard(false);
    setCard(name);
    setCardImg(image);
    dispatch(getCardByUid({ uid }));
    setCardCat(empty);
    setAmount(empty);
    // setFormPI({
    //     ...formPI, country: e.target.textContent
    // })
    // console.log(formPI)
  };

  const toggleCardCat = () => {
    setOpenCardCat((prevCardCat) => {
      if (prevCardCat === true) {
        return false;
      } else if (prevCardCat === false) {
        return true;
      }
    });
  };

  const handleCardCat = (desc, rate) => {
    setOpenCardCat(false);
    setCardCat(desc);
    setRate(rate);
    setAmount(empty);
  };

  const handleProceed = () => {
    navigate("/signup");
  };

  useEffect(() => {
    dispatch(setHeader("Rate Calculator"));
  }, []);

  useEffect(() => {
    setCardRate(parseInt(currRate) * rate);
    setTotalRate(cardRate * parseInt(amount));
    console.log(currRate, rate, cardRate, parseInt(amount));
  }, [rate, currRate, amount, code]);

  return (
    <div className={style.calc}>
      <div className={style.logoImg}>
        <img src={logo} alt="" />
      </div>
      <div className={style.switch}>
        <div className={style.switchTitle}>
          <p
            className={
              calcRoute === "giftCards" ? style.switchPeeHi : style.switchPee
            }
            onClick={() => {
              setCalcRoute("giftCards");
            }}
          >
            Giftcard
          </p>
          <p
            className={
              calcRoute !== "giftCards" ? style.switchPeeHi : style.switchPee
            }
            onClick={() => {
              setCalcRoute("crypto");
            }}
          >
            Crypto
          </p>
        </div>
        <div className={style.switchSlider}>
          <div
            style={{
              left: calcRoute === "giftCards" ? "0%" : "50%",
            }}
            className={style.switchSlideBar}
          ></div>
        </div>
      </div>
      <div className={style.top}>
        <h5>Rate Calculator</h5>
        <p>
          {calcRoute === "giftCards"
            ? "Calculate the current rates of your gift cards using the Astro Africa rates calculator. Input your gift cards details to get the equivalent in Naira and Cedis."
            : "Calculate the current rates of your crypto using the Astro Africa rates calculator. Input your coin details to get the equivalent in Naira and Cedis."}
        </p>
      </div>
      {calcRoute === "giftCards" ? (
        <div className={style.form}>
          <form action="">
            <div className={style.labelPut}>
              <label htmlFor="acct_type"> Select Currency </label>
              <div className={style.select}>
                <div id="curr"
                  name="acctType" className={style.selectInput}>
                  <input
                    style={{pointerEvents: "none"}}
                    type="text"
                    disabled={browserName === "Firefox" ? false : true}
                    value={currency}
                    placeholder="Pick a Currency"
                  />
                  <ArrowDown2
                    // id="currIcon"
                    style={{
                      transform:
                        openCurrency === true
                          ? "translateY( -50%) rotate(-540deg)"
                          : "translateY( -50%) rotate(0deg)",
                      pointerEvents: "none",
                    }}
                    className={style.dropDown}
                    size="20"
                    color="#909090"
                    variant="Bold"
                  />
                </div>
                <div
                  // ref={currRef}
                  style={{
                    height: openCurrency === true ? "fit-content" : "0px",
                    maxHeight: "120px",
                  }}
                  className={style.option}
                >
                  {calcData.fiat ? (
                    calcData.fiat.map((item) => (
                      <p
                        ref={currRef}
                        id="currDrop"
                        key={item.id}
                        onClick={(code, rate) => {
                          handleCurrency(
                            (code = item.code),
                            (rate = item.we_buy)
                          );
                          setCode(item.code);
                        }}
                      >
                        {item.code} Wallet
                      </p>
                    ))
                  ) : (
                    <p>No wallets to show</p>
                  )}
                </div>
                {/* <input type="text" placeholder='Enter Last Name' /> */}
              </div>
            </div>
            <div className={style.labelPut}>
              <label htmlFor="acct_type"> Select Card</label>
              <div className={style.select}>
                <div
                  style={{ pointerEvents: "none" }}
                  className={style.cardImgDrop2}
                >
                  {cardImg ? (
                    <img src={cardImg} alt="astroafrica.site/card.png" />
                  ) : null}
                </div>
                <div id="card"
                  name="acctType" className={style.selectInput}>
                  {/* <img src={} alt="" /> */}
                  <input
                    
                    style={{ paddingLeft: cardImg ? "56px" : "12px", pointerEvents: "none" }}
                    type="text"
                    disabled={browserName === "Firefox" ? false : true}
                    value={card}
                    placeholder="Select Card"
                  />
                  <ArrowDown2
                    style={{
                      transform:
                        openCard === true
                          ? "translateY( -50%) rotate(-540deg)"
                          : "translateY( -50%) rotate(0deg)",
                      pointerEvents: "none",
                    }}
                    className={style.dropDown}
                    size="20"
                    color="#909090"
                    variant="Bold"
                  />
                </div>
                <div
                  style={{
                    height: openCard === true ? "fit-content" : "0px",
                    maxHeight: "220px",
                  }}
                  className={style.option}
                >
                  {calcCards.length > 0 ? (
                    calcCards.map((item) => (
                      <div
                        id="cardDrop"
                        key={item.id}
                        onClick={(image, name, uid) => {
                          handleCard(
                            (image = item.image),
                            (name = item.name),
                            (uid = item.uid)
                          );
                          dispatch(clearCardDetails());
                        }}
                        className={style.cardItem}
                      >
                        <div
                          style={{ pointerEvents: "none" }}
                          className={style.cardImgDrop}
                        >
                          <img
                            src={item.image}
                            alt={`astroafrica.site/${item.title}`}
                            style={{ pointerEvents: "none" }}
                          />
                        </div>
                        <p style={{ pointerEvents: "none" }}> {item.name} </p>
                      </div>
                    ))
                  ) : (
                    <p>Cards have not loaded </p>
                  )}
                </div>
                {/* <input type="text" placeholder='Enter Last Name' /> */}
              </div>
            </div>
            <div className={style.labelPut}>
              <label htmlFor="acct_type"> Select Card Category</label>
              <div className={style.select}>
                <div id="cardCat"
                  name="acctType" className={style.selectInput}>
                  <input
                    style={{pointerEvents: "none"}}
                    type="text"
                    disabled={browserName === "Firefox" ? false : true}
                    value={cardCat}
                    placeholder="Pick a Category"
                  />
                  {calcLoading === true ? (
                    <div className={style.lottieWrapDrop}>
                      <Lottie
                        style={{
                          width: "70px",
                          height: "70px",
                          color: "#fff",
                          padding: "0px",
                          margin: "0px",
                        }}
                        animationData={loader}
                        loop={true}
                      />
                    </div>
                  ) : (
                    <ArrowDown2
                      style={{
                        transform:
                          openCardCat === true
                            ? "translateY( -50%) rotate(-540deg)"
                            : "translateY( -50%) rotate(0deg)",
                        pointerEvents: "none",
                      }}
                      className={style.dropDown}
                      size="20"
                      color="#909090"
                      variant="Bold"
                    /> 
                  )}
                </div>
                <div
                  style={{
                    height: openCardCat === true ? "fit-content" : "0px",
                    maxHeight: "220px",
                  }}
                  className={style.option}
                >
                  {calcCardDetails.length > 0 ? (
                    calcCardDetails.map((item) => (
                      <div
                        id="cardCatDrop"
                        key={item.id}
                        onClick={(desc, rate) => {
                          handleCardCat(
                            (desc = item.description),
                            (rate = item.rate)
                          );
                        }}
                        className={style.cardItem}
                      >
                        {/* <img src={item.GiftCard.image} alt={`astroafrica.site/${item.title}`} /> */}
                        <p style={{ pointerEvents: "none" }}>
                          {" "}
                          {item.description}{" "}
                        </p>
                      </div>
                    ))
                  ) : (
                    <p>There are no categories here</p>
                  )}
                </div>
                {/* <input type="text" placeholder='Enter Last Name' /> */}
              </div>
            </div>

            <div className={style.labelPut}>
              <label htmlFor="number"> Amount </label>
              <div className={style.input}>
                <input
                  onChange={handleAmountChange}
                  type="number"
                  name="acct_number"
                  placeholder="Enter Amount"
                  value={amount}
                />
              </div>
            </div>
          </form>
          <div className={style.rate}>
            <p>
              Card Rate:
              <span>
                {cardRate > 0
                  ? new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: code,
                    }).format(cardRate)
                  : "0.00"}
                /$
              </span>
            </p>
            <p>
              Rate:
              {/* {currency === "NGN" && " ₦"}
            {currency === "GHS" && " ¢"}
            {currency === "USD" && " $"} */}
              {totalRate > 0
                ? new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: code,
                  }).format(totalRate)
                : "0.00"}
            </p>
            <div
              style={{
                width: "100%",
                borderTop: "1px #90909039 dashed",
                margin: "2rem 0 0 0 ",
              }}
            ></div>
            <p>
              The calculator is updated everyday to ensure that you are always
              up to date with the current rates. Sell gift cards at the best
              rates after using the calculator on Astro Africa.
            </p>
          </div>
          <button onClick={handleProceed} className={style.proceed}>
            Proceed to Trade
          </button>
        </div>
      ) : (
        <CryptoRates />
      )}
    </div>
  );
};

export default Rates;
