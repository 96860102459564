export const ghAccounts = [
  {
    id: 396,
    code: "10550214",
    name: "The Hongkong and Shanghai Banking Corporation Limited",
  },
  { id: 397, code: "11088200", name: "Giro Elszamolasforgalmi Zrt." },
  { id: 398, code: "11276282", name: "First National Bank Ghana Limited" },
  { id: 399, code: "11299458", name: "OMNIBANK GHANA LIMITED" },
  { id: 400, code: "11319374", name: "GHL Bank Ltd" },
  { id: 401, code: "11350352", name: "Consolidated Bank Ghana Limited" },
  { id: 402, code: "1812142W72", name: "GH PREPAID" },
  { id: 403, code: "190815670S", name: " VISA" },
  { id: 404, code: "2006168ICS", name: "Standard Chartered Bk Ghana Int" },
  { id: 405, code: "2006228LG0", name: "UNITED BANK FOR AFRICA (GHANA) LTD" },
  { id: 406, code: "20112200", name: "Partner Banka d.d." },
  { id: 407, code: "20313200", name: "Agricultural Development Bank Limited" },
  { id: 408, code: "20313400", name: "Bank of Ghana" },
  { id: 409, code: "20313500", name: "ABSA (Barclays Bank of Ghana Limited)" },
  { id: 410, code: "20313600", name: "GCB Bank Limited" },
  { id: 411, code: "20313800", name: "Universal Merchant Bank" },
  { id: 412, code: "20313900", name: "National Investment Bank Ltd" },
  { id: 413, code: "20314100", name: "Societe Generale Ghana Limited" },
  { id: 414, code: "20314200", name: "Standard Chartered Bank Ghana Limited" },
  {
    id: 415,
    code: "20321900",
    name: "Societe Generale Haitienne de Banque S.A. (Sogebank)",
  },
  { id: 416, code: "20497300", name: "BNG Bank N V" },
  { id: 417, code: "22031960", name: "Cal Bank Limited" },
  { id: 418, code: "25449292", name: "DBS Group Holdings Ltd" },
  { id: 419, code: "25528546", name: "Financial Brokerage Group (Fbg)" },
  { id: 420, code: "25544088", name: "Arb Apex Bank Limited" },
  { id: 421, code: "25549518", name: "Stock Exchange Of Hong Kong Ltd, The" },
  { id: 422, code: "25562284", name: "Guaranty Trust Bank (Ghana) Ltd" },
  { id: 423, code: "25570554", name: "Fidelity Bank Ghana Ltd." },
  {
    id: 424,
    code: "25785380",
    name: "Banque Sahelo-Saharienne Pour LInvestissment et le Commerce (Ghana) Lt",
  },
  { id: 425, code: "25817360", name: "Guaranty Trust Bank (UK) Limited" },
  { id: 426, code: "25892776", name: "Bank of Baroda (Ghana) Limited" },
  { id: 427, code: "25897096", name: "Gatehouse Bank Plc" },
  { id: 428, code: "26153374", name: "Access Bank (Ghana) PLC" },
  { id: 429, code: "26153460", name: "Noble Group Ltd" },
  { id: 430, code: "26156818", name: "Ghazanfar Bank" },
  {
    id: 431,
    code: "26545578",
    name: "Social Security And National Insurance Trust",
  },
  { id: 432, code: "26675842", name: "Energy Bank Ghana Limited" },
  { id: 433, code: "26699422", name: "Ghana Revenue Authority" },
  { id: 434, code: "26708398", name: "Pacific Eagle Asset Management Ltd" },
  { id: 435, code: "26852136", name: "Independent Petroleum Group" },
  {
    id: 436,
    code: "26935088",
    name: "Controller And Accountant-GeneralS Department",
  },
  { id: 437, code: "26935170", name: "Partners Group AG" },
  { id: 438, code: "26955918", name: "Compass Global Holdings Pty Ltd" },
  { id: 439, code: "27096388", name: "Global Exchange Centre Limited" },
  { id: 440, code: "27108818", name: "Volta River Authority" },
  { id: 441, code: "27127004", name: "Norma Group Holding" },
  { id: 442, code: "27208826", name: "Tronox Global Holdings Pty Limited" },
  { id: 443, code: "27209142", name: "Cqlt Saargummi Technologies SAR.l" },
  { id: 444, code: "27257744", name: "Pigeon Corporation" },
  {
    id: 445,
    code: "27349456",
    name: "Ping An Of China Asset Management (Hong Kong) Company Ltd",
  },
  { id: 446, code: "27352014", name: "Gebr. Heinemann Se And Co.KG" },
  { id: 447, code: "27404306", name: "Cgnpc Huasheng Investment Limited" },
  { id: 448, code: "27451510", name: "Premium Bank Ghana Limited" },
  { id: 449, code: "27452668", name: "G.H. Financials Ltd" },
  { id: 450, code: "27457008", name: "Asahi Group Holdings, Ltd." },
  { id: 451, code: "27462484", name: "Heritage Bank Limited" },
  { id: 452, code: "27464082", name: "Grabtaxi Holdings PTE LTD" },
  { id: 453, code: "27466360", name: "Al Ghurair International Exchange" },
  { id: 454, code: "27547278", name: "Dogan Sirketler Grubu Holding AS" },
  { id: 455, code: "50480908", name: "GN Bank Limited" },
  { id: 456, code: "60002121", name: "Banque Populaire de Rabat-Kenitra" },
  { id: 457, code: "60003190", name: "Prudential Bank Ltd" },
  { id: 458, code: "60003191", name: "First Atlantic Bank Ltd" },
  { id: 459, code: "60003193", name: "FBN Bank (Ghana) Limited" },
  { id: 460, code: "96683220", name: "Privredna banka Zagreb d.d." },
  { id: 461, code: "96788653", name: "Ghana International Bank plc" },
  { id: 462, code: "96793657", name: "Republic Bank (Ghana) Ltd" },
  { id: 463, code: "96855152", name: "Guaranty Trust Bank Kenya Limited" },
  { id: 464, code: "97151844", name: "Stanbic Bank Ghana Limited" },
  { id: 465, code: "97155860", name: "Bank of Africa - Ghana" },
  { id: 466, code: "98357706", name: "ZENITH BANK (GHANA) LIMITED" },
  { id: 467, code: "99730556", name: "FINANCIJSKA AGENCIJA" },
  { id: 468, code: "GH130100", name: "Ecobank Ghana (GH130100)" },
];

export const mobileMoney = [
    {
  "id": "79",
  "code": "AIRTELTIGO",
  "name": "AIRTEL-TIGO"
},
 {
  "code": "VODAFONE",
   "id": "80",
   "name": "Vodafone"
 },
{
 "id": "82",
 "code": "MTN",
 "name": "MTN Mobile"
 }
]


export const levels = [
  {id: 1, level: "1", dailyLimit: 1000, tag: "one" },
  {id: 2, level: "2", dailyLimit: 2000, tag: "two" },
  {id: 3, level: "3", dailyLimit: 3000, tag: "three" },
  {id: 4, level: "4", dailyLimit: 4000, tag: "four" },
]

export const identityTypes = [
  { id: 1, name: "International Passport", param: "INTERNATIONAL_PASSPORT" },
  { id: 2, name: "Voters Card", param: "VOTER_VARD" },
  {
    id: 3,
    name: "National Identity Number (NIN)",
    param: "NATIONAL_IDENTITY_NUMBER",
  },
  { id: 4, name: "Drivers License", param: "DRIVERS_LICENCE" },
];