import { createSlice } from "@reduxjs/toolkit";
import {
  getAllSettings,
  getKycSettingsById,
  getKycStatusById,
  getUserDailyWithdrawal,
  verifyAddress,
  verifyBvn,
  verifyIdentity,
} from "./kycAction";

const initialState = {
  loading: false,
  kycStatLoading: false,
  hasVerifiedBvn: false,
  hasVerifiedId: false,
  settingsLoading: false,
  addressLoading: false,
  dailyStat: {},
  dailyStatLoading: false,
  kycStats: {},
  settings: {},
  currentSettings: {},
};

const kycSlice = createSlice({
  name: "userKyc",
  initialState,
  reducers: {
    resetHasVerifiedBvn: (state) => {
      state.hasVerifiedBvn = false;
    },
    resetHasVerifiedId: (state) => {
      state.hasVerifiedId = false;
    },
    resetVerifyingAddress: (state) => {
      state.isVerifyingAddress = false
    }
  },
  extraReducers: {
    // ========================== EXTRA RERDUCER HANDLING VERIFY BVN
    [verifyBvn.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [verifyBvn.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.hasVerifiedBvn = true;
    },
    [verifyBvn.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    // ========================== EXTRA RERDUCER HANDLING GET ALL KYC SETTINGS
    [getAllSettings.pending]: (state, { payload }) => {
      state.settingsLoading = true;
    },
    [getAllSettings.fulfilled]: (state, { payload }) => {
      state.settingsLoading = false;
      state.settings = payload.data;
    },
    [getAllSettings.rejected]: (state, { payload }) => {
      state.settingsLoading = false;
    },

    // ========================== EXTRA RERDUCER HANDLING GET KYC STATUS BY ID
    [getKycStatusById.pending]: (state, { payload }) => {
      state.kycStatLoading = true;
    },
    [getKycStatusById.fulfilled]: (state, { payload }) => {
      state.kycStatLoading = false;
      state.kycStats = payload.data;
    },
    [getKycStatusById.rejected]: (state, { payload }) => {
      state.kycStatLoading = false;
    },

    // ========================== EXTRA RERDUCER HANDLING GET DAILY WITHDRAWALS
    [getUserDailyWithdrawal.pending]: (state, { payload }) => {
      state.dailyStatLoading = true;
    },
    [getUserDailyWithdrawal.fulfilled]: (state, { payload }) => {
      state.dailyStatLoading = false;
      state.dailyStat = payload.data;
    },
    [getUserDailyWithdrawal.rejected]: (state, { payload }) => {
      state.dailyStatLoading = false;
    },

    // ========================== EXTRA RERDUCER HANDLING GET PERSONAL KYC SETTINGS
    [getKycSettingsById.pending]: (state, { payload }) => {
      state.dailyStatLoading = true;
    },
    [getKycSettingsById.fulfilled]: (state, { payload }) => {
      state.dailyStatLoading = false;
      state.currentSettings = payload.data;
    },
    [getKycSettingsById.rejected]: (state, { payload }) => {
      state.dailyStatLoading = false;
    },

    // ========================== EXTRA RERDUCER HANDLING VERIFY IDENTITY
    [verifyIdentity.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [verifyIdentity.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.hasVerifiedId = true;
    },
    [verifyIdentity.rejected]: (state, { payload }) => {
      state.loading = false;
    },
   
    // ========================== EXTRA RERDUCER HANDLING VERIFY ADDRESS
    [verifyAddress.pending]: (state, { payload }) => {
      state.addressLoading = true;
    },
    [verifyAddress.fulfilled]: (state, { payload }) => {
      state.addressLoading = false;
    },
    [verifyAddress.rejected]: (state, { payload }) => {
      state.addressLoading = false;
    },
  },
});

export default kycSlice.reducer;
export const { resetHasVerifiedBvn, resetHasVerifiedId } = kycSlice.actions;
