import { Image } from 'iconsax-react'
import React, { useState } from 'react'
import { useEffect } from 'react'
import PinInput from 'react-pin-input'
import { useDispatch, useSelector } from 'react-redux'
import { setPin } from '../../store/settings/settingsActions'
import style from './settings.module.css'
import Lottie from "lottie-react";
import loader from "../../assets/lottie/loader.json";

const SetPin = () => {

  const dispatch = useDispatch()
  const isSaveLoading = useSelector(state => state.settings.saveLoading)

  const [fieldFilled, setFieldFilled] = useState(false);
  const [pinNo, setPinNo] = useState("")
  const [saveLoader, setSaveLoader] = useState(false)

  const handleSetPin = (e) => {
    e.preventDefault()
    dispatch(setPin({pinNo}))
  }

  useEffect(() => {
    if (isSaveLoading === true) {
      setSaveLoader(true)
    } else {
      setSaveLoader(false)
    }
   } ,[isSaveLoading])

  
  return (
    <div className={style.profile}>
      <div className={style.header}>
        <h2>Set Transaction Pin</h2>
        <p>
          Enter your preferred 5-digit pin for all your transactions on Astro
        </p>
      </div>
      <div className={style.form}>
        <form action="">
          <div className={style.inputs}>
            <PinInput
              length={5}
              initialValue=""
              secret={true}
              onChange={(value, index) => {

              }}
              type="numeric"
              inputMode="number"
              style={{ padding: "10px", margin: "0 auto 0 auto" }}
              inputStyle={{ border: "1px solid", borderColor: "#909090" }}
              inputFocusStyle={{ border: "2px solid", borderColor: "#40196D" }}
              onComplete={(value, index) => {
                setFieldFilled(true);
                setPinNo(value);
              }}
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
          </div>
          {/* <p className={style.resendCode} > Didn't get a code? <a href="#">Resend Code</a> </p> */}
          {saveLoader === true ? (
            <div className={style.lottieWrap}>
              <Lottie
                style={{
                  width: "70px",
                  height: "70px",
                  color: "#fff",
                  padding: "0px",
                  margin: "0px",
                }}
                animationData={loader}
                loop={true}
              />
            </div>
          ) : (
            <button
              onClick={handleSetPin}
              className={style.save}
              type="submit"
              disabled={!fieldFilled}
            >
              Set Transaction Pin
            </button>
          )}
        </form>
        {/* <p className={style.resendCode} > Already a member? <Link to="/login">Sign In</Link> </p> */}
      </div>
    </div>
  );
}

export default SetPin