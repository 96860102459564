import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import calcReducer from './Calc/calcSlice';
import settingsReducer from './settings/settingsSlice';
import transReducer from './transactions/transSlice';
import walletReducer from './wallet/walletSlice';
import cryptoReducer from './crypto/cryptoSlice';
import kycReducer from './KYC/kycSlice';
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import thunk from 'redux-thunk';


const rootPersistConfig = {
  key: "root",
  storage,
  blacklist: [""],
};

const appReducer = combineReducers({
  auth: authReducer, 
  wallet: walletReducer,
  calc: calcReducer,
  settings: settingsReducer,
  trans: transReducer,
  crypto: cryptoReducer,
  userKyc: kycReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "auth/logout") {
    state = undefined;
  }
  return appReducer(state, action);
}

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: [thunk],
});

export const persistor = persistStore(store);
