import { createSlice } from "@reduxjs/toolkit";
import { fetchCryptoTransactions, fetchCryptoTransactionsById, getAllCoins, getCoinById, tradeNewCoin } from "./cryptoActions";

const initialState = {
  loading: false,
  coins: [],
  coinNetworks: [],
  coinRates: [],
  coinLoading: false,
  coinRateLoading: false,
  barcodeLoading: false,
  cryptoInwardTransLoading: false,
  cryptoInwardTransactions: [],
  newcoinData: "",
};

const cryptoSlice = createSlice({
  name: "crypto",
  initialState, 
  reducers: {
    clearCoins: (state) => {
      state.coins = [];
    },
    clearRates: (state) => { 
      state.coinRates = [];
    },
    clearNewcoinData: (state) => {
      state.newcoinData = "";
    },
    // fond networks available in coin selected
    filterCoins: (state, { payload }) => {
      console.log(payload)
      state.coinNetworks = state.coins.find((coin)=> coin.id === payload).networks;
    },
    clearCoinNetwork: (state) => {
      state.coinNetworks = [];
    },
  },
  extraReducers: {
    // ========================== EXTRA RERDUCER HANDLING GET ALL COINS ACTION
    [getAllCoins.pending]: (state, { payload }) => {
      state.loading = true;
      state.coinLoading = true;
    },
    [getAllCoins.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.coinLoading = false;
      state.coins = payload.data;
    },
    [getAllCoins.rejected]: (state, { payload }) => {
      state.loading = false;
      state.coinLoading = false;
    },

    // ========================== EXTRA RERDUCER HANDLING GET ALL COINS ACTION
    [getCoinById.pending]: (state, { payload }) => {
      state.coinRateLoading = true;
    },
    [getCoinById.fulfilled]: (state, { payload }) => {
      state.coinRateLoading = false;
      state.coinRates = payload.data;
    },
    [getCoinById.rejected]: (state, { payload }) => {
      state.coinRateLoading = false;
    },


    // ========================== EXTRA RERDUCER HANDLING NEW COIN TRADE
    [tradeNewCoin.pending]: (state, { payload }) => {
      state.barcodeLoading = true;
    },
    [tradeNewCoin.fulfilled]: (state, { payload }) => {
      state.barcodeLoading = false;
      state.newcoinData = payload.data;
    },
    [tradeNewCoin.rejected]: (state, { payload }) => {
      state.barcodeLoading = false;
    },


    // ========================== EXTRA RERDUCER HANDLING FETCH CRYPTO TRANSACTIONS
    [fetchCryptoTransactions.pending]: (state, { payload }) => {
      state.cryptoInwardTransLoading = true;
    },
    [fetchCryptoTransactions.fulfilled]: (state, { payload }) => {
      state.cryptoInwardTransLoading = false;
      state.cryptoInwardTransactions = payload.data;
    },
    [fetchCryptoTransactions.rejected]: (state, { payload }) => {
      state.cryptoInwardTransLoading = false;
    },

  },
});

export default cryptoSlice.reducer;
export const {
  clearSignupSuccess,
  logout,
  clearHasResetPwd,
  clearCoins,
  clearRates,
  clearNewcoinData,
  filterCoins,
  clearCoinNetwork
} = cryptoSlice.actions;
