import { createSlice } from "@reduxjs/toolkit";
import {
  addBankAccount,
  addCryptoAccount,
  changePin,
  completeResetPin,
  createCryptoWallet,
  delBankAccount,
  delCryptoAccount,
  deleteProfileAccount,
  getAccountName,
  getBankAccounts,
  getBanks,
  getCryptoAccounts,
  getCryptoCoins,
  proceedToTrade,
  resetPin,
  setPin,
} from "./settingsActions";

const initialState = {
  id: "",
  loading: false,
  nameLoading: false,
  saveLoading: false,
  acctLoading: false,
  data: {},
  allBanks: {},
  allAccounts: {},
  cryptocoins: [],
  allCryptoAccounts: [],
  accountDets: {},
  resetPinref: "",
  cardImages: [],
  profileImage: [],
  isTradeSuccessful: false,
  isSetPin: false,
  pinRef: "",
  header: "",
  pinChanged: false,
  changePin: false,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    clearAllBanks: (state, action) => {
      state.allBanks = {};
    },
    uploadCard: (state, { payload }) => {
      state.cardImages = [...state.cardImages, payload.data];
    },
    uploadProfilePic: (state, { payload }) => {
      state.profileImage = [payload.data];
    },
    deleteCard: (state, { payload }) => {
      let result = [...state.cardImages];
      // removing the index using splice
      result.splice(payload, 1);
      state.cardImages = result;
    },
    clearCardImages: (state, { payload }) => {
      state.cardImages = [];
    },
    removePhoto: (state, { payload }) => {
      state.profileImage = [];
    },
    setHeader: (state, { payload }) => {
      state.header = payload;
    },
    setTradeStatus: (state, { payload }) => {
      state.isTradeSuccessful = false;
    },
    clearPinRef: (state, { payload }) => {
      state.pinRef = "";
    },
    clearChangePin: (state, { payload }) => {
      state.changePin = false;
    },
    setProfileImage: (state, { payload }) => {
      state.profileImage = payload;
    },

    clearProfileImage: (state) => {
      state.profileImage = [];
    },
  },
  extraReducers: {
    // ======================================== EXTRA REDUCERS FOR GET ALL BANK ACCOUNTS
    [getBankAccounts.pending]: (state, { payload }) => {
      state.acctLoading = true;
    },
    [getBankAccounts.fulfilled]: (state, { payload }) => {
      state.acctLoading = false;
      state.allAccounts = payload.data;
    },
    [getBankAccounts.rejected]: (state, { payload }) => {
      state.acctLoading = false;
    },

    // ====================================== EXTRA REDUCERS FOR GET CRYPTO COINS
    [getCryptoCoins.pending]: (state, { payload }) => {
    },
    [getCryptoCoins.fulfilled]: (state, { payload }) => {
      state.cryptocoins = payload.data;
    },
    [getCryptoCoins.rejected]: (state, { payload }) => {
    },

    // ================================ CREATE CRYPTO WALLET
    [createCryptoWallet.pending]: (state, { payload }) => {
    },
    [createCryptoWallet.fulfilled]: (state, { payload }) => {
    },
    [createCryptoWallet.rejected]: (state, { payload }) => {
    },

    // ======================================== EXTRA REDUCERS FOR GET ALL NIGERIAN BANKS
    [getBanks.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getBanks.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.allBanks = payload.data;
    },
    [getBanks.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    // ======================================== EXTRA REDUCERS TO GET ACCOUNT NAME
    [getAccountName.pending]: (state, { payload }) => {
      state.nameLoading = true;
    },
    [getAccountName.fulfilled]: (state, { payload }) => {
      state.nameLoading = false;
      state.accountDets = payload.data.data;
    },
    [getAccountName.rejected]: (state, { payload }) => {
      state.nameLoading = false;
    },

    // ======================================== EXTRA REDUCERS TO ADD BANK ACCOUNT
    [addBankAccount.pending]: (state, { payload }) => {
      state.saveLoading = true;
    },
    [addBankAccount.fulfilled]: (state, { payload }) => {
      state.saveLoading = false;
    },
    [addBankAccount.rejected]: (state, { payload }) => {
      state.saveLoading = false;
    },

    // ======================================== EXTRA REDUCERS TO DELETE BANK ACCOUNT
    [delBankAccount.pending]: (state, { payload }) => {
      state.saveLoading = true;
    },
    [delBankAccount.fulfilled]: (state, { payload }) => {
      state.saveLoading = false;
    },
    [delBankAccount.rejected]: (state, { payload }) => {
      state.saveLoading = false;
    },

    // ======================================== EXTRA REDUCERS TO SET INITIAL PIN
    [setPin.pending]: (state, { payload }) => {
      state.saveLoading = true;
      state.isSetPin = false;
    },
    [setPin.fulfilled]: (state, { payload }) => {
      state.saveLoading = false;
      state.isSetPin = true;
    },
    [setPin.rejected]: (state, { payload }) => {
      state.saveLoading = false;
      state.isSetPin = false;
    },

    // ======================================== EXTRA REDUCERS TO CHANGE TRANSACTION PIN
    [changePin.pending]: (state, { payload }) => {
      state.saveLoading = true;
      state.changePin = false;
    },
    [changePin.fulfilled]: (state, { payload }) => {
      state.saveLoading = false;
      state.changePin = true;
    },
    [changePin.rejected]: (state, { payload }) => {
      state.saveLoading = false;
      state.changePin = false;
    },

    // ======================================== EXTRA REDUCERS TO RESSET TRANSACTION PIN
    [resetPin.pending]: (state, { payload }) => {
      state.saveLoading = true;
    },
    [resetPin.fulfilled]: (state, { payload }) => {
      state.saveLoading = false;
      state.pinRef = payload.data.reference;
    },
    [resetPin.rejected]: (state, { payload }) => {
      state.saveLoading = false;
    },

    // ======================================== EXTRA REDUCERS TO PROCEED TO TRADE
    [proceedToTrade.pending]: (state, { payload }) => {
      state.saveLoading = true;
    },
    [proceedToTrade.fulfilled]: (state, { payload }) => {
      state.saveLoading = false;
      state.isTradeSuccessful = true;
    },
    [proceedToTrade.rejected]: (state, { payload }) => {
      state.saveLoading = false;
      state.isTradeSuccessful = false;
    },

    // ======================================== EXTRA REDUCERS TO COMPLETE RESET PIN
    [completeResetPin.pending]: (state, { payload }) => {
      state.saveLoading = true;
      state.pinChanged = false;
    },
    [completeResetPin.fulfilled]: (state, { payload }) => {
      state.saveLoading = false;
      state.isTradeSuccessful = true;
      state.pinChanged = true;
    },
    [completeResetPin.rejected]: (state, { payload }) => {
      state.saveLoading = false;
      state.isTradeSuccessful = false;
      state.pinChanged = false;
    },

    // ======================================== EXTRA REDUCERS TO GET ALL CRYPTO ACCOUNTS
    [getCryptoAccounts.pending]: (state, { payload }) => {
      state.acctLoading = true;
      state.pinChanged = false;
    },
    [getCryptoAccounts.fulfilled]: (state, { payload }) => {
      state.acctLoading = false;
      state.allCryptoAccounts = payload.data;
    },
    [getCryptoAccounts.rejected]: (state, { payload }) => {
      state.acctLoading = false;
    },

    // ======================================== EXTRA REDUCERS TO ADD A NEW CRYPTO ACCOUNT
    [addCryptoAccount.pending]: (state, { payload }) => {
      state.saveLoading = true;
    },
    [addCryptoAccount.fulfilled]: (state, { payload }) => {
      state.saveLoading = false;
    },
    [addCryptoAccount.rejected]: (state, { payload }) => {
      state.saveLoading = false;
    },

    // ======================================== EXTRA REDUCERS TO DELETE ANY CRYPTO ACCOUNT
    [delCryptoAccount.pending]: (state, { payload }) => {
      state.saveLoading = true;
    },
    [delCryptoAccount.fulfilled]: (state, { payload }) => {
      state.saveLoading = false;
    },
    [delCryptoAccount.rejected]: (state, { payload }) => {
      state.saveLoading = false;
    },
    // ------ delete user profile account
    [deleteProfileAccount.pending]: (state) =>{

    },
    [deleteProfileAccount.fulfilled]: (state) =>{

    },
    [deleteProfileAccount.rejected]: (state) =>{

    },

  },
});

export default settingsSlice.reducer;
export const {
  clearAllBanks,
  uploadCard,
  deleteCard,
  clearCardImages,
  uploadProfilePic,
  removePhoto,
  setHeader,
  setTradeStatus,
  clearPinRef,
  clearChangePin,
  setProfileImage,
  clearProfileImage,
} = settingsSlice.actions;
