import React, { useState } from "react";
import style from "./login.module.css";
import { Link, useNavigate } from "react-router-dom";
import { Eye, EyeSlash, } from "iconsax-react";
import { useFormik } from "formik";
import { loginSchema } from "../../../schemas/Index";
import user1 from "../../../assets/images/user1.svg";
import Lottie from "lottie-react";
import loader from "../../../assets/lottie/loader.json";
import ImageComp from "../signup/ImageComp";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword, userSignIn } from "../../../store/auth/authActions";
import Modal from "../../../components/modal/Modal";
import logo from "../../../assets/images/logo2.png";
import cogoToast from "cogo-toast";


const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalDone, setOpenModalDone] = useState(false);
  const [isResetEmail, setIsResetEmail] = useState(false);
  const [loading, setLoading] = useState(false)

  const isLoading = useSelector((state) => state.auth.loading);
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const isResetReq = useSelector((state) => state.auth.resetReq);
  const isVerified = useSelector(state => state.auth.fullUser?.is_verified);

  const togglePasswordVisibility = () => {
    setVisible(!visible);
  };

  const onSubmit = (values, actions) => {
    dispatch(
      userSignIn({
        email: values.email,
        password: values.password,
      })
    );
    console.log(values);
  };

  useEffect(() => {
    if (isLoading === true) {
      setIsFilled(false);
      setIsResetEmail(false);
      setLoading(true)
    } else {
      setIsFilled(true);
      setIsResetEmail(true)
      setLoading(false)
    }
  }, [isLoading]);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema: loginSchema,
      onSubmit,
    });

  const handleSubmitModal = (e) => {
    e.preventDefault();

    dispatch(
      resetPassword({
        email: values.email,
      })
    );
  };

  useEffect(() => {
    if (
      values.email !== "" &&
      !errors.email 
    ) {
      setIsResetEmail(true);
    } else {
      setIsResetEmail(false);
    }
  }, [values, errors, isResetEmail]);

  useEffect(() => {
    if (
      values.email !== "" &&
      values.password !== "" &&
      !errors.email &&
      !errors.password 
    ) {
      setIsFilled(true);
    } else {
      setIsFilled(false);
    }
  }, [values, errors]);

  useEffect(() => {
    if (isAuth === true && isVerified === true) {
      navigate("/dashboard");
    } else if (isAuth === true && isVerified === false) {
      cogoToast.info("Kindly verify your email address to proceed your dashboard!")
    }
  }, [isAuth, isVerified, navigate]);

  useEffect(() => {
    if (isResetReq === true) {
      navigate("/reset-completed");
    }
  }, [isResetReq, navigate]);

  return (
    <div className={style.signup}>
      {/* ==================== HERE LIES THE MODAL FOR FORGOT PASSWORD TO INPUT YOUR EMAIL ADDRESS FOR VERIFIATION ===================== */}

      <Modal canClose={true} modalOpen={openModal} setModalOpen={setOpenModal}>
        <h4>Forgot Password</h4>
        <p>Kindly input the email address attached to your account</p>
        <form action="">
          <div className={style.input}>
            <input
              value={values.email}
              onChange={handleChange}
              type="email"
              id="email"
              placeholder="Enter email address"
              onBlur={handleBlur}
              className={
                errors.email && touched.email ? style.error : style.notError
              }
            />
            {errors.email && touched.email && (
              <p className={style.pError}>{errors.email}</p>
            )}
          </div>
          <br />
          {isLoading === true ? (
            <div className={style.lottieWrap}>
              <Lottie
                style={{
                  width: "70px",
                  height: "70px",
                  color: "#fff",
                }}
                animationData={loader}
                loop={true}
              />
            </div>
          ) : (
            <button
              onClick={handleSubmitModal}
              disabled={!isResetEmail}
              className={style.button}
            >
              Submit
            </button>
          )}
        </form>
      </Modal>

      {/* ==================== HERE LIES THE MODAL WHEN YOU CHANGE PASSWORD MAIL HAS BEEN SENT TO YOUR ACCOUNT ===================== */}

      <Modal modalOpen={openModalDone} setModalOpen={setOpenModalDone}>
        {/* <Icon className={style.closeModalCon} onClick={() => {setOpenModalDone(false)}} icon="ri:close-circle-fill" /> */}
        <div className={style.modalImgWrap}>
          <img
            className={style.modalImg}
            src={user1}
            alt="astroafrica.site/user1.svg"
          />
        </div>
        <p style={{ margin: "0" }}>
          Hi there! Kindly check your email for the link to reset your password{" "}
        </p>
        <div className={style.lottieWrap}>
          <Lottie
            style={{
              width: "70px",
              height: "70px",
              color: "#fff",
            }}
            animationData={loader}
            loop={true}
          />
        </div>
      </Modal>

      {/* ==================== HERE LIES THE END OF THE  MODALS FOR THIS PAGE  ===================== */}

      <ImageComp />

      <div className={style.right}>
        <div className={style.formCont}>
          <div style={{ width: "100%" }}>
            <div className={style.logoImg}>
              <img src={logo} alt="" />
            </div>
            <div className={style.starter}>
              <h2>Welcome Back</h2>
              <p>Continue where you left off</p>
            </div>
            <div className={style.form}>
              <form action="">
                <div className={style.labelPut}>
                  <label htmlFor="email">
                    Email Address<span className={style.required}>*</span>{" "}
                  </label>
                  <div className={style.input}>
                    <input
                      value={values.email}
                      onChange={handleChange}
                      type="email"
                      id="email"
                      placeholder="Enter email address"
                      onBlur={handleBlur}
                      className={
                        errors.email && touched.email
                          ? style.error
                          : style.notError
                      }
                    />
                  </div>
                  {errors.email && touched.email && (
                    <p className={style.pError}>{errors.email}</p>
                  )}
                </div>

                <div className={style.labelPut}>
                  <label htmlFor="password">
                    Password<span className={style.required}>*</span>{" "}
                  </label>
                  <div className={style.input}>
                    <input
                      value={values.password}
                      onChange={handleChange}
                      type={visible ? "text" : "password"}
                      id="password"
                      placeholder="Enter password"
                      onBlur={handleBlur}
                      className={
                        errors.password && touched.password
                          ? style.error
                          : style.notError
                      }
                    />
                    {visible ? (
                      <EyeSlash
                        onClick={togglePasswordVisibility}
                        className={style.visSwitch}
                        color="#909090"
                        variant="Bulk"
                      />
                    ) : (
                      <Eye
                        onClick={togglePasswordVisibility}
                        className={style.visSwitch}
                        color="#909090"
                        variant="Bulk"
                      />
                    )}
                  </div>
                  {errors.password && touched.password && (
                    <p className={style.pError}>{errors.password}</p>
                  )}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <p
                    className={style.fgtPwd}
                    onClick={() => {
                      setOpenModal(true);
                    }}
                  >
                    Forgot Password
                  </p>
                </div>
                {loading === true ? (
                  <div className={style.lottieWrap}>
                    <Lottie
                      style={{
                        width: "70px",
                        height: "70px",
                        color: "#fff",
                      }}
                      animationData={loader}
                      loop={true}
                    />
                  </div>
                ) : (
                  <button
                    onClick={handleSubmit}
                    className={style.button}
                    disabled={!isFilled}
                    type="submit"
                  >
                    {" "}
                    Login
                  </button>
                )}
              </form>
            </div>
            <div className={style.alternates}>
              {/* BUTTONS TO SIGN IN WITH SOCIALMEDIA */}

              {/* <div className={style.googleFace}>
                <p>Or sign up with: </p>
                <Icon className={style.logoCons} icon="logos:google-icon" />
                <Icon className={style.logoCons} icon="logos:facebook" />
              </div> */}
              <p className={style.register}>
                New to Astro? <Link to="/signup">Create Account</Link>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    //   <div className={style.login} >

    //       <div style={{display: openModal ? "flex" : "none" }} className={style.modal} >
    //           <div className={style.popup} >
    //               <Icon className={style.closeCon} onClick={() => {setOpenModal(false)}} icon="material-symbols:close-rounded" />
    //               <h3>Forgot Password</h3>
    //               <p>Kindly input the email address attached to your accout</p>
    //               <div className={style.modalForm} >
    //                   <form action="">
    //                       {/* <div className={style.labelPut} > */}
    //                           <label htmlFor="Email"> Email Address</label>
    //                           <input type="email" placeholder='Enter Your Email Address' />
    //                       {/* </div> */}
    //                       <Link to="/forgot-password" ><button className={style.createBtn} disabled={false} >Submit</button></Link>
    //                   </form>
    //               </div>
    //           </div>
    //       </div>

    //         <div className={style.log} >
    //             <div className={style.left}>
    //                 <div className={style.contLeft} >
    //                     <h2>Welcome Back</h2>
    //                     <p>Continue where you left off</p>
    //                     <div className={style.storeBtns} >
    //                         <button>
    //                             <Icon className={style.icon} icon="logos:google-icon" />
    //                             Sign Up with Google
    //                         </button>
    //                         <button>
    //                             <Icon className={style.icon} icon="logos:facebook" />
    //                             Sign Up with Facebook
    //                         </button>
    //                     </div>
    //                     <div className={style.legacy} >
    //                         <hr />
    //                         <p>Or with email</p>
    //                         <hr />
    //                   </div>
    //                   <div className={style.form} >
    //                       <form autoComplete={false} action="">
    //                         <div className={style.labelInput} >
    //                             <label htmlFor="first-name">Email Address</label>
    //                             <div className={style.input} >
    //                                 <input type="email" placeholder='Enter Your Email Address' />
    //                             </div>
    //                         </div>

    //                         <div className={style.labelInput} >
    //                             <label htmlFor="first-name">Password</label>
    //                             <div className={style.input} >
    //                                 <input type={!visible ? "password" : "text"} placeholder='Enter Your Password' />
    //                                 {
    //                                     !visible ? <Icon className={style.eyeCon} onClick={togglePasswordVisibility} icon="mdi:eye" />
    //                                         : <Icon className={style.eyeCon} onClick={togglePasswordVisibility} icon="mdi:eye-off" />
    //                                 }
    //                             </div>
    //                             <div className={style.terms} >
    //                                 <p className={style.fgtPwd} onClick={() => {setOpenModal(true)}} > Forgot Password </p>
    //                             </div>
    //                         </div>

    //                           <button className={style.createBtn} type='submit' disabled={true} >Sign In</button>
    //                           <p className={style.logi} >New to Astro? <Link to="/sign-up" >Create Account</Link></p>
    //                       </form>
    //                   </div>

    //                 </div>
    //             </div>
    //             <div className={style.right} >
    //                 <div className={style.contRight} >
    //                     <div className={style.box1} >
    //                         <img src={rectribox} alt="" />
    //                     </div>
    //                     <div className={style.box2} >
    //                         <img src={rectribox} alt="" />
    //                     </div>
    //                   <div className={style.img} >
    //                       <img src={dash} alt="astroafrica.site" />
    //                   </div>
    //                   <div className={style.text} >
    //                       <h3>Trade with Astro</h3>
    //                       <p>Best Rates, Fast Payment, and Responsive Support. Experience a life without limits with Astro.  </p>
    //                   </div>
    //                 </div>
    //             </div>
    //         </div>
    //     {/* <Footer /> */}
    // </div>
  );
};

export default Login;
