import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../config";
import cogoToast from "cogo-toast";


// ================================================================= ACTION TO GET ALL DEPOSIT TRANSACTIONS

export const getDepositTransactions = createAsyncThunk(
  "trans/getDepositTransactions",
  async (value, { rejectWithValue, dispatch, getState }) => {
    const id = getState().auth.user.user_id;
    try {
      console.log(id)
      const res = await axios.get(
        `${baseUrl}/payments/inwards/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": getState().auth.token.toString(),
          },
        }
      );
      if (res.status === 200) {
        return res;
      }
        return res;
    } catch (error) {
      if (error.response.status === 400) {
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        console.log(error.response.status);
        return rejectWithValue(error.response);
      }
    }
  }
);



// ================================================================= ACTION TO GET A DEPOSIT TRANSACTION BY IT'S ID

export const getDepositTransactionsById = createAsyncThunk(
  "trans/getDepositTransactionsById",
  async (values, { rejectWithValue, dispatch, getState }) => {
      const id = getState().auth.user.user_id;
      try {
        console.log(values.transId)
      const res = await axios.get(`${baseUrl}/payments/inwards/${id}/${values.transId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": getState().auth.token.toString(),
        },
      });
      if (res.status === 200) {
        console.log(res)
        return res;
      }
        return res;
    } catch (error) {
      if (error.response.status === 400) {
        cogoToast.error("Unable to get data");
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        console.log(error.response.status);
        return rejectWithValue(error.response);
      }
    }
  }
);


// ================================================================= ACTION TO GET ALL WITHDRAWAL TRANSACTIONS

export const getWithdrawalTransactionsById = createAsyncThunk(
  "trans/getWithdrawalTransactionsById",
  async (values, { rejectWithValue, dispatch, getState }) => {
    const id = getState().auth.user.user_id;
    try {
      const res = await axios.get(`${baseUrl}/payments/outwards/${id}/${values.transId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": getState().auth.token.toString(),
        },
    });
      if (res.status === 200) {
        console.log(res)
        return res;
      }
        return res;
    } catch (error) {
      if (error.response.status === 400) {
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        console.log(error.response.status);
        return rejectWithValue(error.response);
      }
    }
  }
);

// ================================================================= ACTION TO GET ALL WITHDRAWAL TRANSACTIONS

export const getWithdrawalTransactions = createAsyncThunk(
  "trans/getWithdrawalTransactions",
  async (args, { rejectWithValue, dispatch, getState }) => {
    const id = getState().auth.user.user_id;
    try {
      const res = await axios.get(`${baseUrl}/payments/outwards/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": getState().auth.token.toString(),
        },
    });
      if (res.status === 200) {
        console.log(res)
        return res;
      }
        return res;
    } catch (error) {
      if (error.response.status === 400) {
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        console.log(error.response.status);
        return rejectWithValue(error.response);
      }
    }
  }
);


// ================================================================= ACTION TO GET ALL DEPOSIT TRANSACTIONS

export const getGiftcardTransactions = createAsyncThunk(
  "trans/getGiftcardTransactions",
  async (args, { rejectWithValue, dispatch, getState }) => {
    const id = getState().auth.user.user_id;
    try {
      const res = await axios.get(`${baseUrl}/cards/sell/transactions/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": getState().auth.token.toString(),
        },
      });
        if (res.status === 200) {
          console.log(res)
        return res;
      }
        return res;
    } catch (error) {
      if (error.response.status === 400) {
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }  
  }   
); 

// ================================================================= ACTION TO GET ALL DEPOSIT TRANSACTIONS

export const getGiftcardTransactionsById = createAsyncThunk(
  "trans/getGiftcardTransactionsById",
  async ({transId}, { rejectWithValue, dispatch, getState }) => {
    const id = getState().auth.user.user_id;
    try {
      const res = await axios.get(`${baseUrl}/cards/sell/transactions/${id}/${transId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": getState().auth.token.toString(),
        },
      });
        if (res.status === 200) {
          console.log(res)
        return res;
      }
        return res;
    } catch (error) {
      if (error.response.status === 400) {
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }  
  }   
); 

// ================================================================= ACTION TO GET ALL DEPOSIT TRANSACTIONS

export const getRecentTransactions = createAsyncThunk(
  "trans/getRecentTransactions",
  async (args, { rejectWithValue, dispatch, getState }) => {
    const id = getState().auth.user.user_id;
    try {
      const res = await axios.get(`${baseUrl}/payments/recents/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": getState().auth.token.toString(),
        },
      });
      if (res.status === 200) {
        console.log(res)
        return res;
      }
        return res;
    } catch (error) {
      if (error.response.status === 400) {
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        console.log(error.response.status);
        return rejectWithValue(error.response);
      }
    }
  }
);

// ================================================================= FETCH CRYPTO TRANSACTIONS

export const fetchCryptoTransactionsById = createAsyncThunk(
  "fetchCryptoTransactionsById",
  async (values, { rejectWithValue, dispatch, getState }) => {
    const id = getState().auth.user.user_id;
    try {
      const res = await axios.get(
        `${baseUrl}/coin/inward/${id}/${values.transId}`,
         {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": getState().auth.token.toString(),
          },
        }
      );
      if (res.status === 200 || res.status === 201) {
        console.log(res);
        return res;
      }
    } catch (error) {
      if (error.response.status === 401) {
        cogoToast.error("Wallet Not created");
        // dispatch({ type: "auth/Logout" });
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    } 
  }
);


// ================================================================= FETCH CRYPTO TRANSACTIONS
export const fetchOutwardCryptoTransactions = createAsyncThunk(
  "fetchOutwardCryptoTransactions",
  async (values, { rejectWithValue, dispatch, getState }) => {
    const id = getState().auth.user.user_id;
    try {
      const res = await axios.get(
        `${baseUrl}/coin/withdraw/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": getState().auth.token.toString(),
          },
        }
      );
      if (res.status === 200 || res.status === 201) {
        console.log(res);
        return res;
      }
    } catch (error) {
      if (error.response.status === 401) {
        cogoToast.error("Wallet Not created");
        // dispatch({ type: "auth/Logout" });
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);


// ================================================================= FETCH CRYPTO TRANSACTIONS
export const fetchOutwardCryptoTransactionsById = createAsyncThunk(
  "fetchOutwardCryptoTransactionsById",
  async (values, { rejectWithValue, dispatch, getState }) => {
    const id = getState().auth.user.user_id;
    try {
      const res = await axios.get(
        `${baseUrl}/coin/withdraw/${id}/${values.transId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": getState().auth.token.toString(),
          },
        }
      );
      if (res.status === 200 || res.status === 201) {
        console.log(res);
        return res;
      }
    } catch (error) {
      if (error.response.status === 401) {
        cogoToast.error("Wallet Not created");
        // dispatch({ type: "auth/Logout" });
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);