import cogoToast from "cogo-toast";
import React, { useState } from "react";
import PinInput from "react-pin-input";
import { useDispatch, useSelector } from "react-redux";
import { changePin } from "../../store/settings/settingsActions";
import style from "./settings.module.css";
import Lottie from "lottie-react";
import loader from "../../assets/lottie/loader.json";
import { useEffect } from "react";
import { clearChangePin } from "../../store/settings/settingsSlice";

const ChangePin = ({setNewRoute}) => {
  const [oldPin, setOldPin] = useState("");
  const [newPin, setNewPin] = useState("");
  const [confirmNewPin, setConfirmNewPin] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true)

  const changeTransPin = useSelector(state => state.settings.changePin)
  const isSaveLoading = useSelector(state => state.settings.saveLoading)

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (newPin !== confirmNewPin) {
      cogoToast.warn("Your new pin does not match with confirm new pin")
    } else {
      dispatch(changePin({
        oldPin, newPin
      }))
    }

    console.log(newPin, confirmNewPin);
  };

  useEffect(() => { 
    if (isSaveLoading === true) {
      setLoading(true)
    } else {
      setLoading(false)
    }

  }, [isSaveLoading])
  

  useEffect(() => { 

    if (changeTransPin === true) {
      setNewRoute("settings")
    }
    setTimeout(() => {
      dispatch(clearChangePin())
    }, 2000)
    
    
  }, [changeTransPin])
  
  useEffect(() => { 
    
    if (oldPin !== "" && newPin !== "" && confirmNewPin !== "" && oldPin.length === 5 && newPin.length == 5 && confirmNewPin.length === 5) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }

  } ,[oldPin, newPin, confirmNewPin])

  return (
    <div className={style.profile}>
      <div className={style.header}>
        <h2>Change Your Transaction Pin</h2>
        <p className={style.enterFive}>
          Enter your preferred 5-digit pin for all your transactions on Astro
        </p>
      </div>
      <div className={style.form}>
        <form action="">
          <div className={style.inputs2}>
            <p className={style.label}>Old Pin is here</p>
            <PinInput
              length={5}
              initialValue=""
              secret={true}
              onChange={(value, index) => {
                setOldPin(value);
              }}
              type="numeric"
              inputMode="number"
              style={{ padding: "10px", margin: "0 auto 0 auto" }}
              inputStyle={{ border: "1px solid", borderColor: "#909090" }}
              inputFocusStyle={{ border: "2px solid", borderColor: "#40196D" }}
              onComplete={(value, index) => {
                setOldPin(value);
              }}
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
          </div>
          <div className={style.inputs2}>
            <p className={style.label}>New Pin</p>
            <PinInput
              length={5}
              initialValue=""
              secret={true}
              onChange={(value, index) => {
                setNewPin(value);
              }}
              type="numeric"
              inputMode="number"
              style={{ padding: "10px", margin: "0 auto 0 auto" }}
              inputStyle={{ border: "1px solid", borderColor: "#909090" }}
              inputFocusStyle={{ border: "2px solid", borderColor: "#40196D" }}
              onComplete={(value, index) => {
                setNewPin(value);
              }}
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
          </div>
          <div className={style.inputs2}>
            <p className={style.label}>Confirm New Pin</p>
            <PinInput
              length={5}
              initialValue=""
              secret={true}
              onChange={(value, index) => {
                setConfirmNewPin(value);
              }}
              type="numeric"
              inputMode="number"
              style={{ padding: "10px", margin: "0 auto 0 auto" }}
              inputStyle={{ border: "1px solid", borderColor: "#909090" }}
              inputFocusStyle={{ border: "2px solid", borderColor: "#40196D" }}
              onComplete={(value, index) => {
                setConfirmNewPin(value);
              }}
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
          </div>
          {/* <p className={style.resendCode} > Didn't get a code? <a href="#">Resend Code</a> </p> */}
          {loading == true ? (
            <div className={style.lottieWrap}>
              <Lottie
                style={{
                  width: "70px",
                  height: "70px",
                  color: "#fff",
                  padding: "0px",
                  margin: "0px",
                }}
                animationData={loader}
                loop={true}
              />
            </div>
          ) : (
            <button
              onClick={handleSubmit}
              className={style.save2}
              type="submit"
              disabled={isDisabled}
            >
              Verify
            </button>
          )}
        </form>
        {/* <p className={style.resendCode} > Already a member? <Link to="/login">Sign In</Link> </p> */}
      </div>
    </div>
  );
};

export default ChangePin;
