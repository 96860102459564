import { createSlice } from "@reduxjs/toolkit";
import { completeReset, getUser, getUserById, resendOtp, resetPassword, updateUserDetails, userSignIn, userSignUp, verifyEmailCode, verifyEmailWithOtp } from "./authActions";


const initialState = {
  msg: "",
  user: {},
  fullUser: {},
  pinData: {},
  pinSet:false,
  token: "",
  isAuthenticated:false,
  loading: false,
  hasResetPwd: false,
  uidLoading: false,
  signupSuccess: false,
  isVerified:false,
  resetReq: false,
  authEmail: false,
  currency:"",
  error: "",
  isVerifyMsg: ""
};



const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearSignupSuccess:(state, {payload}) => {
      state.signupSuccess = false;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      localStorage.clear()
      localStorage.removeItem("persist:root");

      return initialState;
    },
    clearHasResetPwd: (state) => {
      state.hasResetPwd = false;
    }
  },
  extraReducers: {
    
    
    // =========================  extra reducers for the SIGN UP
    
    [userSignUp.pending]: (state, {payload}) => {
      state.loading = true;
      console.log(payload);
      state.isAuthenticated = false;
    },
    [userSignUp.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.signupSuccess = true;
      state.user = payload.data.user;
      state.token = payload.data.token; 
      state.authEmail = true;
      state.fullUser = payload.data.user;
      state.pinData = payload.data;
      console.log(payload)
      state.isAuthenticated = false;
      state.currency = payload.data.user.Profile.country_tag;
    },
    [userSignUp.rejected]: (state, {payload}) => {
      state.loading = false;
      state.isAuthenticated = false;
    },
    
    // =========================  extra reducers for the LOG IN 
    
    [userSignIn.pending]: (state, action) => {
      state.loading = true;
      state.isAuthenticated = false;
      console.log(action); 
    },
    [userSignIn.fulfilled]: (state, { payload}) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.token = payload.data.token;
      state.user = payload.data.user.Profile;
      state.fullUser = payload.data.user;
      state.authEmail = true;
      state.pinData = payload.data;
    },
    [userSignIn.rejected]: (state, {payload}) => {
      state.loading = false;
      state.isAuthenticated = false; 
      state.authEmail = false;
    },
    
    // =========================  extra reducers for the VERIFY EMAIL WITH OTP
    
    [verifyEmailWithOtp.pending]: (state, action) => {
      state.loading = true;
      state.isAuthenticated = false
      console.log(action);
    },
    [verifyEmailWithOtp.fulfilled]: (state, { payload}) => {
      state.loading = false;
      state.isAuthenticated = true;
      console.log(payload)
    },
    [verifyEmailWithOtp.rejected]: (state, {payload}) => {
      state.loading = false;
      state.isAuthenticated = false
    },

    // ====================== extra reducers for verify email code
    [verifyEmailCode.pending]: (state, action) => {
        
    },
    [verifyEmailCode.fulfilled]: (state, { payload}) => {
      state.isVerified = true
    },
    [verifyEmailCode.rejected]: (state, {payload}) => {
        state.isVerifyMsg = payload
        state.isVerified = false
    },

    // =========================  extra reducers for the RESET PASSWORD
    
    [resetPassword.pending]: (state, action) => {
      state.loading = true;
      state.isAuthenticated = false
      state.resetReq = false;
      console.log(action);
    },
    [resetPassword.fulfilled]: (state, { payload}) => {
      state.loading = false;
      state.resetReq = true;
      console.log(payload)
    },
    [resetPassword.rejected]: (state, {payload}) => {
      state.loading = false;
      state.resetReq = false;
      state.isAuthenticated = false
    },


    // =========================  extra reducers for the GET USER
    
    [getUser.pending]: (state, action) => {
      state.loading = true;
      state.isAuthenticated = false
      state.resetReq = false;
      console.log(action);
    },
    [getUser.fulfilled]: (state, { payload}) => {
      state.loading = false;
      state.resetReq = true;
      console.log(payload)
    },
    [getUser.rejected]: (state, {payload}) => {
      state.loading = false;
      state.resetReq = false;
      state.isAuthenticated = false
    },


    // =========================  extra reducers for the GET USER
    
    [resendOtp.pending]: (state, action) => {
      state.loading = true;
      state.isAuthenticated = false
      state.resetReq = false;
    },
    [resendOtp.fulfilled]: (state, { payload}) => {
      state.loading = false;
    },
    [resendOtp.rejected]: (state, {payload}) => {
      state.loading = false;
      state.resetReq = false;
      state.authEmail = false;
      state.isAuthenticated = false
    },


    // =========================  extra reducers for the GET USER
    
    [completeReset.pending]: (state, action) => {
      state.loading = true;
      state.isAuthenticated = false
      state.resetReq = false;
      console.log(action);
    },
    [completeReset.fulfilled]: (state, { payload}) => {
      state.loading = false;
      state.hasResetPwd = true;
      console.log(payload)
    },
    [completeReset.rejected]: (state, {payload}) => {
      state.loading = false;
      state.resetReq = false;
      state.isAuthenticated = false
    },


    // =========================  extra reducers for the GET USER BY ID
    
    [getUserById.pending]: (state, action) => {
      state.uidLoading = true;
    },
    [getUserById.fulfilled]: (state, { payload}) => {
      state.uidLoading = false;
      state.fullUser = payload.data.data.user;
      state.pinData = payload.data.data;
    },
    [getUserById.rejected]: (state, {payload}) => {
      state.uidLoading = false;
    },


    // =========================  extra reducers for the GET USER
    
    [updateUserDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [updateUserDetails.fulfilled]: (state, { payload}) => {
      state.loading = false;
      state.user = payload.data.user.Profile;
      state.fullUser = payload.data.user;
    },
    [updateUserDetails.rejected]: (state, {payload}) => {
      state.loading = false;
      state.resetReq = false;
      state.isAuthenticated = false
    },


  },
});
export default authSlice.reducer;
export const { clearSignupSuccess, logout, clearHasResetPwd } = authSlice.actions;