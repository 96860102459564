import { useFormik } from "formik";
import { Card, Lock, User } from "iconsax-react";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { basicSchema } from "../../schemas/Index";
import { getBankAccounts, getCryptoAccounts, resetPin } from "../../store/settings/settingsActions";
import { setHeader } from "../../store/settings/settingsSlice";
import Modal from "../modal/Modal";
import AddBank from "./AddBank";
import ChangePin from "./ChangePin";
import ProfileInfo from "./ProfileInfo";
import ResetPin from "./ResetPin";
import SetPin from "./SetPin";
import style from "./settings.module.css";
import { getAllCoins } from "../../store/crypto/cryptoActions";
import KYC from "./KYC";
import Lottie from "lottie-react";
import loader from "../../assets/lottie/loader.json";
import CryptoCoinsLayout from "./CryptoCoins";

 

const Settings = ({ newRoute, setNewRoute }) => {
  const [location, setLocation] = useState("settings");
  const [pinSet, setPinSet] = useState(false);
  const [openEmailQuery, setOpenEmailQuery] = useState(false)
  const [addrLoading, setAddrLoading] = useState(false)

  const dispatch = useDispatch();
  const isPin = useSelector((state) => state.auth.pinData.isSetPin);
  const isPinSet = useSelector((state) => state.settings.isSetPin);
  const isAddressLoading = useSelector((state) => state.userKyc.addressLoading);
  


  const onSubmit = () => {
    console.log(values.email)
  }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        firstname: "",
        lastname: "",
        email: "",
        phoneNumber: "",
        country: "",
        password: "",
        referral: "",
      },
      validationSchema: basicSchema,
      onSubmit,
    });

    useEffect(() => {
      dispatch(setHeader("Profile Settings"));
    }, []);

    useEffect(() => {
      dispatch(getAllCoins());
    }, []);
  
  useEffect(() => {
    setNewRoute(newRoute === "" ? "settings" : newRoute);
  }, []);

  useEffect(() => {
    if (isPin === true || isPinSet === true) {
      setPinSet(true);
    } else {
      setPinSet(false);
    }
  }, [isPin]);

  useEffect(() => {
    if ( isAddressLoading === true ) {
      setAddrLoading(true);
    } else {
      setAddrLoading(false);
    }
  }, [isAddressLoading]);




  return (
    <div className={style.settings}>
      <Modal modalOpen={addrLoading} >
        <div className={style.lottieWrap}>
          <Lottie
            style={{
              width: "70px",
              height: "70px",
              color: "#fff",
              padding: "0px",
              margin: "0px",
            }}
            animationData={loader}
            loop={true}
          />
        </div>
      </Modal>
      <div className={style.panel}>
        <div
          onClick={() => {
            setNewRoute("settings");
          }}
          style={{
            color: newRoute === "settings" ? "#40196D" : "#1a1a1a",
            background: newRoute === "settings" ? "#f4f4f4" : "none",
          }}
          className={style.button}
        >
          <User variant="Outline" />
          <p>Profile Info</p>
        </div>
        <div
          onClick={() => {
            setNewRoute("crypto");
          }}
          style={{
            color: newRoute === "crypto" ? "#40196D" : "#1a1a1a",
            background: newRoute === "crypto" ? "#f4f4f4" : "none",
          }}
          className={style.button}
        >
          <Card variant="Outline" />
          <p>Crypto Coins</p>
        </div>
        <div
          onClick={() => {
            setNewRoute("bank");
            dispatch(getBankAccounts());
            dispatch(getCryptoAccounts());
          }}
          style={{
            color: newRoute === "bank" ? "#40196D" : "#1a1a1a",
            background: newRoute === "bank" ? "#f4f4f4" : "none",
          }}
          className={style.button}
        >
          <Card variant="Outline" />
          <p>Add Bank Account</p>
        </div>

        {pinSet === false ? (
          <div
            onClick={() => {
              setNewRoute("setPin");
            }}
            style={{
              color: newRoute === "setPin" ? "#40196D" : "#1a1a1a",
              background: newRoute === "setPin" ? "#f4f4f4" : "none",
            }}
            className={style.button}
          >
            <Lock variant="Outline" />
            <p>Set Transaction Pin</p>
          </div>
        ) : (
          <div
            onClick={() => {
              setNewRoute("changePin");
            }}
            style={{
              color: newRoute === "changePin" ? "#40196D" : "#1a1a1a",
              background: newRoute === "changePin" ? "#f4f4f4" : "none",
            }}
            className={style.button}
          >
            <Lock variant="Outline" />
            <p>Change Transaction Pin</p>
          </div>
        )}

        {pinSet === true && (
          <div
            onClick={() => {
              setNewRoute("resetPin");
            }}
            style={{
              color: newRoute === "resetPin" ? "#40196D" : "#1a1a1a",
              background: newRoute === "resetPin" ? "#f4f4f4" : "none",
            }}
            className={style.button}
          >
            <Lock variant="Outline" />
            <p>Forgot/Reset Pin</p>
          </div>
        )}
      </div>
      <div className={style.displayWrap}>
        {newRoute === "settings" && <KYC />}
        <div className={style.display}>
          {newRoute === "settings" && <ProfileInfo />}
          {newRoute === "crypto" && <CryptoCoinsLayout />}
          {newRoute === "bank" && <AddBank />}
          {newRoute === "setPin" && <SetPin />}
          {newRoute === "changePin" && <ChangePin setNewRoute={setNewRoute} />}
          {newRoute === "resetPin" && <ResetPin />}
        </div>
      </div>
    </div>
  );
};

export default Settings;
