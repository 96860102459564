import React, { useEffect, useState } from "react";
import style from "./wallet.module.css";
import Lottie from "lottie-react";
import loader from "../../assets/lottie/loader.json";
import { ArrowDown2 } from "iconsax-react";
import { useDispatch, useSelector } from "react-redux";
import { fundWallet } from "../../store/wallet/walletActions";

const acctTypes = [
  { id: 1, title: "Savings" },
  { id: 2, title: "Current" },
  { id: 3, title: "Domiciliary" },
  { id: 4, title: "Salary" },
  { id: 5, title: "Fixed deposit" },
  { id: 6, title: "Diaspora" },
]; 

const Fund = () => {
  const [wallet, setWallet] = useState("");
  const [openWallet, setOpenWallet] = useState(false);
  const [account, setAccount] = useState("");
  const [amount, setAmount] = useState(0);
  const [currId, setCurrId] = useState(0);
  const [openAccount, setOpenAccount] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [loading, setLoading] = useState(false);

  const isLoading = useSelector((state) => state.wallet.loading);
  const walletData = useSelector((state) => state.wallet.data);
  const dispatch = useDispatch()

  console.log(walletData);

  const toggleWallet = () => {
    setOpenWallet(!openWallet);
  };

  const handleWallet = (e, id) => {
    setOpenWallet(false);
    setWallet(e.target.textContent);
    setCurrId(id);
  };

  const handleFundWallet = (e) => {
    // console.log(amount, currId, wallet);
    dispatch(fundWallet({currId, amount}));
    e.preventDefault();
  };
  useEffect(() => {
    if (isLoading === true) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    if (amount !== "" && wallet !== "" && currId !== "") {
      setIsFilled(true);
    } else {
      setIsFilled(false);
    }
  }, [isLoading, amount, wallet, currId]);

  return (
    <div className={style.form}>
      <form action="">
        <div className={style.labelPut}>
          <label htmlFor="acct_type"> Select Wallet</label>
          <div className={style.select}>
            <div onClick={toggleWallet} className={style.selectInput}>
              <input
                name="acctType"
                type="text"
                disabled={true}
                value={wallet}
                placeholder="Please select a Wallet"
              />
              <ArrowDown2
                style={{
                  transform:
                    openWallet === true
                      ? "translateY( -50%) rotate(-540deg)"
                      : "translateY( -50%) rotate(0deg)",
                }}
                className={style.dropDown}
                size="20"
                color="#909090"
                variant="Bold"
              />
            </div>
            <div
              style={{
                height: openWallet === true ? "fit-content" : "0px",
                maxHeight: "120px"
              }}
              className={style.option}
            >
              {walletData.fiatWallets ? (
                walletData.fiatWallets.map((item) => (
                  <p
                    key={item.id}
                    onClick={(e, id = item.Currency.id) => {
                      handleWallet(e, id);
                    }}
                  >
                    {item.Currency.code} Wallet
                  </p>
                ))
              ) : (
                <div>You Have no wallets</div>
              )}
            </div>
            {/* <input type="text" placeholder='Enter Last Name' /> */}
          </div>
        </div>

        {/* <div className={style.labelPut}>
          <label htmlFor="number"> Comment </label>
          <div className={style.input}>
            <input
              className={style.cardImgTa}
              name="comment"
              id=""
              placeholder="Add Comments"
            />
          </div>
        </div> */}

        <div className={style.labelPut}>
          <label htmlFor="number"> Enter Amount </label>
          <div className={style.input}>
            <input
              className={style.cardImgTa}
              name="amount"
              id="amount"
              placeholder="Enter Amount"
              type="number"
              onChange={(e) => {
                setAmount(e.target.value);
              }}
            />
          </div>
        </div>

        {loading === true ? (
          <div className={style.lottieWrap}>
            <Lottie
              style={{
                width: "70px",
                height: "70px",
                color: "#fff",
                padding: "0px",
                margin: "0px",
              }}
              animationData={loader}
              loop={true}
            />
          </div>
        ) : (
          <button
            onClick={handleFundWallet}
            disabled={!isFilled}
            className={style.save}
          >
            Fund Wallet
          </button>
        )}
      </form>
    </div>
  );
};

export default Fund;
