import React from 'react'
import style from './prompt.module.css'
import image from '../../assets/images/prompt.svg'

const Prompt = ({message, cta1, cta2, cta1Text,  cta2Text, open  }) => {
  return (
      <div style={{ display: open === true ? "flex" : "none"}} className={style.promptWrap} >
          <div className={style.prompt} >
              <div className={style.contents} >
                  <img src={image} alt="" />
                  <p>{message}</p>
                  <div className={style.buttons} >
                    {cta1Text && <button onClick={cta1} className={style.proceed} >{ cta1Text }</button>}
                    {cta2Text && <button onClick={cta2} className={style.notProceed} >{ cta2Text }</button>}
                  </div>
              </div>
          </div>
    </div> 
  )
}
export default Prompt;
