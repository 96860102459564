import React, { useEffect } from 'react';
import Right from '../../components/right/Right';
import Sidebar from '../../components/sidebar/Sidebar';
import style from './dashboard.module.css';
import { Home2, Card, EmptyWallet,ArrowRotateRight, Setting2 } from 'iconsax-react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getWalletBalance } from '../../store/wallet/walletActions';

const Dashboard = ({ verifiedKYC, setVerifiedKYC, calcRoute, setCalcRoute }) => {


    const links = [
        { id: 1, title: 'Dashboard', icon: <Home2 size="20" />, route: "/dashboard" },
        { id: 2, title: 'Trade Gift Card', icon: <Card size="20" />, route: "/trade" },
        { id: 3, title: 'Bills', icon: <Home2 size="20" />, route: "/bills" },
        { id: 4, title: 'Wallets', icon: <EmptyWallet size="20" />, route: "/wallets" },
        { id: 5, title: 'Transactions', icon: <ArrowRotateRight size="20" />, route: "/transactions" },
        { id: 6, title: 'Settings', icon: <Setting2 size="20" />, route: "/settings" },
    ]

    const [newRoute, setNewRoute] = useState("");
    const [dynTitle, setDynTitle] = useState('Dashboard');
    const [openSidebar, setOpenSidebar] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()


        
    const handleRoutes = () => {
        window.location.pathname === "/settings" ? setNewRoute("settings") : setNewRoute("");
    }

    useEffect(() => {
      dispatch(getWalletBalance());
    }, []);

    useEffect(() => { 
        handleRoutes();

    },[])


    useEffect(() => { 
        localStorage.setItem('new-route', newRoute);
    },[newRoute])
   

  return (
    <div className={style.dashboard}>
      <Sidebar
        links={links}
        newRoute={newRoute}
        setNewRoute={setNewRoute}
        dynTitle={dynTitle}
        setDynTitle={setDynTitle}
        openSidebar={openSidebar}
        setOpenSidebar={setOpenSidebar}
      />
      <Right
        links={links}
        newRoute={newRoute}
        setNewRoute={setNewRoute}
        verifiedKYC={verifiedKYC}
        dynTitle={dynTitle}
        setDynTitle={setDynTitle}
        setVerifiedKYC={setVerifiedKYC}
        openSidebar={openSidebar}
        setOpenSidebar={setOpenSidebar}
        calcRoute={calcRoute}
        setCalcRoute={setCalcRoute}
      />
    </div>
  );
}


export default Dashboard;