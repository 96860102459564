import React, { useEffect } from "react";
import style from "./emailVerify.module.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import success_icon from "../../../assets/images/success-icon.svg";
import error_icon from "../../../assets/images/error-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { resendOtp, verifyEmailCode } from "../../../store/auth/authActions";


const VerifyEmailPage = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch();
  const {isVerified, isVerifyMsg, loading} = useSelector((state) => state.auth);
  const search = useLocation().search;

  const handleProceed = () =>{
    navigate('/login')
  }

  const handleResendLink = () =>{
    const userId = new URLSearchParams(search).get("tokenId");
    dispatch(resendOtp({
      userId: userId
    }))
  }

  useEffect(() => {
    const code = new URLSearchParams(search).get("code");
    const userId = new URLSearchParams(search).get("tokenId");
    if(code && userId){
        dispatch(verifyEmailCode({
            code: code,
            userId: userId
        }))
      }
  }, [dispatch, search]);



  return (
    <div className={style.login}>
      <Link to="/">
        <div className={style.logo}>
          <img src={logo} alt="astroafrica.site/v1" />
        </div>
      </Link>
      <div className={style.log}>
        <div className={style.left}>
          <div className={style.contLeft}>

            {
                isVerified 
                ?
                <>
                <div>
                  <img src={success_icon} alt="success icon" />
                    </div>

                    <h2 className={style.title}>Email Verification</h2>
                    <p>
                    Your email has been successfully verified, Kindly proceed to login to your account.
                    </p>
                
                    <div className={style.form}>
                        <button
                        onClick={handleProceed}
                            className={style.pay}
                            type="submit"
                            style={{marginTop: '40px'}}
                        >
                            Proceed to Login
                        </button>
                    
                    </div>
                </>
                :
                <>
                <div>
                 <img src={error_icon} alt="error icon" />
                 </div>

                <h2 className={style.title}>Email Not Verified</h2>
                <p>
                    {isVerifyMsg}
                </p>

                <div className={style.form}>
                       {isVerifyMsg === "Expired token" && <button
                        onClick={handleResendLink}
                            className={style.pay}
                            type="submit"
                            disabled={loading}
                            style={{marginTop: '40px'}}
                        >
                            Resend Link
                        </button>
                        }
                    
                    </div>
                </>
            }
            
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default VerifyEmailPage;
