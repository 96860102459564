import { Icon } from "@iconify/react";
import { DocumentCopy, TransactionMinus } from "iconsax-react";
import React, { useRef } from "react";
import IfEmpty from "../ifEmpty/IfEmpty";
import style from "./transactions.module.css";
import empty1 from "../../assets/images/empty1.svg";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import cogoToast from "cogo-toast";
import Lottie from "lottie-react";
import loader from "../../assets/lottie/loader.json";
import { getDepositTransactionsById, getWithdrawalTransactionsById } from "../../store/transactions/transActions";
import moment from "moment";
import Pagination from "../Pagination/Pagination";

const Cash = ({
  transactions,
  cashLocation,
  transBarOpen,
  setTransBarOpen,
  searchInput,
  currentPage, 
  getMoreItemsByOffset,
  pageSize,
  transCat
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [currNo, setCurrNo] = useState(0);
  const [filteredTrans, setFilteredTrans] = useState([]);

  const refId = useRef();

  const isLoading = useSelector((state) => state.trans.loading);
  const withdrawals = useSelector((state) => state.trans.withdrawals);
  const cryptoWithdrawals = useSelector((state) => state.trans.cryptoOutTrans);
  const walletData = useSelector((state) => state.wallet.data);
  const filtTrans = useSelector((state) => state.trans.filteredTransaction);

  const copyRefId = () => {
    refId.current.innerText.select();
    refId.execCommand("copy");

    cogoToast.success("ref id copied to clipboard successfully");
  };

  const handleTransactionId = (transId) => {
    setTransBarOpen(true);
    console.log(transId);
    dispatch(getDepositTransactionsById({ transId }));
  };

  const handleWithdrawalId = (transId) => {
    setTransBarOpen(true);
    console.log(transId);
    dispatch(getWithdrawalTransactionsById({ transId }));
  };

  const formatTagName = (value) => {
    let res;
    switch (value) {
      case "Wallet withdrawer":
        res = "Wallet Withdrawal";
        break;
      default:
        break;
    }
    return res;
  };

  const handleSearch = () => {
    let result =
      transactions &&
      transactions.filter((trans) => {
        if (trans.reference.includes(searchInput)) {
          return trans;
        }
      });
    setFilteredTrans(result);
    console.log(filteredTrans);
  };

  useEffect(() => {
    if (isLoading === true) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isLoading]);

  useEffect(() => {
    handleSearch();
  }, [searchInput]);

  return (
    <div className={style.table}>
      {/* =========================================== DEPOSIT TABLE STARTS FROM HERE  */}

      {cashLocation === "deposit" && (
        <>
          {loading === true ? (
            <div className={style.lottieWrap}>
              <Lottie
                style={{
                  width: "70px",
                  height: "70px",
                  color: "#fff",
                  padding: "0px",
                  margin: "0px",
                }}
                animationData={loader}
                loop={true}
              />
            </div>
          ) : (
            <>
              {transactions && transactions.length > 0 ? (
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>
                          <div className={style.tableHead}>
                            <h5>Name</h5>
                            <Icon
                              className={style.arrowCon}
                              icon="ph:arrows-down-up-fill"
                            />
                          </div>
                        </th>
                        <th>
                          <div className={style.tableHead}>
                            <h5>Date</h5>
                            <Icon
                              className={style.arrowCon}
                              icon="ph:arrows-down-up-fill"
                            />
                          </div>
                        </th>
                        <th>
                          <div className={style.tableHead}>
                            <h5>Reference ID</h5>
                            <Icon
                              className={style.arrowCon}
                              icon="ph:arrows-down-up-fill"
                            />
                          </div>
                        </th>
                        <th>
                          <div className={style.tableHead}>
                            <h5>Amount</h5>
                            <Icon
                              className={style.arrowCon}
                              icon="ph:arrows-down-up-fill"
                            />
                          </div>
                        </th>
                        <th>
                          <div className={style.tableHead}>
                            <h5>Status</h5>
                            <Icon
                              className={style.arrowCon}
                              icon="ph:arrows-down-up-fill"
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>

                    {/* This is where all the transactions are mapped into the table */}

                    <tbody>
                      {transactions.map((item) => (
                        <tr
                          className={style.fullRow}
                          onClick={(transId) => {
                            handleTransactionId((transId = item.id));
                          }}
                          key={item.id}
                        >
                          <td className={style.user}>
                            <div className={style.image}>
                              {/* <img src="" alt="" /> */}
                            </div>
                          </td>
                          <td>
                            <div className={style.name}>
                              <h6>{item.tag}</h6>
                              <p>{item.tag === "Fund Wallet" && "Deposit"}</p>
                            </div>
                          </td>
                          <td>
                            <div className={style.date}>
                              <h6>
                                {item.created_at
                                  ? moment(item.created_at).format(
                                      "Do, MMM YYYY"
                                    )
                                  : ""}
                              </h6>
                              <p>
                                at
                                {item.created_at
                                  ? moment(item.created_at).format("hh:mm:ss a")
                                  : ""}
                              </p>
                            </div>
                          </td>
                          <td>
                            <h6 ref={refId} className={style.invoice}>
                              {item.reference}
                            </h6>
                          </td>
                          <td>
                            <h6 className={style.amount}>
                              {new Intl.NumberFormat("NG", {
                                style: "currency",
                                currency:
                                  walletData.fiatWallets[currNo].Currency.code,
                              }).format(item.amount)}
                            </h6>
                          </td>
                          <td>
                            {item.status === "DONE" && (
                              <p className={style.success}>Successful</p>
                            )}
                            {item.status === "SUBMITTED" && (
                              <p className={style.pending}>Pending</p>
                            )}
                            {item.status === "FAILED" && (
                              <p className={style.failed}>Failed</p>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  {/* =============================== design for the mobile view */}

                  {transactions.map((item) => (
                    <div key={item.id} className={style.transList}>
                      <div className={style.eachTrans}>
                        <div className={style.et}>
                          <div className={style.etPic}>
                            <img src={empty1} alt="" />
                          </div>
                          <div className={style.etTitle}>
                            <h4>{item.tag}</h4>
                            <p>{item.tag === "Fund Wallet" && "Deposit"}</p>
                          </div>
                          <div className={style.etTime}>
                            <p>
                              {item.created_at
                                ? moment(item.created_at).format("Do, MMM YYYY")
                                : ""}
                            </p>
                            <span>
                              {item.created
                                ? moment(item.created_at).format("hh:mm:ss a")
                                : ""}
                            </span>
                          </div>
                        </div>
                        <div className={style.etDet}>
                          <p>Invoice ID</p>
                          <p
                            style={{
                              fontSize: "14px",
                              color: "#727272",
                              width: "100px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {item.reference}
                          </p>
                        </div>
                        <div className={style.etDet}>
                          <p>Amount</p>
                          <p>
                            {new Intl.NumberFormat("NG", {
                              style: "currency",
                              currency:
                                walletData.fiatWallets[currNo].Currency.code,
                            }).format(item.amount)}
                          </p>
                        </div>
                        <div className={style.etDet}>
                          <p>Status</p>
                          {item.status === "SUBMITTED" && (
                            <p className={style.pending}>Pending</p>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className={style.emptyCont}>
                  {cashLocation === "deposit" && (
                    <IfEmpty
                      title="sorry, you have not made any deposits transactions"
                      image={empty1}
                    />
                  )}
                  {cashLocation === "withdrawals" && (
                    <IfEmpty
                      title="sorry, you have not yet made any withdrawal"
                      image={empty1}
                    />
                  )}
                  {cashLocation === "payments" && (
                    <IfEmpty
                      title="sorry, no bill payments recorded"
                      image={empty1}
                    />
                  )}
                </div>
              )}
            </>
          )}
        </>
      )}

      {/* =========================================== WITHDRAWAL TABLE STARTS FROM HERE  */}

      {cashLocation === "withdrawals" && transCat === "Cash" && (
        <>
          {loading === true ? (
            <div className={style.lottieWrap}>
              <Lottie
                style={{
                  width: "70px",
                  height: "70px",
                  color: "#fff",
                  padding: "0px",
                  margin: "0px",
                }}
                animationData={loader}
                loop={true}
              />
            </div>
          ) : (
            <>
              {withdrawals.length > 0 ? (
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>
                          <div className={style.tableHead}>
                            <h5>Name</h5>
                            <Icon
                              className={style.arrowCon}
                              icon="ph:arrows-down-up-fill"
                            />
                          </div>
                        </th>
                        <th>
                          <div className={style.tableHead}>
                            <h5>Date</h5>
                            <Icon
                              className={style.arrowCon}
                              icon="ph:arrows-down-up-fill"
                            />
                          </div>
                        </th>
                        <th>
                          <div className={style.tableHead}>
                            <h5>Reference</h5>
                            <Icon
                              className={style.arrowCon}
                              icon="ph:arrows-down-up-fill"
                            />
                          </div>
                        </th>
                        <th>
                          <div className={style.tableHead}>
                            <h5>Amount</h5>
                            <Icon
                              className={style.arrowCon}
                              icon="ph:arrows-down-up-fill"
                            />
                          </div>
                        </th>
                        <th>
                          <div className={style.tableHead}>
                            <h5>Status</h5>
                            <Icon
                              className={style.arrowCon}
                              icon="ph:arrows-down-up-fill"
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>

                    {/* This is where all the transactions are mapped into the table */}

                    <tbody>
                      {withdrawals?.map((item) => (
                        <tr
                          onClick={(transId) => {
                            handleWithdrawalId((transId = item.id));
                            console.log(item.PaymentOutwards[0]);
                          }}
                          className={style.fullRow}
                          key={item.id}
                        >
                          <td className={style.user}>
                            <div className={style.image}>
                              {/* <img src="" alt="" /> */}
                            </div>
                          </td>
                          <td>
                            <div className={style.name}>
                              <h6>
                                {item.BankAccount.details.account_name
                                  ? item.BankAccount.details.account_name
                                  : ""}
                              </h6>
                              <p>
                                {/* {item.PaymentOutwards[0]
                                  ? item.PaymentOutwards[0].tag
                                  : ""} */}
                                {item.PaymentOutwards[0]
                                  ? formatTagName(item.PaymentOutwards[0].tag)
                                  : ""}
                              </p>
                            </div>
                          </td>
                          <td>
                            <div className={style.date}>
                              <h6>
                                {item.created_at
                                  ? moment(item.created_at).format(
                                      "Do, MMM YYYY"
                                    )
                                  : ""}
                              </h6>
                              <p>
                                at
                                {item.created_at
                                  ? moment(item.created_at).format("hh:mm:ss a")
                                  : ""}
                              </p>
                            </div>
                          </td>
                          <td>
                            <h6 ref={refId} className={style.invoice}>
                              {item.PaymentOutwards[0]
                                ? item.PaymentOutwards[0].reference
                                : "no reference available"}
                            </h6>
                          </td>
                          <td>
                            <h6 className={style.amount}>
                              {new Intl.NumberFormat("NG", {
                                style: "currency",
                                currency:
                                  walletData.fiatWallets[currNo].Currency.code,
                              }).format(item.amount)}
                            </h6>
                          </td>
                          <td>
                            {item.status === "DONE" && (
                              <p className={style.success}>Success</p>
                            )}
                            {item.status === "PROCESSING" && (
                              <p className={style.pending}>Pending</p>
                            )}
                            {item.status === "SUBMITTED" && (
                              <p className={style.pending}>Pending</p>
                            )}
                            {item.status === "FAILED" && (
                              <p className={style.failed}>Declined</p>
                            )}
                            {item.status === "CANCELED" && (
                              <p className={style.failed}>Declined</p>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  {/* ========================== The mobile view for withdrawals */}

                  <div className={style.transList}>
                    {withdrawals?.map((item) => (
                      <div key={item.id} className={style.transList}>
                        <div className={style.eachTrans}>
                          <div className={style.et}>
                            <div className={style.etPic}>
                              <img src={empty1} alt="" />
                            </div>
                            <div className={style.etTitle}>
                              <h4>
                                {item.BankAccount.details.account_name
                                  ? item.BankAccount.details.account_name
                                  : ""}
                              </h4>
                              <p>
                                {item.PaymentOutwards[0]
                                  ? item.PaymentOutwards[0].tag
                                  : "N/A"}
                              </p>
                            </div>
                            <div className={style.etTime}>
                              <p>
                                {item.created_at
                                  ? moment(item.created_at).format(
                                      "Do, MM YYYY"
                                    )
                                  : ""}
                              </p>
                              <span>
                                at
                                {item.created_at
                                  ? moment(item.created_at).format("hh:mm:ss a")
                                  : ""}
                              </span>
                            </div>
                          </div>
                          <div className={style.etDet}>
                            <p>Reference</p>
                            <p
                              style={{
                                fontSize: "12px",
                                color: "#727272",
                                width: "100px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.PaymentOutwards[0]
                                ? item.PaymentOutwards[0].reference
                                : "no reference available"}
                            </p>
                          </div>
                          <div className={style.etDet}>
                            <p>Amount</p>
                            <p>
                              {new Intl.NumberFormat("NG", {
                                style: "currency",
                                currency:
                                  walletData.fiatWallets[currNo].Currency.code,
                              }).format(item.amount)}
                            </p>
                          </div>
                          <div className={style.etDet}>
                            <p>Status</p>
                            {item.status === "SUBMITTED" && (
                              <p className={style.pending}>Pending</p>
                            )}
                            {item.status === "PROCESSING" && (
                              <p className={style.pending}>Pending</p>
                            )}
                            {item.status === "DONE" && (
                              <p className={style.success}>Success</p>
                            )}
                            {item.status === "FAILED" && (
                              <p className={style.pending}>Pending</p>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className={style.emptyCont}>
                  {cashLocation === "deposit" && (
                    <IfEmpty
                      title="sorry, you have not made any deposits transactions"
                      image={empty1}
                    />
                  )}
                  {cashLocation === "withdrawals" && (
                    <IfEmpty
                      title="sorry, you have not yet made any withdrawal"
                      image={empty1}
                    />
                  )}
                  {cashLocation === "payments" && (
                    <IfEmpty
                      title="sorry, no bill payments recorded"
                      image={empty1}
                    />
                  )}
                </div>
              )}
            </>
          )}
        </>
      )}

      {/* =========================================== WITHDRAWAL TABLE STARTS FROM HERE  */}

      {cashLocation === "withdrawals" && transCat === "Crypto" && (
        <>
          {loading === true ? (
            <div className={style.lottieWrap}>
              <Lottie
                style={{
                  width: "70px",
                  height: "70px",
                  color: "#fff",
                  padding: "0px",
                  margin: "0px",
                }}
                animationData={loader}
                loop={true}
              />
            </div>
          ) : (
            <>
              {cryptoWithdrawals && cryptoWithdrawals.length > 0 ? (
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>
                          <div className={style.tableHead}>
                            <h5>Name</h5>
                            <Icon
                              className={style.arrowCon}
                              icon="ph:arrows-down-up-fill"
                            />
                          </div>
                        </th>
                        <th>
                          <div className={style.tableHead}>
                            <h5>Date</h5>
                            <Icon
                              className={style.arrowCon}
                              icon="ph:arrows-down-up-fill"
                            />
                          </div>
                        </th>
                        <th>
                          <div className={style.tableHead}>
                            <h5>Reference</h5>
                            <Icon
                              className={style.arrowCon}
                              icon="ph:arrows-down-up-fill"
                            />
                          </div>
                        </th>
                        <th>
                          <div className={style.tableHead}>
                            <h5>Amount</h5>
                            <Icon
                              className={style.arrowCon}
                              icon="ph:arrows-down-up-fill"
                            />
                          </div>
                        </th>
                        <th>
                          <div className={style.tableHead}>
                            <h5>Status</h5>
                            <Icon
                              className={style.arrowCon}
                              icon="ph:arrows-down-up-fill"
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>

                    {/* This is where all the transactions are mapped into the table */}

                    <tbody>
                      {cryptoWithdrawals?.map((item) => (
                        <tr
                          onClick={(transId) => {
                            handleWithdrawalId((transId = item.id));
                            console.log(item.PaymentOutwards[0]);
                          }}
                          className={style.fullRow}
                          key={item.id}
                        >
                          <td className={style.user}>
                            <div className={style.image}>
                              {/* <img src="" alt="" /> */}
                            </div>
                          </td>
                          <td>
                            <div className={style.name}>
                              <h6>
                                {item.CryptoAccount
                                  ? item.CryptoAccount.coin_name
                                  : "N/A"}
                              </h6>
                              <p>
                                {/* {item.PaymentOutwards[0]
                                  ? item.PaymentOutwards[0].tag
                                  : ""} */}
                                {item.CryptoAccount
                                  ? item.CryptoAccount.wallet_address
                                  : ""}
                              </p>
                            </div>
                          </td>
                          <td>
                            <div className={style.date}>
                              <h6>
                                {item.created_at
                                  ? moment(item.created_at).format(
                                      "Do, MMM YYYY"
                                    )
                                  : ""}
                              </h6>
                              <p>
                                at
                                {item.created_at
                                  ? moment(item.created_at).format("hh:mm:ss a")
                                  : ""}
                              </p>
                            </div>
                          </td>
                          <td>
                            <h6 ref={refId} className={style.invoice}>
                              {item.CryptoAccount
                                ? item.CryptoAccount.network
                                : "no network found"}
                            </h6>
                          </td>
                          <td>
                            <h6 className={style.amount}>
                              {new Intl.NumberFormat("NG", {
                                style: "currency",
                                currency: "USD",
                              }).format(item.amount)}
                            </h6>
                          </td>
                          <td>
                            {item.status === "DONE" && (
                              <p className={style.success}>Success</p>
                            )}
                            {item.status === "PROCESSING" && (
                              <p className={style.pending}>Pending</p>
                            )}
                            {item.status === "SUBMITTED" && (
                              <p className={style.pending}>Pending</p>
                            )}
                            {item.status === "FAILED" && (
                              <p className={style.failed}>Declined</p>
                            )}
                            {item.status === "CANCELED" && (
                              <p className={style.failed}>Declined</p>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  {/* ========================== The mobile view for withdrawals */}

                  <div className={style.transList}>
                    {withdrawals?.map((item) => (
                      <div key={item.id} className={style.transList}>
                        <div className={style.eachTrans}>
                          <div className={style.et}>
                            <div className={style.etPic}>
                              <img src={empty1} alt="" />
                            </div>
                            <div className={style.etTitle}>
                              <h4>
                                {item.CryptoAccount
                                  ? item.CryptoAccount.coin_name
                                  : "N/A"}
                              </h4>
                              <p>
                                {item.CryptoAccount
                                  ? item.CryptoAccount.wallet_address
                                  : ""}
                              </p>
                            </div>
                            <div className={style.etTime}>
                              <p>
                                {item.created_at
                                  ? moment(item.created_at).format(
                                      "Do, MM YYYY"
                                    )
                                  : ""}
                              </p>
                              <span>
                                at
                                {item.created_at
                                  ? moment(item.created_at).format("hh:mm:ss a")
                                  : ""}
                              </span>
                            </div>
                          </div>
                          <div className={style.etDet}>
                            <p>Reference</p>
                            <p
                              style={{
                                fontSize: "12px",
                                color: "#727272",
                                width: "100px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.CryptoAccount
                                ? item.CryptoAccount.network
                                : "no network found"}
                            </p>
                          </div>
                          <div className={style.etDet}>
                            <p>Amount</p>
                            <p>
                              {new Intl.NumberFormat("NG", {
                                style: "currency",
                                currency:"USD"
                              }).format(item.amount)}
                            </p>
                          </div>
                          <div className={style.etDet}>
                            <p>Status</p>
                            {item.status === "SUBMITTED" && (
                              <p className={style.pending}>Pending</p>
                            )}
                            {item.status === "PROCESSING" && (
                              <p className={style.pending}>Pending</p>
                            )}
                            {item.status === "DONE" && (
                              <p className={style.success}>Success</p>
                            )}
                            {item.status === "FAILED" && (
                              <p className={style.pending}>Pending</p>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className={style.emptyCont}>
                  {cashLocation === "deposit" && (
                    <IfEmpty
                      title="sorry, you have not made any deposits transactions"
                      image={empty1}
                    />
                  )}
                  {cashLocation === "withdrawals" && (
                    <IfEmpty
                      title="sorry, you have not yet made any withdrawal"
                      image={empty1}
                    />
                  )}
                  {cashLocation === "payments" && (
                    <IfEmpty
                      title="sorry, no bill payments recorded"
                      image={empty1}
                    />
                  )}
                </div>
              )}
            </>
          )}
        </>
      )}

      {/* =========================================== PAYMENTS TABLE STARTS FROM HERE  */}

      {cashLocation === "payments" && (
        <>
          {loading === true ? (
            <div className={style.lottieWrap}>
              <Lottie
                style={{
                  width: "70px",
                  height: "70px",
                  color: "#fff",
                  padding: "0px",
                  margin: "0px",
                }}
                animationData={loader}
                loop={true}
              />
            </div>
          ) : (
            <>
              {withdrawals.length > 0 ? (
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>
                          <div className={style.tableHead}>
                            <h5>Payment</h5>
                            <Icon
                              className={style.arrowCon}
                              icon="ph:arrows-down-up-fill"
                            />
                          </div>
                        </th>
                        <th>
                          <div className={style.tableHead}>
                            <h5>Date</h5>
                            <Icon
                              className={style.arrowCon}
                              icon="ph:arrows-down-up-fill"
                            />
                          </div>
                        </th>
                        <th>
                          <div className={style.tableHead}>
                            <h5>Invoice ID</h5>
                            <Icon
                              className={style.arrowCon}
                              icon="ph:arrows-down-up-fill"
                            />
                          </div>
                        </th>
                        <th>
                          <div className={style.tableHead}>
                            <h5>Amount</h5>
                            <Icon
                              className={style.arrowCon}
                              icon="ph:arrows-down-up-fill"
                            />
                          </div>
                        </th>
                        <th>
                          <div className={style.tableHead}>
                            <h5>Status</h5>
                            <Icon
                              className={style.arrowCon}
                              icon="ph:arrows-down-up-fill"
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>

                    {/* This is where all the transactions are mapped into the table */}

                    <tbody>
                      {withdrawals?.map((item) => (
                        <tr className={style.fullRow} key={item.id}>
                          <td className={style.user}>
                            <div className={style.image}>
                              {/* <img src="" alt="" /> */}
                            </div>
                          </td>
                          <td>
                            <div className={style.name}>
                              <h6>{item.tag}</h6>
                              <p>{item.tag === "Fund Wallet" && "Deposit"}</p>
                            </div>
                          </td>
                          <td>
                            <div className={style.date}>
                              <h6>{item.created_at.slice(0, 10)}</h6>
                              <p>
                                at {item.created_at.slice(11, 16)}
                                {parseInt(item.created_at.slice(11, 13)) > 11
                                  ? "PM"
                                  : "AM"}
                              </p>
                            </div>
                          </td>
                          <td>
                            <h6 ref={refId} className={style.invoice}>
                              {item.reference}
                            </h6>
                          </td>
                          <td>
                            <h6 className={style.amount}>
                              {new Intl.NumberFormat("NG", {
                                style: "currency",
                                currency: "NGN",
                              }).format(item.amount)}
                            </h6>
                          </td>
                          <td>
                            {item.status === "DONE" && (
                              <p className={style.success}>{item.status}</p>
                            )}
                            {item.status === "PROCESSING" && (
                              <p className={style.pending}>Pending</p>
                            )}
                            {item.status === "FAILED" && (
                              <p className={style.failed}>{item.status}</p>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <div className={style.transList}>here we are</div>
                </div>
              ) : (
                <div className={style.emptyCont}>
                  {cashLocation === "deposit" && (
                    <IfEmpty
                      title="sorry, you have not made any deposits transactions"
                      image={empty1}
                    />
                  )}
                  {cashLocation === "withdrawals" && (
                    <IfEmpty
                      title="sorry, you have not yet made any withdrawal"
                      image={empty1}
                    />
                  )}
                  {cashLocation === "payments" && (
                    <IfEmpty
                      title="sorry, no bill payments recorded"
                      image={empty1}
                    />
                  )}
                </div>
              )}
            </>
          )}
        </>
      )}
      {/* <div style={{
        width: "100%",
        display: "flex",
        justifyContent: "center"
      }} >

      <Pagination
        currentPage={currentPage}
        totalCount={20}
        pageSize={pageSize}
        onPageChange={(page) => getMoreItemsByOffset(page)}
        />
        </div> */}
      {/* <div className="flex justify-center mt-5 mb-10">
        <PaginationComponent
          currentPage={currentPage}
          totalCount={total}
          pageSize={PageSize}
          onPageChange={(page) => getMoreItemsByOffset(page)}
        />
      </div> */}
    </div>
  );
};

export default Cash;

// {
//   transactions?.map((item) => (

//   ));
// }
