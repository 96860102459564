import React from 'react'
import st from './if-empty.module.css'
import empty1 from '../../assets/images/empty1.svg'
import { useNavigate } from 'react-router-dom'

const IfEmpty = ({ title, route, buttonText, image, funct }) => {
    
    const navigate = useNavigate();

  return ( 
        <div className={st.emptyWrap} >
          <div className={st.empty} >
              <div className={st.image} >
                  <img src={image} alt="" />
              </div>
              <div>
                  <p className={st.peeh} >{ title}</p>
                  { buttonText && (<button 
            onClick={() => {
              navigate(route)
              if (funct) {
                return funct;
              }
            }}
                      className={st.proceed} >{buttonText}</button>)}
              </div>
        </div>
    </div>
  )
}

export default IfEmpty;