import React, { useEffect, useRef, useState } from "react";
import style from "./wallet.module.css";
import Lottie from "lottie-react";
import loader from "../../assets/lottie/loader.json";
import { ArrowDown2, Warning2 } from "iconsax-react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBankAccounts,
  getCryptoAccounts,
} from "../../store/settings/settingsActions";
import Modal from "../modal/Modal";
import PinInput from "react-pin-input";
import { useNavigate } from "react-router-dom";
import {
  withdrawCrypto,
  withdrawToBank,
} from "../../store/wallet/walletActions";
import Modal2 from "../modal/Modal2";
import phones from "../../assets/images/phonesAstro.svg";
import appStore from "../../assets/images/AppStore.svg";
import playStore from "../../assets/images/PlayStore.svg";
import { closeDmOpen, setDmDoneOnce } from "../../store/wallet/walletSlice";
import { browserName } from "react-device-detect";
import cogoToast from "cogo-toast";
import { InfoCircle } from "iconsax-react";

const acctTypes = [
  { id: 1, title: "Savings" },
  { id: 2, title: "Current" },
  { id: 3, title: "Domiciliary" },
  { id: 4, title: "Salary" },
  { id: 5, title: "Fixed deposit" },
  { id: 6, title: "Diaspora" },
];

const Withdraw = ({ setCurr, setMultiplier, difference, cryptoDifference }) => {
  const [bankAcctId, setBankAcctId] = useState("");
  const [fiatWalletId, setFiatWalletId] = useState("");
  const [trPin, setTrPin] = useState("");

  let ele = useRef();

  const [wallet, setWallet] = useState("");
  const [walletType, setWalletType] = useState("");
  const [openWallet, setOpenWallet] = useState(false);
  const [account, setAccount] = useState("");
  const [weBuy, setWeBuy] = useState(0);
  const [amount, setAmount] = useState(0);
  const [openAccount, setOpenAccount] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [loading, setLoading] = useState(false);

  const [settingsLoading, setSettingsLoading] = useState(false);
  const [isPin, setIsPin] = useState(false);
  const [putPass, setPutPass] = useState(false);
  const [cryptoPass, setCryptoPass] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [openPopup, setOpenPopup] = useState(false);
  const [sortedAccounts, setSortedAccounts] = useState([]);

  const isLoading = useSelector((state) => state.wallet.loading);
  const walletData = useSelector((state) => state.wallet.data);
  const isPinSet = useSelector((state) => state.auth.pinData.isSetPin);
  const isDmOpen = useSelector((state) => state.wallet.dmOpen);
  const isDmDoneOnce = useSelector((state) => state.wallet.dmDoneOnce);
  const allAccounts = useSelector((state) => state.settings.allAccounts);
  const allSettings = useSelector((state) => state.wallet.settings);

  const isSettingsLoading = useSelector(
    (state) => state.wallet.settingsLoading
  );
  const allCryptoAccounts = useSelector(
    (state) => state.settings.allCryptoAccounts
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleWallet = () => {
    setOpenWallet((prevWallet) => {
      if (prevWallet === false) {
        return true;
      } else if (prevWallet === true) {
        return false;
      }
    });
  };

  const handleWallet = (e) => {
    setOpenWallet(false);
    setWallet(e.target.textContent);

    walletType === "USD"
      ? dispatch(getCryptoAccounts())
      : dispatch(getBankAccounts());
  };

  const toggleAccount = () => {
    setOpenAccount((prevAccount) => {
      if (prevAccount === false) {
        return true;
      } else if (prevAccount === true) {
        return false;
      }
    });
  };

  const handleAccount = (e) => {
    setOpenAccount(false);
    setAccount(e.target.textContent);
    // setFormPI({
    //     ...formPI, country: e.target.textContent
    // })
    // console.log(formPI)
  };

  const handleWithdraw = (e) => {
    e.preventDefault();
    setPutPass(true);
  };

  const handleWithdrawCrypto = (e) => {
    e.preventDefault();
    setCryptoPass(true);
  };

  const withdrawHandler = () => {
    if (amount > difference) {
      cogoToast.error(
        "The amount you want to withdraw has EXCEEDED your daily limit, please go to settings to upgrade"
      );
    } else {
      dispatch(
        withdrawToBank({
          bankAcctId,
          fiatWalletId,
          trPin,
          amount,
        })
      );
    }
    ele.clear();
    setPutPass(false);
    setTrPin("");
    setWallet("");
    setAccount("");
    setAmount(0);
  };

  const withdrawCryptoHandler = () => {
    setCryptoPass(false);
    if (amount > cryptoDifference) {
      cogoToast.error(
        "The amount you want to withdraw has EXCEEDED your daily limit, please go to settings to upgrade"
      );
    } else {
      dispatch(
        withdrawCrypto({
          bankAcctId,
          fiatWalletId,
          trPin,
          amount,
        })
      );
    }
    setTrPin("");
    setWallet("");
    setAccount("");
    setAmount(0);

    ele.clear();
  };

  const navToPin = () => {
    // here I will route to settings
    navigate("/settings");
    // here I will set the newRoute to setPin
  };

  console.log(ele);

  const handleSortAccounts = () => {
    let newSort = [];
    if (allAccounts.bankAccounts && allAccounts.bankAccounts.length > 0) {
      if (walletType === "NGN") {
        allAccounts.bankAccounts.filter((item) => {
          if (item.type === "ng-account" || item.type.value === "ng-account") {
            newSort.push(item ? item : null);
          }
        });
        setSortedAccounts(newSort);
      } else if (walletType === "GHS") {
        allAccounts.bankAccounts.filter((item) => {
          if (
            item.type === "gh-account" ||
            item.type === "gh-mobile" ||
            item.type.value === "gh-account" ||
            item.type.value === "gh-mobile"
          ) {
            newSort.push(item ? item : null);
          }
        });
        setSortedAccounts(newSort);
      } else if (walletType === "USD") {
        setSortedAccounts(allCryptoAccounts.cryptotAccounts);
      }
    }
    console.log(sortedAccounts);
  };

  useEffect(() => {
    handleSortAccounts();
    setAccount("");
  }, [walletType]);

  useEffect(() => {
    if (isLoading === true) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    if (amount !== 0 && amount !== "" && account !== "" && wallet !== "") {
      setIsFilled(true);
    } else {
      setIsFilled(false);
    }
  }, [isLoading, amount, account, wallet]);

  useEffect(() => {
    if (isSettingsLoading === true) {
      setSettingsLoading(true);
    } else {
      setSettingsLoading(false);
    }
  }, [isSettingsLoading]);

  useEffect(() => {
    if (isPinSet === true) {
      setIsPin(true);
    } else {
      setIsPin(false);
    }
  }, [isPinSet]);

  useEffect(() => {
    if (trPin !== "" && trPin.length === 5) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [trPin]);

  useEffect(() => {
    if (isDmOpen === true && isDmDoneOnce === false) {
      setOpenPopup(true);
    } else if (isDmOpen === true && isDmDoneOnce === true) {
      setOpenPopup(false);
    } else {
      setOpenPopup(false);
    }
  }, [isDmOpen, isDmDoneOnce]);

  // HANDLE WALLET CLOSE ON CLICK OUT
  useEffect(() => {
    let handler = (e) => {
      if (e.target.id !== "wallet") {
        setOpenWallet(false);
      } else if (e.target.id === "wallet") {
        toggleWallet();
      }
      if (e.target.id === "walletDrop") {
        setOpenWallet(true);
      }
      // console.log(currRef.current.name)
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  // HANDLE ACCOUNT CLOSE ON CLICK OUT
  useEffect(() => {
    let handler = (e) => {
      if (e.target.id !== "account") {
        setOpenAccount(false);
      } else if (e.target.id === "account") {
        toggleAccount();
      }
      if (e.target.id === "accountDrop") {
        setOpenAccount(true);
      }
      // console.log(currRef.current.name)
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  return (
    <div style={{ margin: "2rem 0 0 0" }} className={style.form}>
      <Modal2
        closeFunct={() => {
          dispatch(closeDmOpen());
          dispatch(setDmDoneOnce());
        }}
        modalOpen={openPopup}
        canClose={true}
      >
        <div className={style.popupOverlay}>
          <div className={style.popupCard}>
            <div className={style.ppLeft}>
              <img src={phones} alt="astroafrica.site" />
            </div>
            <div className={style.ppRight}>
              <div className={style.pprUp}>
                <h2>
                  Download the Astro <br /> mobile app to have a more <br />{" "}
                  seamless experience
                </h2>
              </div>
              <div className={style.pprDown}>
                <a
                  href="https://apps.apple.com/ng/app/astro-africa/id6447790674 "
                  target="blank"
                >
                  <img src={appStore} alt="astroafrica.site" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=site.astroafrica.app"
                  target="blank"
                >
                  <img src={playStore} alt="astroafrica.site" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </Modal2>
      {/* {settingsLoading === true ? (
        <div className={style.lottieWrap}>
          <Lottie
            style={{
              width: "70px",
              height: "70px",
              color: "#fff",
              padding: "0px",
              margin: "0px",
            }}
            animationData={loader}
            loop={true}
          />
        </div>
      ) : (
        <>
          {allSettings && allSettings.settings ? (
            <div className={style.minMax}>
              <div className={style.mmPop}>
                <p>
                  min:{" "}
                  {new Intl.NumberFormat("NG", {
                    style: "currency",
                    currency: `${walletType !== "" ? walletType : "USD"}`,
                  }).format(
                    walletType !== ""
                      ? allSettings.settings.withdrawal_min_amount.value.data *
                          weBuy
                      : 0
                  )}
                </p>
              </div>
              <div className={style.mmPop}>
                <p>
                  max:{" "}
                  {new Intl.NumberFormat("NG", {
                    style: "currency",
                    currency: `${walletType !== "" ? walletType : "USD"}`,
                  }).format(
                    walletType !== ""
                      ? allSettings.settings.withdrawal_max_amount.value.data *
                          weBuy
                      : 0
                  )}
                </p>
              </div>
            </div>
          ) : (
            <div className={style.minMax}>noSettings</div>
          )}
        </>
      )} */}

      <Modal
        canClose={true}
        modalOpen={cryptoPass}
        setModalOpen={setCryptoPass}
      >
        {isPin === true && (
          <div className={style.setTrPin}>
            <h2 className={style.pinHead}>Transaction Pin</h2>
            <p>Please input your transaction pin</p>
            <div className={style.inputs2}>
              <PinInput
                length={5}
                initialValue=""
                secret={true}
                // ref={(ele)}
                onChange={(value, index) => {
                  setTrPin(value);
                }}
                type="numeric"
                inputMode="number"
                style={{ padding: "10px", margin: "0 auto 0 auto" }}
                inputStyle={{ border: "1px solid", borderColor: "#909090" }}
                inputFocusStyle={{
                  border: "2px solid",
                  borderColor: "#40196D",
                }}
                onComplete={(value, index) => {
                  setTrPin(value);
                }}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
            </div>
            <button
              disabled={disabled}
              onClick={withdrawCryptoHandler}
              className={style.save}
            >
              Withdraw Funds
            </button>
          </div>
        )}
      </Modal>
      <Modal canClose={true} modalOpen={putPass} setModalOpen={setPutPass}>
        {isPin === true && (
          <div className={style.setTrPin}>
            <h2 className={style.pinHead}>Transaction Pin</h2>
            <p>Please input your transaction pin</p>
            <div className={style.inputs2}>
              <PinInput
                length={5}
                initialValue=""
                secret={true}
                ref={(n) => {
                  ele = n;
                }}
                onChange={(value, index) => {
                  setTrPin(value);
                }}
                type="numeric"
                inputMode="number"
                style={{ padding: "10px", margin: "0 auto 0 auto" }}
                inputStyle={{ border: "1px solid", borderColor: "#909090" }}
                inputFocusStyle={{
                  border: "2px solid",
                  borderColor: "#40196D",
                }}
                onComplete={(value, index) => {
                  setTrPin(value);
                }}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
            </div>
            <button
              disabled={disabled}
              onClick={withdrawHandler}
              className={style.save}
            >
              Withdraw Funds
            </button>
          </div>
        )}
      </Modal>

      <form action="">
        <div className={style.labelPut}>
          <label htmlFor="acct_type"> Select Wallet for Withdrawal</label>
          <div className={style.select}>
            <div id="wallet"
              name="acctType" className={style.selectInput}>
              <input
                
                type="text"
                disabled={browserName === "Firefox" ? false : true}
                value={wallet}
                placeholder="Please select a Wallet"
                style={{pointerEvents: "none"}}
              />
              <ArrowDown2
                style={{
                  transform:
                    openWallet === true
                      ? "translateY( -50%) rotate(-540deg)"
                      : "translateY( -50%) rotate(0deg)",
                  pointerEvents: "none",
                }}
                className={style.dropDown}
                size="20"
                color="#909090"
                variant="Bold"
              />
            </div>
            <div
              style={{
                height: openWallet === true ? "fit-content" : "0px",
              }}
              className={style.option}
            >
              {walletData.fiatWallets ? (
                walletData.fiatWallets.map((item) => (
                  <p
                    id="walletDrop"
                    key={item.id}
                    onClick={(e) => {
                      handleWallet(e);
                      setFiatWalletId(item.id);
                      setWalletType(item.Currency.code);
                      setCurr(item.Currency.code);
                      setWeBuy(item.Currency.withdrawer_rate);
                      setMultiplier(item.Currency.withdrawer_rate);
                    }}
                  >
                    {item.Currency.code} Wallet
                  </p>
                ))
              ) : (
                <p>No wallets here</p>
              )}
            </div>
            {/* <input type="text" placeholder='Enter Last Name' /> */}
          </div>
        </div>

        <div className={style.labelPut}>
          <label htmlFor="acct_type">Select Account</label>
          <div className={style.select}>
            <div id="account"
              name="acctType" className={style.selectInput}>
              <input
                
                type="text"
                disabled={browserName === "Firefox" ? false : true}
                value={account}
                placeholder="Please select an account"
                style={{pointerEvents: "none"}}
              />
              <ArrowDown2
                style={{
                  transform:
                    openAccount === true
                      ? "translateY( -50%) rotate(-540deg)"
                      : "translateY( -50%) rotate(0deg)",
                  pointerEvents: "none",
                }}
                className={style.dropDown}
                size="20"
                color="#909090"
                variant="Bold"
              />
            </div>
            <div
              style={{
                height: openAccount === true ? "fit-content" : "0px",
              }}
              className={style.option}
            >
              {sortedAccounts && sortedAccounts.length > 0 ? (
                sortedAccounts.map((item) => (
                  <p
                    id="accountDrop"
                    key={item.id}
                    onClick={(e) => {
                      handleAccount(e);
                      setBankAcctId(item.id);
                    }}
                  >
                    {item.wallet_address ? (
                      `${item.coin_type} wallet - ${item.wallet_address}`
                    ) : (
                      <>
                        {item.details
                          ? item.details.account_name
                          : "nothing to display"}{" "}
                        -{" "}
                        {item.details
                          ? item.details.bank_name ||
                            item.details.bankName ||
                            item.bank_code
                          : "nothing to display"}
                      </>
                    )}
                  </p>
                ))
              ) : (
                <p>{`Sorry you do not seem to have a ${walletType} wallet`}</p>
              )}
            </div>
            {/* <input type="text" placeholder='Enter Last Name' /> */}
          </div>
        </div>
        {/* <div className={style.labelPut}>
          <label htmlFor="number"> Comment </label>
          <div className={style.input}>
            <input
              className={style.cardImgTa}
              name="comment"
              id=""
              placeholder="Add Comments"
            />
          </div>
        </div> */}

        <div className={style.labelPut}>
          <label htmlFor="number"> Enter Amount </label>
          <div className={style.input}>
            <input
              className={style.cardImgTa}
              name="comment"
              id=""
              value={amount}
              type="number"
              onChange={(e) => {
                setAmount(e.target.value < 0 ? 0 : e.target.value);
              }}
            />
          </div>
        </div>

        <div style={{display: 'flex', gap: '10px'}}>
            <InfoCircle size="24" variant="Bold" color="#F40000" />
            <p>There is a charge of NGN40 using the NGN Wallet</p>
         </div>

        {loading === true ? (
          <div className={style.lottieWrap}>
            <Lottie
              style={{
                width: "70px",
                height: "70px",
                color: "#fff",
                padding: "0px",
                margin: "0px",
              }}
              animationData={loader}
              loop={true}
            />
          </div>
        ) : (
          <>
            {walletType === "USD" ? (
              <button
                onClick={(e) => {
                  handleWithdrawCrypto(e);
                }}
                className={style.save}
                disabled={!isFilled}
              >
                Proceed
              </button>
            ) : (
              <button
                onClick={(e) => {
                  handleWithdraw(e);
                }}
                className={style.save}
                disabled={!isFilled}
              >
                Proceed
              </button>
            )}
          </>
        )}
      </form>
    </div>
  );
};

export default Withdraw;
