import React, { useEffect } from 'react'
import { useState } from 'react'
import style from './navbar.module.css'
import { ArrowDown2, HambergerMenu, LogoutCurve, Notification, SearchNormal1, Setting2, User } from 'iconsax-react';
import woman from '../../assets/images/woman.png'
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getBankAccounts } from '../../store/settings/settingsActions';
import IfEmpty from '../ifEmpty/IfEmpty';
import empty1 from '../../assets/images/empty1.svg'
import avatar from "../../assets/images/user1.svg";
import { logout } from '../../store/auth/authSlice';
import { clearProfileImage } from '../../store/settings/settingsSlice';


const Navbar = ({ links, setNewRoute, newRoute , dynTitle, openSidebar, setOpenSidebar }) => {

  const [openDD, setOpenDD] = useState(false);
  const [openNF, setOpenNF] = useState(false);
  const [head, setHead] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch()
    

  const userData = useSelector(state => state.auth.user);
  const profileImg = useSelector((state) => state.settings.profileImage);
  const header = useSelector((state) => state.settings.header);
  console.log(userData)

  useEffect(() => { 
    setHead(header)
  },[header])
  
  return (
    <div className={style.navbar}>
      <div className={style.navContainer}>
        <div className={style.left}>
          <h4>{head}</h4>
          <HambergerMenu
            onClick={() => {
              setOpenSidebar(true);
            }}
            className={style.ham}
            size="32"
            color="#1a1a1a"
            variant="Outline"
          />
        </div>
        <div className={style.right}>
          {/* <div className={style.icons1}>
            <SearchNormal1 size="26" />
          </div> */}

          <div className={style.icons1}>
            <div
              style={{
                height: openNF === true ? "500px" : "0px",
                padding: openNF === true ? "1.25rem" : "0px",
              }}
              className={style.notifs}
            >
              <div className={style.notifsWrap}>
                <IfEmpty
                  title="Sorry, you currently have no notifications"
                  image={empty1}
                />
              </div>
            </div>
            <Notification
              size="26"
              onClick={() => {
                setOpenNF(!openNF);
              }}
            />
            <div className={style.dot}>1</div>
            <div className={style.notifCard}></div>
          </div>

          <div className={style.profileInfo}>
            <div className={style.prImg}>
              <img
                src={
                  profileImg && profileImg.length > 0
                    ? profileImg[0].publicUrl || profileImg
                    : avatar
                }
                alt="astroafrica.site/woman.png"
              />
            </div>
            {/* <p>{userData.first_name}</p> */}
            <Icon
              onClick={() => {
                setOpenDD(!openDD);
              }}
              style={{
                transform:
                  openDD === true
                    ? "translateY(0px) rotate(-180deg)"
                    : "translateY(0px) rotate(0deg)",
                fontSize: "20px",
              }}
              className={style.dropCon}
              icon="mdi:chevron-down"
            />
          </div>

          <div
            style={{
              height: openDD === true ? "226px" : "0px",
              padding: openDD === true ? "8px" : "0px",
            }}
            className={style.dropdown}
          >
            <h5>
              {userData
                ? `${userData.first_name}  ${userData.last_name}`
                : "User"}
            </h5>

            <div className={style.linkWrap}>
              <div
                onClick={() => {
                  navigate("/settings");
                  setOpenDD(!openDD);
                  setNewRoute("settings");
                }}
                className={style.link}
              >
                <User size="20" color="#727272" variant="Bold" />
                <p>Your details</p>
              </div>
              <div
                onClick={() => {
                  navigate("/settings");
                  setNewRoute("bank");
                  setOpenDD(!openDD);
                  dispatch(getBankAccounts());
                }}
                className={style.link}
              >
                <Setting2 size="20" color="#727272" variant="Outline" />
                <p>Account settings</p>
              </div>
              <div
                onClick={() => {
                  setOpenDD(!openDD);
                  dispatch(logout());
                  dispatch(clearProfileImage());
                }}
                className={style.link}
              >
                <LogoutCurve size="20" color="#727272" variant="Outline" />
                <p>Log out</p>
              </div>
            </div>
          </div>

          <div
            onClick={() => {
              setOpenDD(!openDD);
            }}
            className={style.prImg2}
          >
            <img
              src={
                profileImg && profileImg.length > 0
                  ? profileImg[0].publicUrl || profileImg
                  : avatar
              }
              alt="astroafrica.site/profilePhoto.png"
            />
          </div>
        </div>
      </div>
      <div
        className={style.closeDrop}
        onClick={() => {
          setOpenDD(false);
          setOpenNF(false);
        }}
        style={{
          display: openDD === true || openNF === true ? "block" : "none",
        }}
      ></div>
    </div>
  );
}

export default Navbar