import React, { useEffect, useState } from "react";
import style from "./trade.module.css";
import amazon from "../../assets/images/cards/amazon.svg";
import amexgold from "../../assets/images/cards/amexgold.svg";
import apple from "../../assets/images/cards/apple.svg";
import ebay from "../../assets/images/cards/ebay.svg";
import google from "../../assets/images/cards/google.svg";
import itunes from "../../assets/images/cards/itunes.svg";
import nike from "../../assets/images/cards/nike.svg";
import nordstorm from "../../assets/images/cards/nordstorm.svg";
import razer from "../../assets/images/cards/razer.svg";
import sephora from "../../assets/images/cards/sephora.svg";
import steam from "../../assets/images/cards/steam.svg";
import target from "../../assets/images/cards/target.svg";
import vanilla from "../../assets/images/cards/vanilla.svg";
import visa from "../../assets/images/cards/visa.svg";
import walmart from "../../assets/images/cards/walmart.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Lottie from "lottie-react";
import loader from "../../assets/lottie/loader.json";
import { getAllCards, getCardByUid } from "../../store/Calc/calcActions";
import IfEmpty from "../ifEmpty/IfEmpty";
import empty2 from "../../assets/images/empty2.svg";
import { setHeader } from "../../store/settings/settingsSlice";
import { clearCardDetails } from "../../store/Calc/calcSlice";

const cards = [
  { id: 1, route: "amazon", title: "Amazon", image: amazon },
  { id: 2, route: "amex-gold", title: "Amex Gold", image: amexgold },
  { id: 3, route: "ebay", title: "Ebay", image: ebay },
  { id: 4, route: "google-play", title: "Google Play", image: google },
  { id: 5, route: "itunes", title: "iTunes", image: itunes },
  { id: 6, route: "nike", title: "Nike", image: nike },
  { id: 7, route: "nordstorm", title: "Nordstorm", image: nordstorm },
  { id: 8, route: "one-vanilla", title: "One Vanilla", image: vanilla },
  { id: 9, route: "steam", title: "Steam", image: steam },
  { id: 10, route: "walmart", title: "Walmart", image: walmart },
  { id: 11, route: "apple-store", title: "Apple Store", image: apple },
  { id: 12, route: "target", title: "Target", image: target },
  { id: 13, route: "razer-gold", title: "Razer Gold", image: razer },
  { id: 14, route: "visa", title: "Visa", image: visa },
  { id: 15, route: "sephora", title: "Sephora", image: sephora },
];

const Trade = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cards = useSelector((state) => state.calc.cards);

  const isLoading = useSelector((state) => state.calc.loading);

  const callCards = () => {
    dispatch(getAllCards());
    dispatch(clearCardDetails())
  }; 

  useEffect(() => {
    dispatch(setHeader("Trade Now"));
  
  }, []);

  useEffect(() => {
    dispatch(getAllCards());
  }, []);

  useEffect(() => {
    if (isLoading === true) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isLoading]);

  return (
    <div className={style.trade}>
      <div className={style.headerText}>
        {/* <h4>Welcome back, User</h4> */}
      </div>

      <div className={style.cardWrap} >
        {loading === false ? (
          <div className={style.gridWrap}>
            {cards.length > 0 ? (
              <div className={style.grid}>
                {cards.map((card) => (
                  <div
                    key={card.id}
                    onClick={() => {
                      dispatch(getCardByUid({ uid: card.uid }));
                      navigate(`/trade/${card.uid}`);
                    }}
                    className={style.card}
                  >
                    <div className={style.cardImg}>
                      <img
                        src={card.image}
                        alt={`astroafrica.site/${card.name}`}
                      />
                    </div>
                    <p className={style.cardTitle}>{card.name}</p>
                  </div>
                ))}
              </div>
            ) : (
              <div className={style.lottieWrap}>
                <IfEmpty
                  route="/trade"
                  buttonText="Reload"
                  funct={callCards}
                  image={empty2}
                  title="Oops! an error Occured"
                />
              </div>
            )}
          </div>
        ) : (
          <div className={style.lottieWrap}>
            <Lottie
              style={{
                width: "140px",
                height: "140px",
                color: "#fff",
              }}
              animationData={loader}
              loop={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Trade;
