import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../config";
import cogoToast from "cogo-toast";

// ================================================================= GET ALL TRADABLE COINS

export const getAllCoins = createAsyncThunk(
  "getAllCoins",
  async (values, { rejectWithValue, dispatch, getState }) => {
    const id = getState().auth.user.user_id;
    try {
      const res = await axios.get(`${baseUrl}/coin/type?userId=${id}`, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": getState().auth.token.toString(),
        },
      });
      if (res.status === 200) {
        console.log(res);
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
); 

// ================================================================= GET ALL COIN BY ID

export const getCoinById = createAsyncThunk(
  "getCoinById",
  async (values, { rejectWithValue, dispatch, getState }) => {
   
    try {
      const res = await axios.get(`${baseUrl}/coin/rate?coinTypeId=${values.coinTypeId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": getState().auth.token.toString(),
        },
      });
      if (res.status === 200) {
        console.log(res);
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

// ================================================================= TRADE NEW COIN

export const tradeNewCoin = createAsyncThunk(
  "tradeNewCoin",
  async (values, { rejectWithValue, dispatch, getState }) => {
    const id = getState().auth.user.user_id;
    try {
      const res = await axios.post(
        `${baseUrl}/coin/sell/${id}`,
        {
          coinTypeId: values.coinTypeId,
          coinNetworkId: values.coinNetworkId,
          amount: values.amount,
          rate: values.coinTypeRate,
          payoutAmount: values.payoutAmount,
          creditWalletId: values.creditWalletId,
          sellerRemarks: "",
          coinAmount: parseFloat(values.coinAmount).toFixed(6)
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": getState().auth.token.toString(),
          },
        }
      );
      if (res.status === 200 || res.status === 201) {
        console.log(res);
        cogoToast.success("Trade Created Successfully");
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 401) {
        cogoToast.error("Wallet Not created");
        // dispatch({ type: "auth/Logout" });
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else if (error.response.status === 410) {
        cogoToast.error("Error generating barCode, please try again, Thank you!")
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);


// ================================================================= FETCH CRYPTO TRANSACTIONS

export const fetchCryptoTransactions = createAsyncThunk(
  "fetchCryptoTransactions",
  async (values, { rejectWithValue, dispatch, getState }) => {
    const id = getState().auth.user.user_id;
    try {
      const res = await axios.get(
        `${baseUrl}/coin/inward/${id}`,
         {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": getState().auth.token.toString(),
          },
        }
      );
      if (res.status === 200 || res.status === 201) {
        console.log(res);
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 401) {
        cogoToast.error("Wallet Not created");
        // dispatch({ type: "auth/Logout" });
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);