import { ArrowDown2, Copy, InfoCircle } from "iconsax-react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { setHeader, setTradeStatus } from "../../store/settings/settingsSlice";
import IfEmpty from "../ifEmpty/IfEmpty";
import Modal from "../modal/Modal";
import Prompt from "../prompt/Prompt";
import style from "../crypto/crypto-trade.module.css";
import Lottie from "lottie-react";
import loader from "../../assets/lottie/loader.json";
import barcode from "../../assets/images/barcode.png";
import doneDots from "../../assets/images/doneDots.svg";
import cogoToast from "cogo-toast";
import {
  getAllCoins,
  getCoinById,
  tradeNewCoin,
} from "../../store/crypto/cryptoActions";
import QRCode from "react-qr-code";
import {
  clearCoins,
  clearNewcoinData,
  clearRates,
} from "../../store/crypto/cryptoSlice";
import { getFiatCurrencies } from "../../store/wallet/walletActions";
import { Link } from "react-router-dom";
import { browserName } from "react-device-detect";

const CryptoRates = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [coinTypeId, setCoinTypeId] = useState("");
  const [amount, setAmount] = useState("");
  const [rate, setRate] = useState(0);
  const [coinTypeRate, setCoinTypeRate] = useState(0);
  const [usdValue, setUsdValue] = useState(0);
  const [payoutAmount, setPayoutAmount] = useState(0.0);
  const [walletId, setWalletId] = useState("");
  const [creditWalletId, setCreditWalletId] = useState("");

  const [wallet, setWallet] = useState("");
  const [weBuy, setWeBuy] = useState(0);
  const [openWallet, setOpenWallet] = useState(false);
  const [cardCat, setCardCat] = useState("");
  const [openCardCat, setOpenCardCat] = useState(false);
  const [openBarcode, setOpenBarcode] = useState(false);
  const [doneDisabled, setDoneDisabled] = useState(true);
  const [imageLoading, setImageLoading] = useState(false);
  const [coinRateLoading, setCoinRateLoading] = useState(false);
  const [openAfterProceed, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [barLoading, setBarLoading] = useState(false);
  const [proceeded, setProceeded] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [coinLoading, setCoinLoading] = useState(false);
  const [openPrompt, setOpenPrompt] = useState(false);
  const [tradeDone, setTradeDone] = useState(false);

  const copyRef = useRef();

  const walletData = useSelector((state) => state.wallet.data);
  const isLoading = useSelector((state) => state.crypto.barLoading);
  const coins = useSelector((state) => state.crypto.coins);
  const coinRates = useSelector((state) => state.crypto.coinRates.coinRates);
  const isCoinLoading = useSelector((state) => state.crypto.coinLoading);
  const isBarcodeLoading = useSelector((state) => state.crypto.barcodeLoading);
  const newcoinData = useSelector((state) => state.crypto.newcoinData);
  const isCoinRateLoading = useSelector(
    (state) => state.crypto.coinRateLoading
  );
  const addWallets = useSelector((state) =>
    state.wallet.fiatCurrencies.data
      ? state.wallet.fiatCurrencies.data.fiat
      : ""
  );

  // const dollarWallet = wwalletData && walletData.fiatWallets.filter(
  //   (item) => item.Currency.code === "USD"
  // );

  const cta1 = () => {
    setOpenPrompt(false);
  };

  const handleWallet = (e) => {
    setOpenWallet(false);
    setWallet(e.target.textContent);
  };

  const toggleWallet = () => {
    setOpenWallet((prevWallet) => {
      if (prevWallet === true) {
        return false;
      } else if (prevWallet === false) {
        return true;
      }
    });
  };

  const toggleCardCat = () => {
    setOpenCardCat((prevCardCat) => {
      if (prevCardCat === true) {
        return false;
      } else if (prevCardCat === false) {
        return true;
      }
    });
  };

  const handleCardCat = (title) => {
    setCardCat(title);
    setOpenCardCat(false);
  };

  const copyAddress = () => {
    let refToCopy = copyRef.current.textContent;
    // refToCopy.select();
    navigator.clipboard.writeText(refToCopy);
    cogoToast.success("Copied to Clipboard", {
      position: "top-right",
    });
    console.log(refToCopy);
  };

  const handleProceed = (e) => {
    e.preventDefault();
    setOpenBarcode(true);
    dispatch(
      tradeNewCoin({
        coinTypeId,
        amount,
        coinTypeRate,
        payoutAmount,
        creditWalletId,
      })
    );
  };

  useEffect(() => {
    if (coinRates) {
      let newArr = [];
      let newMinArr = [];
      let newMaxArr = [];
      let minRate;
      for (let i = 0; i < coinRates.length; i++) {
        if (
          walletId === coinRates[i].fiat_currency_id &&
          coinTypeId === coinRates[i].coin_type_id &&
          parseInt(usdValue) <= coinRates[i].maximum_usd_Value &&
          parseInt(usdValue) >= coinRates[i].minimum_usd_value
        ) {
          setCoinTypeRate(coinRates[i].rate);
          break;
        } else if (
          coinRates &&
          coinRates.length &&
          walletId === coinRates[i].fiat_currency_id &&
          coinTypeId === coinRates[i].coin_type_id
        ) {
          newArr.push(coinRates[i]);
          function getArr() {
            return newArr.map((item) => item.minimum_usd_value);
          }
          function getRateArr() {
            return newArr.map((item) => item.rate);
          }
          function getMinArr() {
            newMinArr = Math.min(...getArr());
          }
          function getMaxArr() {
            newMaxArr = Math.max(...getArr());
          }
          getMinArr();
          getMaxArr();

          if (parseInt(usdValue) < newMinArr) {
            newArr.filter((item) => {
              if (parseInt(item.minimum_usd_value) === newMinArr) {
                setCoinTypeRate(item.rate);
              }
            });
            console.log("smaller");
          } else if (parseInt(usdValue) > newMaxArr) {
            newArr.filter((item) => {
              if (parseInt(item.maximum_usd_value) === newMaxArr) {
                setCoinTypeRate(item.rate);
              }
            });
            console.log("bigger");
          }
        }
      }

      console.log(usdValue);
      console.log(newMinArr);
      console.log(newMaxArr);
      console.log(newArr);
      console.log(
        parseInt(coinTypeRate),
        parseInt(weBuy),
        walletId,
        coinTypeId,
        parseInt(amount)
      );
    }

    if (
      amount === NaN ||
      amount === 0 ||
      amount === "" ||
      usdValue === NaN ||
      usdValue === 0 ||
      usdValue === ""
    ) {
      setCoinTypeRate(0);
    }
  }, [coinTypeId, amount, rate, payoutAmount, walletId, usdValue]);

  useEffect(() => {
    setPayoutAmount(parseInt(coinTypeRate) * usdValue);
  }, [
    coinTypeId,
    amount,
    rate,
    payoutAmount,
    walletId,
    usdValue,
    coinTypeRate,
  ]);

  useEffect(() => {
    if (isLoading === true) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    if (isCoinLoading === true) {
      setCoinLoading(true);
    } else {
      setCoinLoading(false);
    }

    if (isCoinRateLoading === true) {
      setCoinRateLoading(true);
    } else {
      setCoinRateLoading(false);
    }

    if (isBarcodeLoading === true) {
      setBarLoading(true);
    } else {
      setBarLoading(false);
    }
  }, [isLoading, isCoinLoading, isCoinRateLoading, isBarcodeLoading]);

  useEffect(() => {
    dispatch(setHeader("Sell Crypto"));
  }, []);

  useEffect(() => {
    if (wallet !== "" && cardCat !== "" && amount !== "" && amount !== 0) {
      setDoneDisabled(false);
    } else {
      setDoneDisabled(true);
    }
  }, [wallet, cardCat, amount]);

  useEffect(() => {
    let newUsdValue = Math.round(amount * rate);
    setUsdValue(newUsdValue);
  }, [rate, amount]);

  useEffect(() => {
    let newAmount = usdValue / rate;
    setAmount(newAmount);
  }, [rate, usdValue]);

  useEffect(() => {
    setAmount(0);
    setUsdValue(0);
  }, [setCoinTypeId]);

  useEffect(() => {
    dispatch(setHeader("Sell Crypto"));
  }, []);

  // HANDLE WALLET CLOSE ON CLICK OUT
  useEffect(() => {
    let handler = (e) => {
      if (e.target.id !== "wallet") {
        setOpenWallet(false);
        console.log(openWallet);
      } else if (e.target.id === "wallet") {
        toggleWallet();
      }
      if (e.target.id === "walletDrop") {
        setOpenWallet(true);
      }
      // console.log(currRef.current.name)
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  // HANDLE CARD CAT CLOSE ON CLICK OUT
  useEffect(() => {
    let handler = (e) => {
      if (e.target.id !== "cardCat") {
        setOpenCardCat(false);
        console.log(openCardCat);
      } else if (e.target.id === "cardCat") {
        toggleCardCat();
      }
      if (e.target.id === "cardCatDrop") {
        setOpenCardCat(true);
      }
      // console.log(currRef.current.name)
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  console.log(walletData.fiatWallets);

  return (
    <div className={style.left2}>
      <div>
        <form action="">
          <div
            style={{ display: openBarcode === true ? "none" : "block" }}
            className={style.labelPut}
          >
            <label htmlFor="acct_type"> Payout Wallet</label>
            <div className={style.select}>
              <div id="wallet"
                name="acctType" className={style.selectInput}>
                <input
                   style={{pointerEvents: "none"}}
                  type="text"
                  disabled={browserName === "Firefox" ? false : true}
                  value={wallet}
                  placeholder="Please select a Wallet"
                />
                <ArrowDown2
                  style={{
                    transform:
                      openWallet === true
                        ? "translateY( -50%) rotate(-540deg)"
                        : "translateY( -50%) rotate(0deg)",
                    pointerEvents: "none",
                  }}
                  className={style.dropDown}
                  size="20"
                  color="#909090"
                  variant="Bold"
                />
              </div>
              <div
                style={{
                  height: openWallet === true ? "fit-content" : "0px",
                }}
                className={style.option}
              >
                {addWallets && addWallets.length ? (
                  addWallets.map((item) => (
                    <p
                      id="walletDrop"
                      key={item.id}
                      style={{
                        display: item.code === "USD" ? "none" : "block",
                      }}
                      onClick={(e) => {
                        handleWallet(e);
                        setWeBuy(item.we_buy);
                        setWalletId(item.id);
                        setCreditWalletId(item.id);
                        dispatch(getAllCoins());
                      }}
                    >
                      {item.code} Wallet
                    </p>
                  ))
                ) : (
                  <p>Sorry, no wallets here</p>
                )}
              </div>
              {/* <input type="text" placeholder='Enter Last Name' /> */}
            </div>
          </div>
          <div className={style.labelPut}>
            <label htmlFor="acct_type">
              {openBarcode === true ? "Coin" : "Select Coin"}
            </label>
            <div className={style.select}>
              <div id="cardCat"
                name="acctType" className={style.selectInput}>
                <input
                  style={{pointerEvents: "none"}}
                  type="text"
                  disabled={browserName === "Firefox" ? false : true}
                  value={cardCat}
                  placeholder="Please select a Category"
                />
                {coinLoading === true ? (
                  <div className={style.lottieWrapDrop2}>
                    <Lottie
                      style={{
                        width: "70px",
                        height: "70px",
                        color: "#fff",
                      }}
                      animationData={loader}
                      loop={true}
                    />
                  </div>
                ) : (
                  <>
                    {openBarcode === true ? (
                      ""
                    ) : (
                      <ArrowDown2
                        style={{
                          transform:
                            openCardCat === true
                              ? "translateY( -50%) rotate(-540deg)"
                              : "translateY( -50%) rotate(0deg)",
                          pointerEvents: "none",
                        }}
                        className={style.dropDown}
                        size="20"
                        color="#909090"
                        variant="Bold"
                      />
                    )}
                  </>
                )}
              </div>
              <div
                style={{
                  display: openBarcode === true ? "none" : "flex",
                  height: openCardCat === true ? "fit-content" : "0px",
                }}
                className={style.option}
              >
                {coins.coinTypes && coins.coinTypes.length > 0
                  ? coins.coinTypes.map((item) => (
                      <div
                        id="cardCatDrop"
                        key={item.id}
                        className={style.cardItem}
                        onClick={() => {
                          setCoinTypeId(item.id);
                          setRate(item.usd_rate);
                          handleCardCat(item.name);
                          dispatch(getCoinById({ coinTypeId: item.id }));
                        }}
                      >
                        <p style={{ pointerEvents: "none" }}> {item.name} </p>
                      </div>
                    ))
                  : "There was a problem loading wallets, please select a wallet"}
              </div>
              {/* <input type="text" placeholder='Enter Last Name' /> */}
            </div>
          </div>

          <div className={style.labelPut}>
            <label htmlFor="number">
              {" "}
              Coin Amount<span style={{ color: "#F40000" }}>*</span>{" "}
            </label>
            <div className={style.input}>
              <input
                type="number"
                name="acct_number"
                disabled={coinRateLoading || openBarcode}
                placeholder="Enter Amount"
                value={parseFloat(amount).toFixed(4)}
                onChange={(e) => {
                  if (e.target.value < 0) {
                    setAmount("");
                  } else if (e.target.value === "") {
                    setAmount("");
                  } else {
                    setAmount(e.target.value);
                  }
                }}
              />
            </div>
            {coinRateLoading === true ? (
              <p style={{ fontSize: "12px", margin: "6px 0 0 0" }}>
                {" "}
                Please wait while we fetch rates...{" "}
              </p>
            ) : (
              ""
            )}
          </div>

          <div className={style.labelPut}>
            <label htmlFor="number"> USD Value </label>
            <div className={style.input}>
              <input
                type="number"
                name="acct_number"
                placeholder="Enter Amount"
                disabled={coinRateLoading || openBarcode}
                value={usdValue}
                onChange={(e) => {
                  setUsdValue(e.target.value);
                }}
              />
            </div>
            <p>1 usd = {coinTypeRate && coinTypeRate > 0 ? coinTypeRate : 0}</p>
          </div>

          <div className={style.labelPut2}>
            <InfoCircle size="24" variant="Bold" color="#F40000" />
            <p>The minimum amount is $20</p>
          </div>

          <div className={style.labelPut}>
            <label htmlFor="number"> You Receive </label>
            <div className={style.input}>
              <input
                type="number"
                name="acct_number"
                placeholder="Enter Amount"
                value={payoutAmount === NaN ? "" : payoutAmount}
                disabled
                onChange={(e) => {
                  if (e.target.value <= 0) {
                    setAmount("");
                  } else {
                    setAmount(e.target.value);
                  }
                }}
              />
            </div>
          </div>
          {/* <button
            style={{
              display: openBarcode === true ? "none" : "block",
            }}
            disabled={doneDisabled}
            onClick={handleProceed}
            className={style.save}
          >
            Proceed
          </button> */}
        </form>
        {/* <div className={style.rate}> */}
        {/* <div className={style.eachRate}>
          <p>Payout</p>
          <span>
            {payoutAmount > 0
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: walletData.fiatWallets[currNo].Currency.code,
                }).format(cardRate)
              : "0.00"}
          </span>
        </div> */}
        {/* <hr className={style.erHr} /> */}
        {/* <div className={style.eachRate}>
          <p>
            GHS
          </p>
          <span>
            {totalRate > 0
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: currency,
                }).format(totalRate)
              : "0.00"}
          </span>
        </div> */}
        {/* </div> */}
        {/* <button onClick={handleProceed} className={style.proceed}>
        Proceed to Trade
      </button> */}
      </div>
      <div className={style.rate}>
        <div
          style={{
            width: "100%",
            borderTop: "1px #90909039 dashed",
            margin: "2rem 0 0 0 ",
          }}
        >
          <p>
            The calculator is updated everyday to ensure that you are always up
            to date with the current rates. Sell Crypto at the best rates after
            using the calculator on Astro Africa.
          </p>
        </div>
      </div>
      <Link to="/login">
        <button className={style.save}>Proceed</button>
      </Link>
    </div>
  );
};

export default CryptoRates;
