import React, { useEffect, useState } from "react";
import style from "./settings.module.css";
import { Icon } from "@iconify/react";
import Modal from "../modal/Modal";
import { AddCircle, ArrowDown2, CloseCircle } from "iconsax-react";
import { useDispatch, useSelector } from "react-redux";
import {
  addBankAccount,
  addCryptoAccount,
  delBankAccount,
  delCryptoAccount,
  getAccountName,
  getBankAccounts,
  getBanks,
} from "../../store/settings/settingsActions";
import { clearAllBanks } from "../../store/settings/settingsSlice";
import Lottie from "lottie-react";
import loader from "../../assets/lottie/loader.json";
import IfEmpty from "../ifEmpty/IfEmpty"
import empty2 from '../../assets/images/empty2.svg'
import Prompt from "../prompt/Prompt";
import { ghAccounts, mobileMoney } from './data';
import CryptoBank from "./CryptoBank";
import { browserName } from "react-device-detect";


const acctTypes = [
  { id: 1, title: "ng-account" },
  { id: 2, title: "gh-mobile" },
  { id: 6, title: "gh-account" },
];

const banks = [
  { id: 1, title: "GTB" },
  { id: 2, title: "First Bank" },
  { id: 3, title: "UBA" },
  { id: 4, title: "Polaris" },
  { id: 5, title: "Stanbic" },
  { id: 6, title: "KeyStone" },
];

const AddBank = () => {
  const [walletAddress, setWalletAddress] = useState("");
  const [coinName, setCoinName] = useState("");
  const [coinType, setCoinType] = useState("");
  const [network, setNetwork] = useState("");
  const [coinDisabled, setCoinDisabled] = useState(true);

  const [formModalOpen, setFormModalOpen] = useState(false);
  const [formCryptoModalOpen, setFormCryptoModalOpen] = useState(false);
  const [bank, setBank] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [acctNo, setAcctNo] = useState("");
  const [openBank, setOpenBank] = useState(false);
  const [bankType, setBankType] = useState("");
  const [openBankType, setOpenBankType] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nameLoading, setNameLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [acctLoading, setAcctLoading] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [nameDisabled, setNameDisabled] = useState(true);
  const [isFull, setIsFull] = useState(false);
  const [loadModal, setLoadModal] = useState(false);
  const [openPrompt, setOpenPrompt] = useState(false);
  const [openCryptoPrompt, setOpenCryptoPrompt] = useState(false);
  const [bankID, setBankID] = useState("");
  const [acctTabs, setAcctTabs] = useState("cash");

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.settings.loading);
  const isNameLoading = useSelector((state) => state.settings.nameLoading);
  const isSaveLoading = useSelector((state) => state.settings.saveLoading);
  const isAcctLoading = useSelector((state) => state.settings.acctLoading);
  const allBanks = useSelector((state) => state.settings.allBanks);
  const allCoins = useSelector((state) => state.crypto.coins);
  const allAccounts = useSelector((state) => state.settings.allAccounts);
  const accountDets = useSelector((state) => state.settings.accountDets);

  console.log(allCoins);

  useEffect(() => {
    if (isLoading === true) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    if (isNameLoading === true) {
      setNameLoading(true);
    } else {
      setNameLoading(false);
    }

    if (isSaveLoading === true) {
      setSaveLoading(true);
      setLoadModal(true);
    } else {
      setSaveLoading(false);
      setLoadModal(false);
    }

    if (isAcctLoading === true) {
      setAcctLoading(true);
    } else {
      setAcctLoading(false);
    }

    if (bankType === "ng-account") {
      setNameDisabled(true);
    } else if (bankType === "gh-mobile" || bankType === "gh-account") {
      setNameDisabled(false);
    }
  }, [isNameLoading, isLoading, isSaveLoading, isAcctLoading, bankType]);

  //   useEffect(() => {
  //     dispatch(getBankAccounts());
  //   }, [allAccounts]);

  const toggleBank = () => {
    setOpenBank((prev) => {
      if (prev === false) {
        return true;
      } else if (prev === true) {
        return false;
      }
    });
  };

  const handleBank = (bankname, code) => {
    setOpenBank(false);
    setBank(bankname);
    setBankCode(code);
    
    // setFormPI({
    //     ...formPI, country: e.target.textContent
    // })
    // console.log(formPI);
  };

  const toggleBankType = () => {
    setOpenBankType((prev) => {
      if (prev === false) {
        return true;
      } else if (prev === true) {
        return false
      }
    });
  };

  const handleBankType = (title) => {
    setOpenBankType(false);
    setBankType(title);
    setCoinName(coinName);
    setBank("");

    if (title === "ng-account") {
      dispatch(getBanks({ title: "NG" }));
    } else if (title === "gh-account" || title === "gh-mobile") {
      dispatch(clearAllBanks());
    }
  };

  // `${baseUrl}/user-account/${id}/bank-accounts/${bid}`;
  // `${baseUrl}/user-account/${id}/bank-accounts/${bid}`;

  const handleAccountChange = (e) => {
    setAcctNo(e.target.value);
    setAccountName("");
  };

  const handleDelete = (bid) => {
    dispatch(delBankAccount({ bid }));
    dispatch(getBankAccounts());
  };

  const handleAcctName = (e) => {
    setAccountName(e.target.value);
  };

  const handleSave = (e) => {
    e.preventDefault();
    setFormModalOpen(false);

    dispatch(
      addBankAccount({
        accountNumber: acctNo,
        accountName: accountName,
        bankCode: bankCode,
        bankName: bank,
        accountType: bankType,
      })
    );

    setAcctNo("");
    setAccountName("");
    setBankType("");
    setBank("");
  };

  const handleSaveCrypto = (e) => {
    e.preventDefault();
    setFormCryptoModalOpen(false);

    dispatch(
      addCryptoAccount({
        walletAddress,
        coinType,
        network,
        coinName,
      })
    );

    setWalletAddress("");
    setCoinType("");
    setNetwork("");
    setCoinName("");
  };

  useEffect(() => {
    if (acctNo.length === 10) {
      dispatch(getAccountName({ bankCode, accountNumber: acctNo }));
      console.log(bankCode, "with", acctNo);
      setIsFull(true);
    } else {
      setIsFull(false);
    }
    console.log(acctNo.length);
  }, [acctNo]);

  useEffect(() => {
    if (
      walletAddress !== "" &&
      coinType !== "" &&
      network !== "" &&
      coinName !== ""
    ) {
      setCoinDisabled(false);
    } else {
      setCoinDisabled(true);
    }

    if (formCryptoModalOpen === false) {
      setWalletAddress("");
      setCoinType("");
      setNetwork("");
      setCoinName("");
    }
  }, [walletAddress, coinType, network, coinName, setFormCryptoModalOpen]);

  useEffect(() => {
    if (accountDets !== "") {
      setAccountName(accountDets.accountName);
    }
  }, [accountDets]);

  // HANDLE BANK CLOSE ON CLICK OUT
  useEffect(() => {
    let handler = (e) => {
      if (e.target.id !== "bank") {
        setOpenBank(false);
      } else if (e.target.id === "bank") {
        toggleBank();
      }
      if (e.target.id === "bankDrop") {
        setOpenBank(true);
      }
      // console.log(currRef.current.name)
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  // HANDLE BANK TYPE CLOSE ON CLICK OUT
  useEffect(() => {
    let handler = (e) => {
      if (e.target.id !== "bankType") {
        setOpenBankType(false);
      } else if (e.target.id === "bankType") {
        toggleBankType();
      }
      if (e.target.id === "bankTypeDrop") {
        setOpenBankType(true);
      }
      // console.log(currRef.current.name)
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  return (
    <div className={style.profile}>
      <Prompt
        open={openPrompt}
        message="Are you sure you want to delete this account?"
        cta1={() => {
          dispatch(delBankAccount({ bid: bankID }));
          setOpenPrompt(false);
        }}
        cta1Text="Yes"
        cta2={() => {
          setOpenPrompt(false);
        }}
        cta2Text="No"
      />

      <Prompt
        open={openCryptoPrompt}
        message="Are you sure you want to delete this account?"
        cta1={() => {
          dispatch(delCryptoAccount({ bid: bankID }));
          setOpenCryptoPrompt(false);
        }}
        cta1Text="Yes"
        cta2={() => {
          setOpenCryptoPrompt(false);
        }}
        cta2Text="No"
      />

      {/* =========================== THE MODAL WITH THE FORM TO ADD NORMAL CASH BANK ACCOUNTS  */}
      <Modal
        canClose={true}
        modalOpen={formModalOpen}
        setModalOpen={setFormModalOpen}
      >
        <div className={style.modalTop}>
          <h5 style={{ margin: "0px" }}>Add a Bank Account</h5>
          <p>
            We pay your withdrawals into your account. <br />
            Please enter your account number
          </p>
        </div>
        <form action="">
          <div className={style.labelPut}>
            <label htmlFor="acct_type"> Account Type </label>
            <div className={style.select}>
              <div
                id="bankType"
                name="acctType"
                style={{ pointerEvents: loading === true ? "none" : "" }}
                className={style.selectInput}
                >
                <input
                  
                  style={{ pointerEvents: "none"}}
                  type="text"
                  disabled={browserName === "Firefox" ? false : true}
                  value={bankType}
                  placeholder="Please select a Bank"
                />
                <ArrowDown2
                  style={{
                    transform:
                      openBankType === true
                        ? "translateY( -50%) rotate(-540deg)"
                        : "translateY( -50%) rotate(0deg)",
                    pointerEvents:"none"
                  }}
                  className={style.dropDown}
                  size="20"
                  color="#909090"
                  variant="Bold"
                />
              </div>
              <div
                style={{
                  height: openBankType === true ? "fit-content" : "0px",
                  maxHeight: "120px",
                }}
                className={style.option}
              >
                {acctTypes?.map((item) => (
                  <p
                    id="bankTypeDrop"
                    key={item.id}
                    onClick={(title) => {
                      handleBankType((title = item.title));
                    }}
                  >
                    {item.title}{" "}
                  </p>
                ))}
              </div>
              {/* <input type="text" placeholder='Enter Last Name' /> */}
            </div>
          </div>
          <div className={style.labelPut}>
            <label htmlFor="bank"> Bank</label>
            <div className={style.select}>
              <div
                style={{ pointerEvents: loading === true ? "none" : "" }}
                className={style.selectInput}
                id="bank"
                name="bank"
                >
                <input
                style={{ pointerEvents: "none" }}
                  type="text"
                  disabled={browserName === "Firefox" ? false : true}
                  value={bank}
                  placeholder="Please select a Bank"
                />
                {loading === true ? (
                  <div className={style.lottieWrapDrop}>
                    <Lottie
                      style={{
                        width: "70px",
                        height: "70px",
                        color: "#fff",
                        padding: "0px",
                        margin: "0px",
                      }}
                      animationData={loader}
                      loop={true}
                    />
                  </div>
                ) : (
                  <ArrowDown2
                    style={{
                      transform:
                        openBankType === true
                          ? "translateY( -50%) rotate(-540deg)"
                            : "translateY( -50%) rotate(0deg)",
                      pointerEvents:"none",
                    }}
                    className={style.dropDown}
                    size="20"
                    color="#909090"
                    variant="Bold"
                  />
                )}
              </div>
              <div
                style={{
                  height: openBank === true ? "120px" : "0px",
                }}
                className={style.option}
              >
                {allBanks && allBanks.length > 0
                  ? allBanks.map((bank) => (
                    <p
                      id="bankDrop"
                        key={bank.id}
                        onClick={(bankname, code) => {
                          handleBank(
                            (bankname = bank.name),
                            (code = bank.code)
                          );
                        }}
                      >
                        {bank.name}
                      </p>
                    ))
                  : ""}
                {bankType === "gh-account" && ghAccounts.length > 0
                  ? ghAccounts.map((bank) => (
                      <p
                        key={bank.id}
                        onClick={(bankname, code) => {
                          handleBank(
                            (bankname = bank.name),
                            (code = bank.code)
                          );
                        }}
                      >
                        {bank.name}
                      </p>
                    ))
                  : ""}
                {bankType === "gh-mobile" && mobileMoney.length > 0
                  ? mobileMoney.map((bank) => (
                      <p
                        key={bank.id}
                        onClick={(bankname, code) => {
                          handleBank(
                            (bankname = bank.name),
                            (code = bank.code)
                          );
                        }}
                      >
                        {bank.name}
                      </p>
                    ))
                  : ""}
              </div>
              {/* <input type="text" placeholder='Enter Last Name' /> */}
            </div>
          </div>
          <div className={style.labelPut}>
            <label htmlFor="number"> Account Number</label>
            <div className={style.input}>
              <input
                onChange={handleAccountChange}
                disabled={isNameLoading}
                type="number"
                name="acct_number"
                placeholder="Enter your account number"
                value={acctNo}
              />
            </div>
          </div>
          <div className={style.labelPut}>
            <label htmlFor="acct_name"> Account Name</label>
            <div className={style.input}>
              <input
                onChange={handleAcctName}
                type="text"
                name="acct_name"
                placeholder="Enter account name"
                disabled={nameDisabled}
                value={accountName}
              />
              {nameLoading === true && (
                <div className={style.lottieWrapDrop}>
                  <Lottie
                    style={{
                      width: "70px",
                      height: "70px",
                      color: "#fff",
                      padding: "0px",
                      margin: "0px",
                    }}
                    animationData={loader}
                    loop={true}
                  />
                </div>
              )}
            </div>
          </div>
          {saveLoading === true ? (
            <div className={style.lottieWrap}>
              <Lottie
                style={{
                  width: "70px",
                  height: "70px",
                  color: "#fff",
                  padding: "0px",
                  margin: "0px",
                }}
                animationData={loader}
                loop={true}
              />
            </div>
          ) : (
            <button onClick={handleSave} className={style.save}>
              Save
            </button>
          )}
        </form>
        {/* </div> */}
      </Modal>

      {/* =========================== THE MODAL WITH THE FORM TO ADD CRYPTO ACCOUNTS  */}
      <Modal
        canClose={true}
        modalOpen={formCryptoModalOpen}
        setModalOpen={setFormCryptoModalOpen}
      >
        <div className={style.modalTop}>
          <h5 style={{ margin: "0px" }}>Add a Crypto Account</h5>
          <p>
            We pay your withdrawals into your account. <br />
            Please fill your account details
          </p>
        </div>
        <form action="">
          <div className={style.labelPut}>
            <label htmlFor="acct_name"> Wallet Address</label>
            <div className={style.input}>
              <input
                onChange={(e) => {
                  setWalletAddress(e.target.value);
                }}
                type="text"
                name="acct_name"
                placeholder="Enter your wallet address"
                // disabled={nameDisabled}
                value={walletAddress}
              />
            </div>
          </div>
          <div className={style.labelPut}>
            <label htmlFor="acct_name"> Coin Type</label>
            <div className={style.input}>
              <input
                onChange={(e) => {
                  setCoinType(e.target.value);
                }}
                type="text"
                name="acct_name"
                placeholder="Enter coin type"
                // disabled={nameDisabled}
                value={coinType}
              />
            </div>
          </div>
          <div className={style.labelPut}>
            <label htmlFor="acct_name"> Network</label>
            <div className={style.input}>
              <input
                onChange={(e) => {
                  setNetwork(e.target.value);
                }}
                type="text"
                name="acct_name"
                placeholder="Enter network type"
                // disabled={nameDisabled}
                value={network}
              />
              {nameLoading === true && (
                <div className={style.lottieWrapDrop}>
                  <Lottie
                    style={{
                      width: "70px",
                      height: "70px",
                      color: "#fff",
                      padding: "0px",
                      margin: "0px",
                    }}
                    animationData={loader}
                    loop={true}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={style.labelPut}>
            <label htmlFor="acct_name"> Coin Name</label>
            <div className={style.input}>
              <input
                onChange={(e) => {
                  setCoinName(e.target.value);
                }}
                type="text"
                name="acct_name"
                placeholder="Enter coin name"
                // disabled={nameDisabled}
                value={coinName}
              />
              {nameLoading === true && (
                <div className={style.lottieWrapDrop}>
                  <Lottie
                    style={{
                      width: "70px",
                      height: "70px",
                      color: "#fff",
                      padding: "0px",
                      margin: "0px",
                    }}
                    animationData={loader}
                    loop={true}
                  />
                </div>
              )}
            </div>
          </div>
          {saveLoading === true ? (
            <div className={style.lottieWrap}>
              <Lottie
                style={{
                  width: "70px",
                  height: "70px",
                  color: "#fff",
                  padding: "0px",
                  margin: "0px",
                }}
                animationData={loader}
                loop={true}
              />
            </div>
          ) : (
            <button
              onClick={handleSaveCrypto}
              disabled={coinDisabled}
              className={style.save}
            >
              Save
            </button>
          )}
        </form>
        {/* </div> */}
      </Modal>
      <Modal canClose={false} modalOpen={loadModal} setModalOpen={setLoadModal}>
        <div className={style.lottieWrap}>
          <Lottie
            style={{
              width: "70px",
              height: "70px",
              color: "#fff",
              padding: "0px",
              margin: "0px",
            }}
            animationData={loader}
            loop={true}
          />
        </div>
      </Modal>
      <div className={style.header}>
        <h2>My Accounts</h2>
        {/* <p>Enter your preferred 5-digit pin for all your transactions on Astro</p> */}
      </div>
      <div className={style.abTabs}>
        <div
          onClick={() => {
            setAcctTabs("cash");
          }}
          className={acctTabs === "cash" ? style.abTabActive : style.abTab}
        >
          <p>Cash</p>
        </div>
        <div
          onClick={() => {
            setAcctTabs("crypto");
          }}
          className={acctTabs === "crypto" ? style.abTabActive : style.abTab}
        >
          <p>Crypto</p>
        </div>
      </div>
      <>
        {acctTabs === "cash" ? (
          <div className={style.accounts}>
            {acctLoading === true && (
              <div className={style.lottieWrap}>
                <Lottie
                  style={{
                    width: "70px",
                    height: "70px",
                    color: "#fff",
                    padding: "0px",
                    margin: "0px",
                  }}
                  animationData={loader}
                  loop={true}
                />
              </div>
            )}
            {allAccounts.bankAccounts && allAccounts.bankAccounts.length > 0 ? (
              allAccounts.bankAccounts.map((item) => (
                <div className={style.account}>
                  <div className={style.acLeft}>
                    <h6>
                      {item.details.bank_name ? (
                        item.details.bank_name
                      ) : (
                        <>
                          {item.details.bankName
                            ? item.details.bankName
                            : item.type.name}
                        </>
                      )}
                    </h6>
                    <p>{item.account_number}</p>
                    <span>{item.details.account_name}</span>
                  </div>
                  <div className={style.acRight}>
                    <Icon
                      className={style.acCon}
                      onClick={() => {
                        setBankID(item.id);
                        setOpenPrompt(true);
                        console.log(bankID);
                      }}
                      icon="material-symbols:delete-forever-outline-rounded"
                    />
                  </div>
                </div>
              ))
            ) : (
              <IfEmpty
                image={empty2}
                title="You haven't added any bank accounts, would you like to add one?"
              />
            )}
          </div>
        ) : (
          <CryptoBank
            openPrompt={openPrompt}
            setOpenPrompt={setOpenPrompt}
            bankID={bankID}
            setBankID={setBankID}
            setOpenCryptoPrompt={setOpenCryptoPrompt}
          />
        )}
      </>

      {acctTabs === "cash" ? (
        <button
          onClick={() => {
            setFormModalOpen(true);
          }}
          className={style.save3}
        >
          <AddCircle size="16" color="#fff" variant="Outline" />
          Add Bank Account
        </button>
      ) : (
        <button
          onClick={() => {
            setFormCryptoModalOpen(true);
          }}
          className={style.save3}
        >
          <AddCircle size="16" color="#fff" variant="Outline" />
          Add Crypto Account
        </button>
      )}
    </div>
  );
};

export default AddBank;
