import { Icon } from '@iconify/react'
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setHeader } from '../../store/settings/settingsSlice'
import style from './referrals.module.css'

const Referrals = () => {

  const copyTextRef = useRef()
  const userData = useSelector(state => state.auth.user)

  const dispatch = useDispatch();

    useEffect(() => {
      dispatch(setHeader("Referrals"));
    }, []);

  const copyText = () => {
    document = copyTextRef.current.innerText.select()
    document.execCommand("copy");
  }


  return (
    <div className={style.referral}>
      <div className={style.card}>
        <h5>Referral</h5>
        <p>Get referal bonuses by sharing your referal link with friends</p>
        <div className={style.copyLink}>
          <p>
            https://astroafrica.site/register?ref=$
            <span ref={copyTextRef}>
              {userData && userData.first_name}
              {userData && userData.last_name}
            </span>
          </p>
          <Icon
            onClick={copyText}
            icon="material-symbols:content-copy-outline-rounded"
          />
        </div>
      </div>
    </div>
  );
}

export default Referrals;