import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import PinInput from 'react-pin-input';
import { useDispatch, useSelector } from 'react-redux';
import style from './settings.module.css'
import Lottie from "lottie-react";
import loader from "../../assets/lottie/loader.json";
import { ref } from 'yup';
import { completeResetPin, resetPin } from '../../store/settings/settingsActions';
import { clearPinRef } from '../../store/settings/settingsSlice';

const ResetPin = () => {

  const [newPin, setNewPin] = useState("")
  const [code, setCode] = useState("")
  const [saveLoading, setSaveLoading] = useState(false)
  const [isCompleted, setIsCompleted] = useState(false)
  
  const dispatch = useDispatch()
  const isSaveLoading = useSelector(state => state.settings.saveLoading)
  const reference = useSelector(state => state.settings.pinRef)
  const isChanged = useSelector(state => state.settings.pinChanged)

  const getOtp = () => {
    dispatch(resetPin());
  }

  const completePinChange = () => {
    dispatch(completeResetPin({code, newPin}))
  } 

  

  useEffect(() => {
    if (isSaveLoading === true) {
      setSaveLoading(true)
    } else {
      setSaveLoading(false)
    }

    if (isChanged === true) {
      dispatch(clearPinRef());
    }

    if (newPin !== "" && code !== "" && newPin.length === 5 && code.length === 6 ) {
      setIsCompleted(true)
    } else {
      setIsCompleted(false)
    }

  }, [isSaveLoading, isChanged, newPin, code]);

  return (
    <div className={style.requestPinWrap}>
      {reference === "" ? (
        <div className={style.requestPin}>
          <h2>Reset Transaction Pin</h2>
          <p style={{ margin: "12px 0 2rem 0" }}>
            Please click on the button below to get your OTP Code in our mail
          </p>
          {saveLoading === true ? (
            <div className={style.lottieWrap}>
              <Lottie
                style={{
                  width: "70px",
                  height: "70px",
                  color: "#fff",
                  padding: "0px",
                  margin: "0px",
                }}
                animationData={loader}
                loop={true}
              />
            </div>
          ) : (
            <button onClick={getOtp} className={style.save}>
              Get OTP Code
            </button>
          )}
        </div>
      ) : (
        <div className={style.requestPin}>
          <h2>Reset Transaction Pin</h2>
          <p style={{ margin: "12px 0 2rem 0" }}>
            Please input the OTP Code sent to your mail and your new pin
          </p>
          <p style={{ margin: "12px 0 0rem 0", textAlign: "left" }}>OTP Code</p>
          <div className={style.inputs3}>
            <PinInput
              length={6}
              initialValue=""
              secret={false}
              onChange={(value, index) => {setCode(value)}}
              type="numeric"
              inputMode="number"
              style={{ padding: "10px", margin: "0 auto 0 auto" }}
              inputStyle={{ border: "1px solid", borderColor: "#909090" }}
              inputFocusStyle={{ border: "2px solid", borderColor: "#40196D" }}
              onComplete={(value, index) => {
                setCode(value);
              }}
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
          </div>
          <p style={{ margin: "12px 0 0rem 0", textAlign: "left" }}>New Pin </p>
          <div className={style.inputs3}>
            <PinInput
              length={5}
              initialValue=""
              secret={true}
              onChange={(value, index) => {setNewPin(value)}}
              type="numeric"
              inputMode="number"
              style={{ padding: "10px", margin: "0 auto 0 auto" }}
              inputStyle={{ border: "1px solid", borderColor: "#909090" }}
              inputFocusStyle={{ border: "2px solid", borderColor: "#40196D" }}
              onComplete={(value, index) => {
                setNewPin(value);
              }}
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
          </div>
          {saveLoading === true ? (
            <div className={style.lottieWrap}>
              <Lottie
                style={{
                  width: "70px",
                  height: "70px",
                  color: "#fff",
                  padding: "0px",
                  margin: "0px",
                }}
                animationData={loader}
                loop={true}
              />
            </div>
          ) : (
            <button
              disabled={!isCompleted}
              onClick={completePinChange}
              className={style.save}
            >
              Proceed
            </button>
          )}
          <p>
            Didn't get the code?{" "}
            <span onClick={getOtp} className={style.resOtp}>
              Resend Otp
            </span>
          </p>
        </div>
      )}
    </div>
  );
}

export default ResetPin;