import React, { useState, useEffect } from "react";
import style from "./signup.module.css";
import { Icon } from "@iconify/react";
import { Link, useLocation } from "react-router-dom";
import { ArrowDown2, Eye, EyeSlash, TickSquare } from "iconsax-react";
import { useFormik } from "formik";
import { basicSchema } from "../../../schemas/Index";
import ImageComp from "./ImageComp";
import logo from "../../../assets/images/logo2.png";
import { useDispatch, useSelector } from "react-redux";
import { userSignUp } from "../../../store/auth/authActions";
import { clearSignupSuccess } from "../../../store/auth/authSlice";
import Lottie from "lottie-react";
import loader from "../../../assets/lottie/loader.json";
import { browserName } from "react-device-detect";



const countries = [
  { id: 1, title: "Nigeria", tag: "NGN" },
  { id: 2, title: "Ghana", tag: "GHS" },
  //   { id: 3, title: "United States" },
  //   { id: 4, title: "United Kingdom" },
  //   { id: 5, title: "South Africa" },
  //   { id: 6, title: "United Arab Emirates" },
  //   { id: 7, title: "Zambia" },
  //   { id: 8, title: "Netherlands" },
  //   { id: 9, title: "Turkey" },
  //   { id: 10, title: "Canada" },
  //   { id: 11, title: "Philippines" },
  //   { id: 12, title: "Tunisha" },
  //   { id: 13, title: "Congo" },
  //   { id: 14, title: "Australia" },
];

const Signup = () => {

  const [visible, setVisible] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [topFilled, setTopFilled] = useState(false);
  const [firstFilled, setFirstFilled] = useState(false);
  const [secondFilled, setSecondFilled] = useState(false);
  const [openCountry, setOpenCountry] = useState(false);
  const [loading, setLoading] = useState(false);

  const search = useLocation().search;
  const dispatch = useDispatch();
  const togglePasswordVisibility = () => {
    setVisible(!visible);
  };

  const isLoading = useSelector((state) => state.auth.loading);
  const signUpSuccess = useSelector((state) => state.auth.signupSuccess);

  // useEffect(() => {
  //     if (isLoading === true) {
  //         setSecondFilled(false)
  //     } else {
  //         setFirstFilled(true);
  //     }
  // }, [isLoading])

  useEffect(() => {
    if (isLoading === true) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isLoading]);

  const onSubmit = async (values, actions) => {
    let firstName = values.firstname;
    let lastName = values.lastname;
    let email = values.email;
    let password = values.password;
    let username = values.username;
    let phoneNumber = values.phoneNumber;
    let country = values.country === "Nigeria" ? "Nigeria" : "Ghana";
    let countryTag = values.country === "Nigeria" ? "NGN" : "GHS";
    let referral = values.referral;

    dispatch(
      userSignUp({
        firstName,
        lastName,
        email,
        password,
        username,
        phoneNumber,
        country,
        countryTag,
        referral,
      })
    );

    console.log(values);
    // alert("Successfully submitted");
  };


  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        firstname: "",
        lastname: "",
        username: "",
        email: "",
        phoneNumber: "",
        country: "",
        password: "",
        referral: new URLSearchParams(search).get("ref")
          ? new URLSearchParams(search).get("ref")
          : "",
      },
      validationSchema: basicSchema,
      onSubmit,
    });

    useEffect(() => {
      if (signUpSuccess === true) {
        setFirstFilled(false);
        setTopFilled(false);
        values.firstname = "";
        values.lastname = "";
        values.email = "";
        values.password = "";
        values.username = "";
        values.phoneNumber = "";
        values.country = "";
        values.referral = "";
        setIsChecked(false)
        setTimeout(() => {
          dispatch(clearSignupSuccess());
        }, 800);
      }
    }, [signUpSuccess, dispatch, values]);

  useEffect(() => {
    if (
      values.firstname !== "" &&
      values.lastname !== "" &&
      values.email !== "" &&
      values.phoneNumber !== "" &&
      !errors.firstname &&
      !errors.lastname &&
      !errors.email &&
      !errors.phoneNumber
    ) {
      setFirstFilled(true);
    } else {
      setFirstFilled(false);
    }

    if (
      values.country !== "" &&
      values.password !== "" &&
      values.username !== "" &&
      isChecked === true &&
      !errors.country &&
      !errors.username &&
      !errors.password
    ) {
      setSecondFilled(true);
    } else {
      setSecondFilled(false);
    }
  }, [values, isChecked, errors]);

  const toggleCountry = () => {
    setOpenCountry((prevCountry) => {
      if (prevCountry === true) {
        return false;
      } else if (prevCountry === false) {
        return true;
      }
    });
  };
  const handleCountry = (e) => {
    values.country = e.target.innerText.toString();
    setOpenCountry(false);
  };
  //   const handleSignup = (e) => {
  //     e.preventDefault();

  //     navigate("/email-verify");
  //   };

  // HANDLE COUNTRY CLOSE ON CLICK OUT
  useEffect(() => {
    let handler = (e) => {
      if (e.target.id !== "country") {
        setOpenCountry(false);
      } else if (e.target.id === "country") {
        toggleCountry();
      }
      if (e.target.id === "countryDrop") {
        setOpenCountry(true);
      }
      // console.log(currRef.current.name)
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  return (
    <div className={style.signup}>
      {/* <p style={{
              fontSize: 40,
          }} >{ count }</p> */}
      <ImageComp />

      <div className={style.right}>
        <Link to="/">
          <img className={style.mobileImg} src={logo} alt="" />
        </Link>
        <div className={style.formCont}>
          <div style={{ width: "100%" }}>
            <div className={style.logoImg}>
              <img src={logo} alt="" />
            </div>
            <div className={style.starter}>
              <h2>Let's get started!</h2>
              <p>Enter your details to create your Astro account</p>
            </div>
            <div className={style.form}>
              <div className={style.indicator}>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setTopFilled(false);
                  }}
                >
                  {" "}
                  {topFilled === true || firstFilled === true ? (
                    <Icon
                      className={style.tickCircleDone}
                      icon="mdi:tick-circle"
                    />
                  ) : (
                    <Icon
                      className={style.tickCircleDone}
                      icon="mdi:tick-circle"
                    />
                  )}{" "}
                </div>
                <hr
                  className={topFilled === true ? style.indiHrHi : style.indiHr}
                />
                <div
                // onClick={() => { setTopFilled(true) }}
                >
                  {" "}
                  {topFilled === true ? (
                    <Icon
                      className={
                        secondFilled === true
                          ? style.tickCircleDone
                          : style.tickCircleDone
                      }
                      icon="mdi:tick-circle"
                    />
                  ) : (
                    <Icon className={style.tickCircle} icon="mdi:tick-circle" />
                  )}{" "}
                </div>
              </div>

              <form action="">
                {topFilled === false ? (
                  <div className={style.first}>
                    <div className={style.labelPut}>
                      <label htmlFor="firstname">
                        First Name<span className={style.required}>*</span>{" "}
                      </label>
                      <div className={style.input}>
                        <input
                          value={values.firstname}
                          onChange={handleChange}
                          type="text"
                          id="firstname"
                          placeholder="Enter firstname"
                          onBlur={handleBlur}
                          className={
                            errors.firstname && touched.firstname
                              ? style.error
                              : style.notError
                          }
                        />
                      </div>
                      {errors.firstname && touched.firstname && (
                        <p className={style.pError}>{errors.firstname}</p>
                      )}
                    </div>

                    <div className={style.labelPut}>
                      <label htmlFor="lastname">
                        Last Name<span className={style.required}>*</span>{" "}
                      </label>
                      <div className={style.input}>
                        <input
                          value={values.lastname}
                          onChange={handleChange}
                          type="text"
                          id="lastname"
                          placeholder="Enter lastname"
                          onBlur={handleBlur}
                          className={
                            errors.lastname && touched.lastname
                              ? style.error
                              : style.notError
                          }
                        />
                      </div>
                      {errors.lastname && touched.lastname && (
                        <p className={style.pError}>{errors.lastname}</p>
                      )}
                    </div>
                    <div className={style.labelPut}>
                      <label htmlFor="email">
                        Email Address<span className={style.required}>*</span>{" "}
                      </label>
                      <div className={style.input}>
                        <input
                          value={values.email}
                          onChange={handleChange}
                          type="email"
                          id="email"
                          placeholder="Enter email address"
                          onBlur={handleBlur}
                          className={
                            errors.email && touched.email
                              ? style.error
                              : style.notError
                          }
                        />
                      </div>
                      {errors.email && touched.email && (
                        <p className={style.pError}>{errors.email}</p>
                      )}
                    </div>

                    <div className={style.labelPut}>
                      <label htmlFor="phone_no">
                        Phone Number<span className={style.required}>*</span>{" "}
                      </label>
                      <div className={style.input}>
                        <input
                          value={values.phoneNumber}
                          onChange={handleChange}
                          type="text"
                          id="phoneNumber"
                          placeholder="Enter phone number"
                          onBlur={handleBlur}
                          className={
                            errors.phoneNumber && touched.phoneNumber
                              ? style.error
                              : style.notError
                          }
                        />
                      </div>
                      {errors.phoneNumber && touched.phoneNumber && (
                        <p className={style.pError}>{errors.phoneNumber}</p>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className={style.second}>
                    <div className={style.labelPut}>
                      <label htmlFor="country">
                        {" "}
                        Country <span className={style.required}>*</span>
                      </label>
                      <div className={style.select}>
                        <div id="country"
                          name="country" className={style.selectInput}>
                            <input
                              style={{pointerEvents: "none"}}
                            type="text"
                            disabled={browserName === "Firefox" ? false : true}
                            value={values.country}
                            placeholder="Please select a country"
                          />
                          <ArrowDown2
                            style={{
                              transform:
                                openCountry === true
                                  ? "translateY( -50%) rotate(-540deg)"
                                  : "translateY( -50%) rotate(0deg)",
                              pointerEvents: "none",
                            }}
                            className={style.dropDown}
                            size="20"
                            color="#909090"
                            variant="Bold"
                          />
                        </div>
                        <div
                          style={{
                            height:
                              openCountry === true ? "fit-content" : "0px",
                            maxHeight: "120px",
                          }}
                          className={style.option}
                        >
                          {countries?.map((country) => (
                            <p
                              id="countryDrop"
                              key={country.id}
                              onClick={handleCountry}
                            >
                              {country.title}
                            </p>
                          ))}
                        </div>
                        {/* <input type="text" placeholder='Enter Last Name' /> */}
                      </div>
                    </div>
                    <div className={style.labelPut}>
                      <label htmlFor="username">
                        Username<span className={style.required}>*</span>
                      </label>
                      <div className={style.input}>
                        <input
                          value={values.username}
                          onChange={handleChange}
                          type="text"
                          id="username"
                          placeholder="Enter Username"
                          onBlur={handleBlur}
                          className={
                            errors.username && touched.username
                              ? style.error
                              : style.notError
                          }
                        />
                      </div>
                      {errors.username && touched.username && (
                        <p className={style.pError}>{errors.username}</p>
                      )}
                    </div>
                    <div className={style.labelPut}>
                      <label htmlFor="password">
                        Password<span className={style.required}>*</span>{" "}
                      </label>
                      <div className={style.input}>
                        <input
                          value={values.password}
                          onChange={handleChange}
                          type={visible ? "text" : "password"}
                          id="password"
                          placeholder="Enter password"
                          onBlur={handleBlur}
                          className={
                            errors.password && touched.password
                              ? style.error
                              : style.notError
                          }
                        />
                        {visible ? (
                          <EyeSlash
                            onClick={togglePasswordVisibility}
                            className={style.visSwitch}
                            color="#909090"
                            variant="Bulk"
                          />
                        ) : (
                          <Eye
                            onClick={togglePasswordVisibility}
                            className={style.visSwitch}
                            color="#909090"
                            variant="Bulk"
                          />
                        )}
                      </div>
                      {errors.password && touched.password && (
                        <p className={style.pError}>{errors.password}</p>
                      )}
                    </div>

                    <div className={style.labelPut}>
                      <label htmlFor="email">
                        Referral{" "}
                        <span className={style.optional}>(optional)</span>{" "}
                      </label>
                      <div className={style.input}>
                        <input
                          value={values.referral}
                          onChange={handleChange}
                          type="text"
                          id="referral"
                          placeholder="Enter referral"
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>

                    <div className={style.terms}>
                      {isChecked ? (
                        <TickSquare
                          onClick={() => {
                            setIsChecked(false);
                          }}
                          className={style.check}
                          size="16"
                          color="#40196D"
                          variant="Bold"
                        />
                      ) : (
                        <TickSquare
                          onClick={() => {
                            setIsChecked(true);
                          }}
                          className={style.check}
                          size="16"
                          color="#40196D"
                          variant="TwoTone"
                        />
                      )}
                      <p>
                        I agree to the <Link>Terms of Use</Link> and{" "}
                        <Link>Privacy Policy</Link>
                      </p>
                    </div>
                  </div>
                )}

                {topFilled === true ? (
                  <>
                    {loading === true ? (
                      <div className={style.lottieWrap}>
                        <Lottie
                          style={{
                            width: "70px",
                            height: "70px",
                            color: "#fff",
                            padding: "0px",
                            margin: "0px",
                          }}
                          animationData={loader}
                          loop={true}
                        />
                      </div>
                    ) : (
                      <button
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          handleSubmit();
                        }}
                        className={style.button}
                        disabled={!secondFilled}
                      >
                        Create Account
                      </button>
                    )}
                  </>
                ) : (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setTopFilled(true);
                    }}
                    className={style.button}
                    disabled={!firstFilled}
                  >
                    Continue
                  </button>
                )}
              </form>
            </div>
            <div className={style.alternates}>
              {/* BUTTONS TO USE FOR SOCIALMEDIA SIGNUP  */}

              {/* <div className={style.googleFace}>
                <p>Or sign up with: </p>
                <Icon className={style.logoCons} icon="logos:google-icon" />
                <Icon className={style.logoCons} icon="logos:facebook" />
              </div> */}
              <p className={style.register}>
                Already have an account ? <Link to="/signin">Sign In</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
