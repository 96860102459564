import React from 'react'
import style from './resetcomp.module.css'

const ResetCompleted = () => {
    return (
        <div className={style.arcee}>
            <div className={style.arceeCont} >
                <h2>Thank You </h2>
                <p>Please check your email for the link to reset your password, please try not to forget it again</p>
            </div>
        </div>
  )
}

export default ResetCompleted