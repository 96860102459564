import React, { useEffect, useState } from "react";
import styles from "./kyc.module.css";
import { ArrowDown2, Camera } from "iconsax-react";
import { browserName } from "react-device-detect";
import Lottie from "lottie-react";
import loader from "../../assets/lottie/loader.json";
import DojahComponent from "../DojahComponent";

const AddressVerification = () => {
  const [loading, setLoading] = useState(false);
  const [openState, setOpenState] = useState(false);
  const [openCity, setOpenCity] = useState(false);
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [houseNo, setHouseNo] = useState("");
  const [disabled, setDisabled] = useState(true);
  

  // TOGGLE THE STATE DROPDOWN
  const toggleState = () => {
    setOpenState((prev) => {
      if (prev === false) {
        return true;
      } else if (prev === true) {
        return false;
      }
    });
  };

  // TOGGLE THE CITY DROPDOWN
  const toggleCity = () => {
    setOpenCity((prev) => {
      if (prev === false) {
        return true;
      } else if (prev === true) {
        return false;
      }
    });
  };


  // HANDLE ID NUMBER CLOSE ON CLICK OUT
  useEffect(() => {
    let handler = (e) => {
      if (e.target.id !== "state") {
        setOpenState(false);
      } else if (e.target.id === "state") {
        toggleState();
      }
      if (e.target.id === "stateDrop") {
        setOpenState(true);
      }
      // console.log(currRef.current.name)
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  // HANDLE ID NUMBER CLOSE ON CLICK OUT
  useEffect(() => {
    let handler = (e) => {
      if (e.target.id !== "city") {
        setOpenCity(false);
      } else if (e.target.id === "city") {
        toggleCity();
      }
      if (e.target.id === "cityDrop") {
        setOpenCity(true);
      }
      // console.log(currRef.current.name)
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

    useEffect(() => {
        if (
            city !== "" &&
            state !== "" &&
            street !== "" && 
            houseNo !== ""
        ) {
            setDisabled(false)
        }else{setDisabled(true)}
    })
    
  return (
    <div className={styles.idVerification}>
      <h2 className={styles.idvTitle}>Upgrade Account</h2>
      <p className={styles.idvSubtitle}>
        Upgrade your Astro account with more <br /> personal information
      </p>

      

      <div className={styles.form}>

      </div>
    </div>
  );
};

export default AddressVerification;
