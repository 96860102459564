import React from 'react'
import { Link } from 'react-router-dom'
import style from './notFoundPage.module.css'

const NotFoundPage = () => {
  return (
      <div className={style.notFound} >
          <div className={style.nfMiddle} >
              <h2>ERROR</h2>
              <p>PAGE NOT FOUND</p>
              <Link to="/" >Back to Login?</Link>
          </div>
    </div>
  )
}

export default NotFoundPage