import { createSlice } from "@reduxjs/toolkit";
import {
  fetchCryptoTransactionsById,
  fetchOutwardCryptoTransactions,
  fetchOutwardCryptoTransactionsById,
  getDepositTransactions,
  getDepositTransactionsById,
  getGiftcardTransactions,
  getGiftcardTransactionsById,
  getRecentTransactions,
  getWithdrawalTransactions,
  getWithdrawalTransactionsById,
} from "./transActions";

 
const initialState = {
  loading: false,
  trLoading: false,
  gcLoading: false,
  transactions: [],
  withdrawals: [],
  giftCards: [],
  depositById: {},
  recentData: {},
  searchTransactions: [],
  searchWithdrawals: [],
  searchGiftCards: [],
  cryptoOutTrans: [],
  cryptoTransIDLoading: false,
  count: 0,
};
 
const transSlice = createSlice({
  name: "trans",
  initialState,
  reducers: {
    // searchTransactions: (state, { payload }) => {
    //   let result = state.transactions.filter((trans) => {
    //     if (trans.reference.includes(payload)) {
    //       return trans;
    //     }
    //   });
    //   state.filteredTransaction = result;
    //   console.log(state.filteredTransaction);
    // },
    clearDepositById: (state) => {
      state.depositById = {};
    },

    searchTransactions: (state, { payload }) => {
      let resp = state.searchTransactions.filter((val) => {
        return (
          val.reference.toLowerCase().includes(payload.toLowerCase()) ||
          val.amount.toLowerCase().includes(payload.toLowerCase())
        );
      });
      state.transactions = resp;
    },

    // searchWithdrawalTransactions: (state, { payload }) => {
    //   let resp = state.searchWithdrawals.filter((val) => {
    //     return (
    //       val.reference.toLowerCase().includes(payload.toLowerCase()) ||
    //       val.amount.toLowerCase().includes(payload.toLowerCase())
    //     );
    //   });
    //   state.withdrawals = resp;
    // },

    // searchGiftCardTransactions: (state, { payload }) => {
    //   let resp = state.searchGiftCards.filter((val) => {
    //     return (
    //       val.reference.toLowerCase().includes(payload.toLowerCase()) ||
    //       val.amount.toLowerCase().includes(payload.toLowerCase())
    //     );
    //   });
    //   state.giftCards = resp;
    // },
  },
  extraReducers: {
    // THESE ARE THE EXTRA REDUCERS FOR HANDLING THE DEPOSIT TRANSACTIONS

    [getDepositTransactions.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getDepositTransactions.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.transactions = payload.data.data.transactions;
      state.searchTransactions = payload.data.data.transactions;
      state.count = payload.data.data.meta.count;
    },
    [getDepositTransactions.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    // THESE ARE THE EXTRA REDUCERS FOR HANDLING THE DEPOSIT TRANSACTIONS

    [getDepositTransactionsById.pending]: (state, { payload }) => {
      state.trLoading = true;
    },
    [getDepositTransactionsById.fulfilled]: (state, { payload }) => {
      state.trLoading = false;
      state.depositById = payload.data.data;
    },
    [getDepositTransactionsById.rejected]: (state, { payload }) => {
      state.trLoading = false;
    },

    // THESE ARE THE EXTRA REDUCERS FOR HANDLING THE WITHDRAWAL TRANSACTIONS

    [getWithdrawalTransactions.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getWithdrawalTransactions.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.withdrawals = payload.data.data.transactions;
      state.searchWithdrawals = payload.data.data.transactions;
    },
    [getWithdrawalTransactions.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    // THESE ARE THE EXTRA REDUCERS FOR HANDLING THE DEPOSIT TRANSACTIONS

    [getWithdrawalTransactionsById.pending]: (state, { payload }) => {
      state.trLoading = true;
    },
    [getWithdrawalTransactionsById.fulfilled]: (state, { payload }) => {
      state.trLoading = false;
      state.depositById = payload.data.data.transaction;
    },
    [getDepositTransactionsById.rejected]: (state, { payload }) => {
      state.trLoading = false;
    },
    // THESE ARE THE EXTRA REDUCERS FOR HANDLING THE GIFTCARD TRANSACTIONS

    [getGiftcardTransactions.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getGiftcardTransactions.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.giftCards = payload.data.data.transactions;
      state.searchGiftCards = payload.data.data.transactions;
    },
    [getGiftcardTransactions.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    // THESE ARE THE EXTRA REDUCERS FOR HANDLING THE GIFTCARD TRANSACTIONS BY ID

    [getGiftcardTransactionsById.pending]: (state, { payload }) => {
      state.trLoading = true;
    },
    [getGiftcardTransactionsById.fulfilled]: (state, { payload }) => {
      state.trLoading = false;
      state.depositById = payload.data.data.transaction;
    },
    [getGiftcardTransactionsById.rejected]: (state, { payload }) => {
      state.trLoading = false;
    },

    // THESE ARE THE EXTRA REDUCERS FOR HANDLING THE RECENT TRANSACTIONS

    [getRecentTransactions.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getRecentTransactions.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.recentData = payload.data.data;
    },
    [getRecentTransactions.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    // ========================== EXTRA RERDUCER HANDLING FETCH CRYPTO TRANSACTIONS BY ID
    [fetchCryptoTransactionsById.pending]: (state, { payload }) => {
      state.trLoading = true;
    },
    [fetchCryptoTransactionsById.fulfilled]: (state, { payload }) => {
      state.trLoading = false;
      state.depositById = payload.data.data.transaction;
    },
    [fetchCryptoTransactionsById.rejected]: (state, { payload }) => {
      state.trLoading = false;
    },
    
    
    // ========================== EXTRA RERDUCER HANDLING FETCH CRYPTO OUTWARD TRANSACTIONS
    [fetchOutwardCryptoTransactions.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [fetchOutwardCryptoTransactions.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.cryptoOutTrans = payload.data.data.transactions;
    },
    [fetchOutwardCryptoTransactions.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    
    
    // ========================== EXTRA RERDUCER HANDLING FETCH CRYPTO OUTWARD TRANSACTIONS BY ID
    [fetchOutwardCryptoTransactionsById.pending]: (state, { payload }) => {
      state.trLoading = true;
    },
    [fetchOutwardCryptoTransactionsById.fulfilled]: (state, { payload }) => {
      state.trLoading = false;
      state.depositById = payload.data.data.transaction;
    },
    [fetchOutwardCryptoTransactionsById.rejected]: (state, { payload }) => {
      state.trLoading = false;
    },
  },
});

export default transSlice.reducer;
export const {
  searchTransactions,
  clearDepositById,
} = transSlice.actions;
