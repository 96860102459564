import * as yup from 'yup'

const passwordRules = /^(?=.*\d)(?=.*[a-z]).{5,}$/

// (?=.*[A-Z])

export const basicSchema = yup.object().shape({
    firstname: yup.string().required('Required'),
    lastname: yup.string().required("Required"),
    username: yup.string().required("Required"),
    country: yup.string().required("Required"),
    email: yup.string().email("Please enter a valid email").required("Required"), 
    phoneNumber: yup
        .number()
        .min(11)
        // .matches()
        .positive()
        .integer()
        .required("Required"),
    password: yup
        .string()
        .min(8)
        .matches(passwordRules, { message: "Passwords must contain atleast one lowercase letter and one number" })
        .required("Required"),
    referral: yup
        .string()
    // confirmPassword: yup
    //     .string()
    //     .oneOf([yup.ref('password'), null], "Passwords must match")
    //     .required("Required")
})

export const loginSchema = yup.object().shape({
    email: yup.string().email("Please enter a valid email").required("Required"), 
    password: yup
        .string()
        .min(8)
        // .matches(passwordRules, { message: "Please create a stronger password" })
        .required("Required"),
    
})

export const resetSchema = yup.object().shape({
    password: yup
        .string()
        .min(8)
        .matches(passwordRules, { message: "Please create a stronger password" })
        .required("Required"),
    
    confirmPassword: yup
        .string()
        .min(8)
        .matches(passwordRules, { message: "Please create a stronger password" })
        .required("Required"),
    
})

export const walletSchema = yup.object().shape({
    email: yup.string().email("Please enter a valid email").required("Required"), 
    password: yup
        .string()
        .min(5)
        .matches(passwordRules, { message: "Please create a stronger password" })
        .required("Required"),
})

// export const forgotEmailSchema = yup.object().shape({
//     email: yup.string().email("Please enter a valid email").required("Required"),
    
// })