import { Icon } from "@iconify/react";
import React from "react";
import { Children } from "react";
import style from "./modal.module.css";

export const ModalChild = ({ children, modalOpen, setModalOpen, canClose }) => {
  return (
    <div
      className={modalOpen === false ? style.modal : style.modalFade}
      // style={{
      //   // display: modalOpen === true ? "flex" : "none"
      // }}
    >
      <div className={style.popUpModalChild}>
        {canClose === true ? (
          <div className={style.closeModalConWrap}>
            <Icon
              className={style.closeModalCon}
              onClick={() => {
                setModalOpen(false);
              }}
              icon="ri:close-circle-fill"
            />
          </div>
        ) : (
          <></>
        )}
        {children}
      </div>
    </div>
  );
};


const Modal = ({ children, modalOpen, setModalOpen, canClose }) => {
  return (
    <div
      className={modalOpen === false ? style.modal : style.modalFade}
      // style={{
      //   // display: modalOpen === true ? "flex" : "none"
      // }}
    >
      <div className={style.popUp}>
        {canClose === true ? (
          <div className={style.closeModalConWrap}>
            <Icon
              className={style.closeModalCon}
              onClick={() => {
                setModalOpen(false);
              }}
              icon="ri:close-circle-fill"
            />
          </div>
        ) : (
          <></>
        )}
        {children}
      </div>
    </div>
  );
};

export default Modal;
