import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../config";
import cogoToast from "cogo-toast";
import { getUserById } from "../auth/authActions";



// ============================================================ GET CRYPTO COINS
export const getCryptoCoins = createAsyncThunk(
  "getCryptoCoins",
  async (args, { rejectWithValue, dispatch, getState }) => {
    const id = getState().auth.user.user_id;
    try {
      const res = await axios.get(
        `${baseUrl}/coin/wallet/${id}/all`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": getState().auth.token.toString(),
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
      return res.data;
    } catch (error) {
      if (error.response.status === 401) {
        // dispatch({ type: "auth/Logout" });
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);


//  ===================================================== CREATE CRYPTO WALLET
export const createCryptoWallet = createAsyncThunk(
  "createCryptoWallet",
  async (
    { coinTypeId,coinNetworkId },
    { rejectWithValue, dispatch, getState }
  ) => {
    const id = getState().auth.user.user_id;
    try {
      const res = await axios.post(
        `${baseUrl}/coin/wallet/${id}`,
        {
          coinTypeId: coinTypeId,
          coinNetworkId: coinNetworkId
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": getState().auth.token.toString(),
          },
        }
      );
      if (res.status === 201) {
        cogoToast.success(
          "Your wallet address has been generated successfully",
          { position: "top-right" }
        );
        return res;
      }
    } catch (error) {
      if (error.response.status === 400 || error.response.status === 410) {
        cogoToast.warn("Oops! looks like this coin already has a wallet address generated", {
          position: "top-right",
        });
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      }
      return rejectWithValue(error.response);
    }
  }
);

// ================================================================= GET ALL BANK ACCOUNTS

export const getBankAccounts = createAsyncThunk(
  "getBankAccounts",
  async (args, { rejectWithValue, dispatch, getState }) => {
    const id = getState().auth.user.user_id;
    // const currency = getState().auth.currency;
    try {
      const res = await axios.get(
        `${baseUrl}/user-account/${id}/bank-accounts`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": getState().auth.token.toString(),
          },
        }
      );
      if (res.status === 200) {
        console.log(res);
        return res.data;
      }
      return res.data;
    } catch (error) {
      if (error.response.status === 401) {
        // dispatch({ type: "auth/Logout" });
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

// ================================================================= GET ALL NIGERIAN BANKS

export const getBanks = createAsyncThunk(
  "getBanks",
  async ({ title }, { rejectWithValue, dispatch, getState }) => {
    // const currency = getState().auth.currency;
    try {
      const res = await axios.get(`${baseUrl}/misc/get-banks/${title}`, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": getState().auth.token.toString(),
        },
      });
      if (res.status === 200 || res.status === 201) {
        console.log(res);
        return res.data;
      }
    } catch (error) {
      console.log(error.code);
      if (error.code === "ERR_NETWORK") {
        cogoToast.error("Please check your connection");
      }
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

// ================================================================= GET ACCOUNT NAME

export const getAccountName = createAsyncThunk(
  "getAccountName",
  async (
    { bankCode, accountNumber },
    { rejectWithValue, dispatch, getState }
  ) => {
    // const currency = getState().auth.currency;
    try {
      const res = await axios.post(
        `${baseUrl}/misc/verify-bank-details`,
        {
          bankCode: bankCode,
          accountNumber: accountNumber,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": getState().auth.token.toString(),
          },
        }
      );
      if (res.status === 200) {
        console.log(res);
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 401) {
        // dispatch({ type: "auth/Logout" });
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

// ================================================================= ADD BANK ACCOUNT

export const addBankAccount = createAsyncThunk(
  "addBankAccount",
  async (
    { bankCode, accountNumber, accountName, bankName, accountType },
    { rejectWithValue, dispatch, getState }
  ) => {
    const id = getState().auth.user.user_id;
    try {
      const res = await axios.post(
        `${baseUrl}/user-account/${id}/bank-accounts`,
        {
          accountNumber: accountNumber,
          accountName: accountName,
          bankCode: bankCode,
          bankName: bankName,
          accountType: accountType,
          isMobileMoney: false,
          bankBranchCode: "",
          bankBranchName: "",
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": getState().auth.token.toString(),
          },
        }
      );
      console.log(res);
      dispatch(getBankAccounts());
      cogoToast.success(
        "Congratulations! Your bank information has been added successfully",
        { position: "top-right" }
      );
      return res;
    } catch (error) {
      console.log(error.response.status);
      if (error.response.status === 409) {
        cogoToast.warn("Oops! this account already exists", {
          position: "top-right",
        });
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      }
      return rejectWithValue(error.response);
    }
  }
);

// ================================================================= DELETE BANK ACCOUNT

export const delBankAccount = createAsyncThunk(
  "delBankAccount",
  async ({ bid }, { rejectWithValue, dispatch, getState }) => {
    const id = getState().auth.user.user_id;
    console.log(bid, id);
    // const currency = getState().auth.currency;
    try {
      const res = await axios.delete(
        `${baseUrl}/user-account/${id}/bank-accounts/${bid}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": getState().auth.token.toString(),
          },
        }
      );
      if (res.status === 200) {
        console.log(res);
        dispatch(getBankAccounts());
        return res.data;
      }
      return res.data;
    } catch (error) {
      if (error.response.status === 401) {
        // dispatch({ type: "auth/Logout" });
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

// ================================================================= SET PIN

export const setPin = createAsyncThunk(
  "setPin",
  async ({ pinNo }, { rejectWithValue, dispatch, getState }) => {
    const id = getState().auth.user.user_id;
    // const pinNum = parseInt(pinNo)
    console.log(parseInt(pinNo));
    // const currency = getState().auth.currency;
    try {
      const res = await axios.put(
        `${baseUrl}/user-account/${id}/transaction-pin`,
        {
          pin: pinNo,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": getState().auth.token.toString(),
          },
        }
      );
      if (res.status === 200 || res.status === 201) {
        cogoToast.success("Your Pin was set Successfully", {
          position: "top-right",
        });
        dispatch(getUserById())
        console.log(res);
        return res;
      }
    } catch (error) {
      if (error.response.status === 400) {
        cogoToast.error("Invalid pin, please make sure you fill all fields", {
          position: "top-right",
        });
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        console.log(error.response.status);
        return rejectWithValue(error.response);
      }
    }
  }
);

// ================================================================= CHANGE PIN

export const changePin = createAsyncThunk(
  "changePin",
  async ({ oldPin, newPin }, { rejectWithValue, dispatch, getState }) => {
    const id = getState().auth.user.user_id;
    try {
      const res = await axios.put(
        `${baseUrl}/user-account/${id}/change-transaction-pin`,
        {
          currentPin: oldPin,
          newPin: newPin,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": getState().auth.token.toString(),
          },
        }
      );
      //   if (res.status === 200) {
      //       return res.data;
      //     }
      console.log(res);
      cogoToast.success("Your pin has been changed successfully", {
        position: "top-right",
      });
      return res.data;
    } catch (error) {
      if (error.response.status === 400) {
        cogoToast.error("Your pin was not set, please retry", {
          position: "top-right",
        });
        return rejectWithValue(error.response);
      } else if (error.response.status === 403) {
        cogoToast.error("Your transaction pin is incorrect", {
          position: "top-right",
        });
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        console.log(error.response.status);
        return rejectWithValue(error.response);
      }
    }
  }
);

// ================================================================= RESET PIN

export const resetPin = createAsyncThunk(
  "resetPin",
  async (args, { rejectWithValue, dispatch, getState }) => {
    const id = getState().auth.user.user_id;
    const header = getState().auth.token.toString();
    console.log(header);
    try {
      const res = await axios.post(
        `${baseUrl}/user-account/${id}/reset-pin`,
        {
          "x-auth-token": header,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": header,
          },
        }
      );
      //   if (res.status === 200) {
      //       return res.data;
      //     }
      console.log(res);
      cogoToast.success("OTP SENT! Please check your email for the otp code", {
        position: "top-right",
      });

      return res.data;
    } catch (error) {
      if (error.response.status === 400) {
        cogoToast.error("Your pin was not set, please retry", {
          position: "top-right",
        });
        return rejectWithValue(error.response);
      } else if (error.response.status === 403) {
        cogoToast.error("Unable to get OTP", { position: "top-right" });
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        console.log(error.response.status);
        return rejectWithValue(error.response);
      }
    }
  }
);

// ================================================================= COMPLETE RESET PIN

export const completeResetPin = createAsyncThunk(
  "completeResetPin",
  async ({ code, newPin }, { rejectWithValue, dispatch, getState }) => {
    const id = getState().auth.user.user_id;
    const header = getState().auth.token.toString();
    const reference = getState().settings.pinRef;
    console.log(code, newPin, getState().settings.pinRef);
    try {
      const res = await axios.put(
        `${baseUrl}/user-account/${id}/complete-pin-reset`,
        {
          resetCode: code,
          reference: reference,
          newPin: newPin,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": header,
          },
        }
      );
      //   if (res.status === 200) {
      //       return res.data;
      //     }
      console.log(res);
      cogoToast.success("Your pin has been reset successfully", {
        position: "top-right",
      });
      return res.data;
    } catch (error) {
      if (error.response.status === 400) {
        cogoToast.error("Your pin was not set, please retry", {
          position: "top-right",
        });
        return rejectWithValue(error.response);
      } else if (error.response.status === 403) {
        cogoToast.error("Unable to get OTP", { position: "top-right" });
        return rejectWithValue(error.response);
      } else if (error.response.status === 406) {
        cogoToast.error("The OTP you entered is invalid", {
          position: "top-right",
        });
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        console.log(error.response.status);
        return rejectWithValue(error.response);
      }
    }
  }
);

// ================================================================= PROCEED TO TRADE

export const proceedToTrade = createAsyncThunk(
  "proceedToTrade",
  async (
    { amount, gcId, ceId, walletId, value },
    { rejectWithValue, dispatch, getState }
  ) => {
    const id = getState().auth.user.user_id;
    const header = getState().auth.token.toString();
    const cards = getState().settings.cardImages;
    const resultCards = cards.map((item) => item.publicUrl);
    try {
      console.log(resultCards);
      const res = await axios.post(
        `${baseUrl}/cards/sell/transactions/${id}`,
        {
          amount: amount,
          giftCardId: gcId,
          payoutAmount: value,
          walletType: "FIAT",
          cardEntryId: ceId,
          walletId: walletId,
          imageURLs: resultCards,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": header,
          },
        }
      );
      console.log(res);
      return res.data;
    } catch (error) {
      if (error.response.status === 400) {
        cogoToast.error("Trade was not completed successful, please try again");
        return rejectWithValue(error.response);
      } else if (error.response.status === 403) {
        cogoToast.error("Your transaction pin is incorrect");
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        console.log(error.response.status);
        return rejectWithValue(error.response);
      }
    }
  }
);

// ================================================================= GET ALL CRYPTO ACCOUNTS

export const getCryptoAccounts = createAsyncThunk(
  "getCryptoAccounts",
  async (args, { rejectWithValue, dispatch, getState }) => {
    const id = getState().auth.user.user_id;
    // const currency = getState().auth.currency;
    try {
      const res = await axios.get(`${baseUrl}/crypto-account/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": getState().auth.token.toString(),
        },
      });
      if (res.status === 200) {
        console.log(res);
        return res.data;
      }
      return res.data;
    } catch (error) {
      if (error.response.status === 401) {
        // dispatch({ type: "auth/Logout" });
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

// ================================================================= ADD CRYPTO ACCOUNT

export const addCryptoAccount = createAsyncThunk(
  "addCryptoAccount",
  async (
    { walletAddress, coinType, network, coinName },
    { rejectWithValue, dispatch, getState }
  ) => {
    const id = getState().auth.user.user_id;
    try {
      const res = await axios.post(
        `${baseUrl}/crypto-account/${id}`,
        {
          walletAddress: walletAddress,
          coinType: coinType,
          network: network,
          coinName: coinName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": getState().auth.token.toString(),
          },
        }
      );
      console.log(res);
      dispatch(getCryptoAccounts());
      cogoToast.success("YAY! Your crypto has been added successfully", {
        position: "top-right",
      });
      return res;
    } catch (error) {
      console.log(error.response.status);
      if (error.response.status === 409) {
        cogoToast.warn("Oops! this account already exists", {
          position: "top-right",
        });
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      }
      return rejectWithValue(error.response);
    }
  }
);

// ================================================================= DELETE CRYPTO ACCOUNT

export const delCryptoAccount = createAsyncThunk(
  "delCryptoAccount",
  async ({ bid }, { rejectWithValue, dispatch, getState }) => {
    const id = getState().auth.user.user_id;
    console.log(bid, id);
    // const currency = getState().auth.currency;
    try {
      const res = await axios.delete(`${baseUrl}/crypto-account/${id}/${bid}`, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": getState().auth.token.toString(),
        },
      });
      if (res.status === 200) {
        console.log(res);
        dispatch(getCryptoAccounts());
        return res.data;
      }
      return res.data;
    } catch (error) {
      if (error.response.status === 401) {
        // dispatch({ type: "auth/Logout" });
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);


// delete user profile account
export const deleteProfileAccount = createAsyncThunk(
  "deleteProfileAccount",
  async (args, { rejectWithValue, dispatch, getState }) => {
    const id = getState().auth.user.user_id;
    try {
      const res = await axios.delete(`${baseUrl}/user-account/${id}/delete`, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": getState().auth.token.toString(),
        },
      });
      if (res.status === 200) {
        cogoToast.success("User account deleted successfully!");
        return res.data;
      }
      return res.data;
    } catch (error) {
      if (error.response.status === 401) {
        // dispatch({ type: "auth/Logout" });
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);
