import React, { useEffect, useState } from 'react'
import styles from './kyc.module.css'
import { ArrowRight2 } from 'iconsax-react';
import Modal from '../modal/Modal';
import { levels } from './data';
import BVN from './BVN';
import IdVerification from './IdVerification';
import AddressVerification from './AddressVerification';
import { useDispatch, useSelector } from 'react-redux';
import Lottie from "lottie-react";
import loader from "../../assets/lottie/loader.json";
import { getAllSettings, getKycStatusById } from '../../store/KYC/kycAction';
import { resetHasVerifiedBvn, resetHasVerifiedId } from '../../store/KYC/kycSlice';
import { useNavigate } from 'react-router-dom';


const LevelCard = ({ item, userLevel, myLevel, setUserLevel, setLevelModalOpen ,  setOpenModal2, setOpenModal3, setOpenModal4 }) => {


    
    return (
      <div
        style={{
          pointerEvents: item.level < myLevel.kyc_setting_id ? "none" : "all",
        }}
        className={
          myLevel.kyc_setting_id == item.level ? styles.card : styles.cardOff
        }
      >
        <div className={styles.levelBadge}>
          <h4
            style={{
              color:
                myLevel.kyc_setting_id == item.level ? "#FFE9A9" : "#1a1a1a",
            }}
          >
            Level {item.level}
          </h4>
          {myLevel.kyc_setting_id == item.level && <p>Current </p>}
        </div>
        <div className={styles.limitAmt}>
          <p
            style={{
              color:
                myLevel.kyc_setting_id == item.level ? "#5a2d8c" : "#adadad",
            }}
          >
            Daily withdrawal limit
          </p>
          <h4
            style={{
              color: myLevel.kyc_setting_id == item.level ? "#fff" : "#40196d",
            }}
          >
            {new Intl.NumberFormat("NG", {
              style: "currency",
              currency: "USD",
            }).format(item.daily_withdrawer_limit)}{" "}

          </h4>
        </div>
      </div>
    );
}

const KYC = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

    const [levelModalOpen, setLevelModalOpen] = useState(false);
    const [openModal2, setOpenModal2] = useState(false);
    const [openModal3, setOpenModal3] = useState(false);
    const [openModal4, setOpenModal4] = useState(false);

    const [settingsLoading, setSettingsLoading] = useState(false);
    const [kycLoading, setKycLoading] = useState(false);
    const [addressLoader, setAddressLoader] = useState(false);
    const [userLevel, setUserLevel] = useState(1);

    // ========================================================= REDUX STATES
    const myLevel = useSelector((state) => state.userKyc.kycStats.userkyc)
    const isKycLoading = useSelector((state) => state.userKyc.kycStatLoading)
    const verifiedBvn = useSelector((state) => state.userKyc.hasVerifiedBvn);
    const verifiedId = useSelector((state) => state.userKyc.hasVerifiedId);
    const settings = useSelector((state) => state.userKyc.settings);
    const isSettingsLoading = useSelector((state) => state.userKyc.settingsLoading);
    const isAddressLoading = useSelector((state) => state.userKyc.addressLoading);
    
    
    
    
    // ========================================================= REDUX USE EFFECTS
    useEffect(() => { 
        if (isAddressLoading === true) {
            setAddressLoader(true)
        } else {
          setAddressLoader(false)
        }
    }, [isAddressLoading])

  useEffect(() => { 
        if (isKycLoading === true) {
            setKycLoading(true)
        } else {
            setKycLoading(false)
        }
    }, [isKycLoading])

    useEffect(() => { 
        if (isSettingsLoading === true) {
            setSettingsLoading(true)
        } else {
            setSettingsLoading(false)
        }
    }, [isSettingsLoading])

    useEffect(() => { 
        if (verifiedBvn === true) {
            setOpenModal2(false)
            setTimeout(() => {
              dispatch(resetHasVerifiedBvn())
             }, 5000)
      } 
    }, [verifiedBvn])

    useEffect(() => { 
        if (verifiedId === true) {
          setOpenModal3(false);
           setTimeout(() => {
             dispatch(resetHasVerifiedId());
           }, 5000);
        } 
    }, [verifiedId])
    
    useEffect(() => {
      dispatch(getKycStatusById());
      dispatch(getAllSettings());
    }, []);

  return (
    <div className={styles.kycWrap}>
      {/* Verifying address modal */}
      <Modal modalOpen={addressLoader}>
        <div style={{
          padding:"2rem 0"
        }} >
          <div className={styles.lottieWrap}>
            <Lottie
              style={{
                width: "70px",
                height: "70px",
                color: "#fff",
                padding: "0px",
                margin: "0px",
              }}
              animationData={loader}
              loop={true}
            />
          </div>
          <p style={{marginTop: 16, marginBottom: 0 }} >Verifying Address</p>
        </div>
      </Modal>
      {/* BVN MODAL */} 
      <Modal canClose modalOpen={openModal2} setModalOpen={setOpenModal2}>
        <BVN setOpenModal2={setOpenModal2} />
      </Modal>

      {/* SELFIE / ID MODAL */}
      <Modal canClose modalOpen={openModal3} setModalOpen={setOpenModal3}>
        <IdVerification setOpenModal3={setOpenModal3} />
      </Modal>

      {/* ADDRESS VERIFICATION MODAL */}
      <Modal canClose modalOpen={openModal4} setModalOpen={setOpenModal4}>
        <AddressVerification setOpenModal4={setOpenModal4} />
      </Modal>

      {/* THE MODAL THAT SHOWS LEVELS */}
      <Modal
        canClose
        modalOpen={levelModalOpen}
        setModalOpen={setLevelModalOpen}
      >
        <h3 className={styles.modalTitle}>KYC Levels</h3>
        <div className={styles.cardWrap}>
          {settingsLoading === true ? (
            <div className={styles.lottieWrap}>
              <Lottie
                style={{
                  width: "70px",
                  height: "70px",
                  color: "#fff",
                  padding: "0px",
                  margin: "0px",
                }}
                animationData={loader}
                loop={true}
              />
            </div>
          ) : settings && settings.kycsettings ? (
            settings.kycsettings.map((item, idx) => (
              <LevelCard
                key={idx}
                myLevel={myLevel ? myLevel : ""}
                setUserLevel={setUserLevel}
                item={item}
                setLevelModalOpen={setLevelModalOpen}
                setOpenModal2={setOpenModal2}
                setOpenModal3={setOpenModal3}
                setOpenModal4={setOpenModal4}
              />
            ))
          ) : (
            <p>Sorry, there seems to be an issue, please reload</p>
          )}
        </div>
        {myLevel && myLevel.kyc_setting_id < 4 ? (
          <button
            onClick={() => {
              if (myLevel.kyc_setting_id === 1) {
                setLevelModalOpen(false);
                setOpenModal2(true);
              }
              if (myLevel.kyc_setting_id === 2) {
                setLevelModalOpen(false);
                setOpenModal3(true);
              }
              if (myLevel.kyc_setting_id === 3) {
                // setLevelModalOpen(false);
                setOpenModal4(true);
                navigate("/verify-address");
              }
            }}
            style={{ marginTop: 30 }}
            className={styles.proceed}
          >
            Upgrade Now
          </button>
        ) : null}
      </Modal>
      <h3 className={styles.title}>KYC Level</h3>
      <div className={styles.lvlArrow}>
        {kycLoading === true ? (
          <div className={styles.lottieWrap}>
            <Lottie
              style={{
                width: "70px",
                height: "70px",
                color: "#fff",
                padding: "0px",
                margin: "0px",
              }}
              animationData={loader}
              loop={true}
            />
          </div>
        ) : myLevel ? (
          <p className={styles.levelPop}> Level {myLevel.kyc_setting_id} </p>
        ) : (
          <p>error please reload</p>
        )}
        <ArrowRight2
          className={styles.icon}
          onClick={() => setLevelModalOpen(true)}
          size="26"
        />
      </div>
    </div>
  );
}

export default KYC;