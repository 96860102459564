import { ArrowDown2, Trash } from "iconsax-react";
import React, { useRef, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import style from "./details.module.css";
import amazon from "../../assets/images/cards/amazon.svg";
import amexgold from "../../assets/images/cards/amexgold.svg";
import apple from "../../assets/images/cards/apple.svg";
import ebay from "../../assets/images/cards/ebay.svg";
import google from "../../assets/images/cards/google.svg";
import itunes from "../../assets/images/cards/itunes.svg";
import nike from "../../assets/images/cards/nike.svg";
import nordstorm from "../../assets/images/cards/nordstorm.svg";
import razer from "../../assets/images/cards/razer.svg";
import sephora from "../../assets/images/cards/sephora.svg";
import steam from "../../assets/images/cards/steam.svg";
import target from "../../assets/images/cards/target.svg";
import vanilla from "../../assets/images/cards/vanilla.svg";
import visa from "../../assets/images/cards/visa.svg";
import walmart from "../../assets/images/cards/walmart.svg";
import { Icon } from "@iconify/react";
import Prompt from "../prompt/Prompt";
import { useDispatch, useSelector } from "react-redux";
import { proceedToTrade, uploadImage } from "../../store/settings/settingsActions";
import { useEffect } from "react";
import cogoToast from "cogo-toast";
import IfEmpty from "../ifEmpty/IfEmpty";
import { baseUrl } from "../../store/config";
import { deleteCard, uploadCard, clearCardImages, setTradeStatus } from "../../store/settings/settingsSlice";
import ProgressBar from "@ramonak/react-progress-bar";
import Lottie from "lottie-react";
import loader from "../../assets/lottie/loader.json";
import Modal from "../modal/Modal";
import ReactTextFormat from "react-text-format";
import { browserName } from "react-device-detect";

const acctTypes = [
  { id: 1, title: "Savings" },
  { id: 2, title: "Current" },
  { id: 3, title: "Domiciliary" },
  { id: 4, title: "Salary" },
  { id: 5, title: "Fixed deposit" },
  { id: 6, title: "Diaspora" },
];

const cards = [
  { id: 1, title: "Amazon", image: amazon },
  { id: 2, title: "Amex Gold", image: amexgold },
  { id: 3, title: "Ebay", image: ebay },
  { id: 4, title: "Google Play", image: google },
  { id: 5, title: "iTunez", image: itunes },
  { id: 6, title: "Nike", image: nike },
  { id: 7, title: "Nordstorm", image: nordstorm },
  { id: 8, title: "One Vanilla", image: vanilla },
  { id: 9, title: "Steam", image: steam },
  { id: 10, title: "Walmart", image: walmart },
  { id: 11, title: "Apple Store", image: apple },
  { id: 12, title: "Target", image: target },
  { id: 13, title: "Razer Gold", image: razer },
  { id: 14, title: "Visa", image: visa },
  { id: 15, title: "Sephora", image: sephora },
];

const TradeDetails = () => {
  const param = useParams().cardname;

  const [openPrompt, setOpenPrompt] = useState(false);

  const [wallet, setWallet] = useState("");
  const [weBuy, setWeBuy] = useState(0);
  const [cardRate, setCardRate] = useState(0);
  const [newRate, setNewRate] = useState(0);
  const [value, setValue] = useState(0);
  const [openWallet, setOpenWallet] = useState(false);
  const [amount, setAmount] = useState(0.0);
  const [payoutAmt, setPayoutAmt] = useState(0.0);
  const [cardCat, setCardCat] = useState("");
  const [progress, setProgress] = useState(0);
  const [progressLevel, setProgressLevel] = useState(0);
  const [openCardCat, setOpenCardCat] = useState(false);
  const [cardCatImg, setCardCatImg] = useState();
  const [imageLoading, setImageLoading] = useState(false);
  const [ready, setReady] = useState(false);
  const [openAfterProceed, setOpenAfterProceed] = useState(false);
  const [gcId, setGcId] = useState("");
  const [ceId, setCeId] = useState("");
  const [walletId, setWalletId] = useState("");
  const [walletCode, setWalletCode] = useState("");
  const [loading, setLoading] = useState("");
  const [tradeDone, setTradeDone] = useState(false);

  const [tac, setTac] = useState("");

  const fileInputRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const walletData = useSelector((state) => state.wallet.data);
  const cardCats = useSelector((state) => state.calc.cardDetails);
  const token = useSelector((state) => state.auth.token);
  const cards = useSelector((state) => state.settings.cardImages);
  const isLoading = useSelector((state) => state.settings.saveLoading);
  const isTrade = useSelector((state) => state.settings.isTradeSuccessful);

  const toggleWallet = () => {
    setOpenWallet((prevWallet) => { 
      if (prevWallet === false) {
        return true;
      } else if (prevWallet === true) {
        return false;
      }
    });
  };

  const getToken = () => {
    if (token !== "") {
      return token;
    } else {
      cogoToast.error("Could Not get Token");
    }
  };

  const changedHandler = () => {
    let files = fileInputRef.current.files[0];
    setImageLoading(true);
    uploadFile(files);
    console.log(files);
  };

  const uploadFile = (file) => {
    // - the base url plus the api
    const url = `${baseUrl}/misc/upload-file`;
    const xhr = new XMLHttpRequest();
    const fd = new FormData();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    // --(This is for the token passed on headers check yours)
    xhr.setRequestHeader("x-auth-token", `${getToken()}`);

    // Update progress (can be used to show progress indicator)
    xhr.upload.addEventListener("progress", (e) => {
      setProgressLevel(Math.round((e.loaded * 100.0) / e.total));
    });

    xhr.onreadystatechange = (e) => {
      // console.log(xhr)
      if (xhr.readyState === 4 && xhr.status === 201) {
        const response = JSON.parse(xhr.responseText);
        setImageLoading(false);
        cogoToast.success("Image uploaded successfully!", {
          position: "top-right",
        });
        // dispatch and add card images - i dipatched here to my redux store for the response gotten from the backend api
        console.log(response);
        dispatch(uploadCard(response));

        setProgressLevel(0);
      }
      //   cors errors
      if (xhr.readyState === 4 && xhr.status === 0) {
        console.log(xhr);
        setImageLoading(false);
        cogoToast.warn(
          "There might be an issue with this image, please optimize or try another image."
        );
        setProgressLevel(0);
      }
      if (xhr.status === 413 || xhr.status === 422) {
        cogoToast.warn(
          "There might be an issue with this image, please optimize or try another image."
        );
        setImageLoading(false);
        setProgressLevel(0);
      }
    };

    fd.append("tags", "browser_upload");
    fd.append("file", file);
    xhr.send(fd);
  };

  const handleWallet = (e) => {
    setOpenWallet(false);
    setWallet(e.target.textContent);
  };

  const toggleCardCat = () => {
    setOpenCardCat((prevCardCat) => {
      if (prevCardCat === false) {
        return true;
      } else if (prevCardCat === true) {
        return false;
      }
    });
  };

  const handleCardCat = (title) => {
    setOpenCardCat(false);
    setCardCat(title);
  };

  const cta1 = () => {
    setOpenPrompt(false);
  };

  const handleProceedToTrade = () => {
    dispatch(proceedToTrade({ amount, gcId, ceId, walletId, value }));
    setOpenAfterProceed(true);
  };

  useEffect(() => {
    dispatch(clearCardImages());
  }, []);

  useEffect(() => {
    let calcedRate = parseInt(weBuy) * cardRate;
    setNewRate(calcedRate);

    console.log(weBuy)
    console.log(cardRate)
    

    let calcedValue = parseFloat(newRate) * parseInt(amount);
    setValue(calcedValue.toFixed(2));
  }, [weBuy, cardRate, cardCat, wallet, setValue, amount, walletId]);

  useEffect(() => {
    if (wallet !== "" && cardCat !== "" && cards.length > 0 && amount > 0) {
      setReady(true);
    } else {
      setReady(false);
    }
  }, [wallet, cardCat, cards, amount]);

  useEffect(() => {
    setProgress(progressLevel);
  }, [wallet, cardCat, cards, amount, progressLevel]);

  // useEffect(() => {
  //   setAmount(0);
  // }, [wallet, walletCode, cardCat, cards]);

  useEffect(() => {
    if (isLoading === true) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    if (isTrade === true) {
      setTradeDone(true);
    } else {
      setTradeDone(false);
    }
  }, [isLoading, isTrade]);

  // HANDLE WALLET CLOSE ON CLICK OUT
  useEffect(() => {
    let handler = (e) => {
      if (e.target.id !== "wallet") {
        setOpenWallet(false);
      } else if (e.target.id === "wallet") {
        toggleWallet();
      }
      if (e.target.id === "walletDrop") {
        setOpenWallet(true);
      }
      // console.log(currRef.current.name)
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  // HANDLE CARD CAT CLOSE ON CLICK OUT
  useEffect(() => {
    let handler = (e) => {
      if (e.target.id !== "cardCat") {
        setOpenCardCat(false);
      } else if (e.target.id === "cardCat") {
        toggleCardCat();
      }
      if (e.target.id === "cardCatDrop") {
        setOpenCardCat(true);
      }
      // console.log(currRef.current.name)
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  return (
    <div className={style.tradeDetails}>
      <Modal modalOpen={openAfterProceed}>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {tradeDone === false && loading === true ? (
            ""
          ) : (
            <h2 style={{ margin: "0 0 12px 0" }}>Thank you</h2>
          )}
          <p style={{ margin: 0 }}>
            {tradeDone === false && loading === true
              ? ""
              : "Your card has been received, please be patient, You'll get a notification once trade is completed"}
          </p>
          {loading === true ? (
            <div className={style.lottieWrap}>
              <Lottie
                style={{
                  width: "140px",
                  height: "140px",
                  color: "#fff",
                }}
                animationData={loader}
                loop={true}
              />
            </div>
          ) : (
            <>
              {tradeDone === true && loading === false && (
                <button
                  className={style.save}
                  onClick={() => {
                    navigate("/dashboard");
                    dispatch(setTradeStatus());
                    setOpenAfterProceed(false);
                  }}
                >
                  Continue
                </button>
              )}
              {tradeDone === false && loading === false && (
                <button
                  className={style.save}
                  onClick={() => {
                    navigate("/trade");
                    setOpenAfterProceed(false);
                  }}
                >
                  Continue
                </button>
              )}
            </>
          )}
        </div>
      </Modal>
      <Prompt
        message="Your cable network recharge was successful"
        cta1={cta1}
        open={openPrompt}
        cta1Text="OK"
        cta2Text="CANCEL"
      />
      <div className={style.top}>
        <h5>Trade Details</h5>
      </div>
      <div className={style.wrapper}>
        <div className={style.left}>
          <div className={style.form}>
            <form action="">
              <div className={style.labelPut}>
                <label htmlFor="acct_type"> Select a wallet to credit</label>
                <div className={style.select}>
                  <div id="wallet"
                    name="acctType" className={style.selectInput}>
                    <input
                      
                      type="text"
                      disabled={browserName === "Firefox" ? false : true}
                      value={wallet}
                      placeholder="Please select a Wallet"
                      style={{
                        pointerEvents: "none"
                      }}
                    />
                    <ArrowDown2
                      style={{
                        transform:
                          openWallet === true
                            ? "translateY( -50%) rotate(-540deg)"
                            : "translateY( -50%) rotate(0deg)",
                        pointerEvents: "none",
                      }}
                      className={style.dropDown}
                      size="20"
                      color="#909090"
                      variant="Bold"
                    />
                  </div>
                  <div
                    style={{
                      height: openWallet === true ? "fit-content" : "0px",
                    }}
                    className={style.option}
                  >
                    {walletData.fiatWallets ? (
                      walletData.fiatWallets.map((item) => (
                        <p
                          id="walletDrop"
                          key={item.id}
                          onClick={(e) => {
                            handleWallet(e);
                            setWeBuy(item.Currency.we_buy);
                            setWalletCode(item.Currency.code);
                            setWalletId(item.id);
                          }}
                        >
                          {item.Currency.code} Wallet
                        </p>
                      ))
                    ) : (
                      <p>Sorry, no wallets here</p>
                    )}
                  </div>
                  {/* <input type="text" placeholder='Enter Last Name' /> */}
                </div>
              </div>
              <div className={style.labelPut}>
                <label htmlFor="acct_type"> Select Card Category</label>
                <div className={style.select}>
                  <div id="cardCat"
                    name="acctType" className={style.selectInput}>
                    <input
                      
                      type="text"
                      disabled={browserName === "Firefox" ? false : true}
                      value={cardCat}
                      placeholder="Please select a Category"
                      style={{
                        pointerEvents: "none"
                      }}
                    />
                    <ArrowDown2
                      style={{
                        transform:
                          openCardCat === true
                            ? "translateY( -50%) rotate(-540deg)"
                            : "translateY( -50%) rotate(0deg)",
                        pointerEvents: "none",
                      }}
                      className={style.dropDown}
                      size="20"
                      color="#909090"
                      variant="Bold"
                    />
                  </div>
                  <div
                    style={{
                      height: openCardCat === true ? "fit-content" : "0px",
                    }}
                    className={style.option}
                  >
                    {cardCats.length > 0 ? (
                      cardCats.map((item) => (
                        <div
                          
                          id="cardCatDrop"
                          key={item.id}
                          onClick={() => {
                            handleCardCat(item.description);
                            setCardRate(item.rate);
                            setTac(item.tac);
                            setGcId(item.GiftCard.id);
                            setCeId(item.id);
                          }}
                          className={style.cardItem}
                        >
                          <p style={{ pointerEvents: "none" }}>
                            {" "}
                            {item.description}{" "}
                          </p>
                        </div>
                      ))
                    ) : (
                      <IfEmpty />
                    )}
                  </div>
                  {/* <input type="text" placeholder='Enter Last Name' /> */}
                </div>
              </div>
              <div className={style.labelPut}>
                <label htmlFor="number"> Amount </label>
                <div className={style.input}>
                  <input
                    type="number"
                    name="acct_number"
                    placeholder="Enter Amount"
                    value={amount}
                    onChange={(e) => {
                      if (e.target.value <= 0) {
                        setAmount("");
                      } else {
                        setAmount(e.target.value);
                      }
                    }}
                  />
                </div>
              </div>

              <div className={style.labelPut}>
                <label htmlFor="number"> Gift Card Image </label>
                <div className={style.input}>
                  <div className={style.cardImg}>
                    <Icon icon="" />
                    <p>Click or Drag and Drop Image to Upload File</p>
                    <input
                      type="file"
                      className={style.uploadFile}
                      ref={fileInputRef}
                      accept="image/png, image/jpg, image/jpeg"
                      onChange={() => changedHandler()}
                    />
                  </div>
                </div>
              </div>
              <div className={style.cardMaps}>
                {progressLevel > 0 && imageLoading === true ? (
                  <div className={style.oneUpload}>
                    <div className={style.ouImg}>
                      <div className={style.lottieWrap}>
                        <Lottie
                          style={{
                            width: "70px",
                            height: "70px",
                            color: "#fff",
                          }}
                          animationData={loader}
                          loop={true}
                        />
                      </div>
                    </div>
                    <div className={style.ouProgress}>
                      <div className={style.ouTop}>
                        <p>Uploading...</p>
                        {progressLevel === 0 && (
                          <Trash size="20" className={style.triCon} />
                        )}
                      </div>
                      {progressLevel === 0 ? (
                        ""
                      ) : (
                        <div className={style.ouBottom}>
                          <ProgressBar
                            completed={parseInt(progress)}
                            bgColor="#401996D"
                            borderRadius="0"
                            maxCompleted={100}
                            isLabelVisible={false}
                            height={4}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {cards && cards.length > 0 ? (
                  cards.map((item, index) => (
                    <div key={index} className={style.oneUpload}>
                      <div className={style.ouImg}>
                        <img
                          src={item.publicUrl}
                          alt="astroafrica.site/cardimage.png"
                        />
                      </div>
                      <div className={style.ouProgress}>
                        <div className={style.ouTop}>
                          <p>{item.publicUrl.slice(77)}</p>
                          {progressLevel === 0 && (
                            <Trash
                              onClick={() => dispatch(deleteCard(index))}
                              size="20"
                              className={style.triCon}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p></p>
                )}
              </div>
              {/* <div className={style.labelPut}>
                <label htmlFor="number"> Comment </label>
                <div className={style.input}>
                  <textarea
                    className={style.cardImgTa}
                    name="comment"
                    id=""
                    cols="30"
                    rows="10"
                    placeholder="Add Comments"
                  ></textarea>
                </div>
              </div> */}
            </form>
          </div>
        </div>
        <div className={style.right}>
          <div className={style.rghTop}>
            <p>Info</p>
            <p>
              Rate{" "}
              <span>
                {walletCode ? walletCode : ""} {newRate > 0 ? newRate : "0.00"}{" "}
                / $
              </span>{" "}
            </p>
            <p>
              Value <span>{value && value}</span>{" "}
            </p>
          </div>
          <button
            onClick={handleProceedToTrade}
            disabled={!ready}
            className={style.save}
          >
            Proceed to Trade
          </button>
          <div className={style.terms}>
            <div className={style.termTop}>
              <p>Terms of Trade for {param}</p>
            </div>
            <div className={style.termBottom}>
              <p>
                {tac ? (
                  tac
                ) : (
                  <span>
                    Terms and conditions will show once a card is picked
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradeDetails;
