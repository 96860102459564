
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../config";
import cogoToast from "cogo-toast";




// ================================================================= FUND WALLET BALANCE

export const getFiatCurrencies = createAsyncThunk(
  "getFiatCurrencies",
  async (values, { rejectWithValue, dispatch, getState }) => {
    try {
      const res = await axios.get(`${baseUrl}/misc/fiat-currencies`, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": getState().auth.token.toString(),
        },
      });
      if (res.status === 200) {
        console.log(res);
        return res;
      }
      return res;
    } catch (error) {
      if (error.response.status === 401) {
        // dispatch({ type: "auth/Logout" });
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);



// ================================================================= FUND WALLET BALANCE

export const getAllCards = createAsyncThunk(
  "getAllCards",
  async (values, { rejectWithValue, dispatch, getState }) => {
    try {
      const res = await axios.get(`${baseUrl}/cards`, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": getState().auth.token.toString(),
        },
      });
      if (res.status === 200) {
        console.log(res);
        return res;
      }
      return res;
    } catch (error) {
      if (error.response.status === 401) {
        // dispatch({ type: "auth/Logout" });
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);


// ================================================================= FUND WALLET BALANCE

export const getCardByUid = createAsyncThunk(
  "getCardByUid",
  async ({ uid }, { rejectWithValue, dispatch, getState }) => {
    const cardUid = uid === "Razer Gold " ? "Razer Gold" : uid;
    try {
      console.log(uid)
      const res = await axios.get(`${baseUrl}/cards/sell/${cardUid}`, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": getState().auth.token.toString(),
        },
      });
      if (res.status === 200) {
        console.log(res);
        return res; 
      }
      return res;
    } catch (error) {
      if (error.response.status === 401) {
        // dispatch({ type: "auth/Logout" });
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
  );
  
  // ================================================================= FUND WALLET BALANCE
  
  