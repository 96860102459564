import React, { useEffect, useState } from "react";
import Dojah from "react-dojah";
import Lottie from "lottie-react";
import loader from "../assets/lottie/loader.json";
import styles from "./settings/kyc.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { verifyAddress } from "../store/KYC/kycAction";
import cogoToast from "cogo-toast";

const DojahComponent = () => {
  const navigate = useNavigate();
  const navigator = window.navigator;
  const dispatch = useDispatch();

  const [loading, setLoading] = useState();

  const userId = useSelector((state) => state.auth.user.user_id);
  const firstName = useSelector((state) => state.auth.user.first_name);
    const lastName = useSelector((state) => state.auth.user.last_name);
    const myLevel = useSelector((state) => state.userKyc.kycStats.userkyc);

  const onSuccess = (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    };
    
    useEffect(() => { 
        if (myLevel && myLevel.kyc_setting_id === 4 ) {
            navigate("/settings")
        }
    },[])

  const appID = "648c3ef322815f00400eac57";
  const publicKey = "test_pk_KjHkx3msUFYENjCG0uqawRE4L";
  const type = "custom";

  const config = {
    widget_id: "64ad968c4d332100404186f2",
    webhook: false,
  };

  const reloadPage = () => {
    if (window.location.pathname === "/settings") {
      window.location.reload()
    }
  }

  const userData = {
    first_name: firstName ? firstName : "",
    last_name: lastName ? lastName : "",
  };

  const metadata = {
    user_id: userId ? userId : "",
  };

  const response = (type, data) => {
    console.log(type, data);
    if (type === "success") {
      dispatch(
        verifyAddress({
          metadata: data.metadata,
          verificationUrl: data.verificationUrl,
          referenceId: data.referenceId,
        })
      );
      console.log(data);
      navigate("/settings");
    } else if (type === "error") {
      cogoToast.error('Oops! there was an error')
      navigate("/settings");
    } else if (type === "close") {
      navigate("/settings");
      reloadPage()
    } else if (type === "begin") {
    } else if (type === "loading") {
    }
  };

  return (
    <>
      {loading === false ? (
        <div className={styles.lottieWrapDrop}>
          <Lottie
            style={{
              width: "70px",
              height: "70px",
              color: "#fff",
              padding: "0px",
              margin: "0px",
            }}
            animationData={loader}
            loop={true}
          />
        </div>
      ) : (
        <Dojah
          response={response}
          appID={appID}
          publicKey={publicKey}
          type={type}
          config={config}
          userData={userData}
          metadata={metadata}
        />
      )}
    </>
  );
};

export default DojahComponent;
