import React, { useState } from "react";
import style from "./forgot-password.module.css";
import { Icon } from "@iconify/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import tribox from "../../../assets/images/tribox.png";
import rafiki from "../../../assets/images/rafiki.png";
import { Eye, EyeSlash } from "iconsax-react";
import cogoToast from "cogo-toast";
import { resetSchema } from "../../../schemas/Index";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Lottie from "lottie-react";
import loader from "../../../assets/lottie/loader.json";
import { useDebugValue } from "react";
import { completeReset } from "../../../store/auth/authActions";
import { clearHasResetPwd } from "../../../store/auth/authSlice";

const ResetPassword = () => {
    const [visible, setVisible] = useState(false);
    const [isFilled, setIsFilled] = useState(false)
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    const isLoading = useSelector(state => state.auth.loading)
    const {hasResetPwd} = useSelector(state => state.auth)
    const params = useParams()

    const navigate = useNavigate()

  const togglePasswordVisibility = () => {
    setVisible(!visible);
  };
 

    const onSubmit = () => {
        if (values.password !== values.confirmPassword) {
            cogoToast.warn('please make sure that the two passwords match')
        } else {
            dispatch(completeReset({
              resetCode : params.userToken,
              newPassword : values.password,
          }))
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        password: "",
        confirmPassword: "",
      },
      validationSchema: resetSchema,
      onSubmit,
    });
  
  useEffect(() => {
    if (hasResetPwd) {
      navigate('/')
      setTimeout(() => {
        dispatch(clearHasResetPwd())
      }, 800)
    }
  }, [hasResetPwd]);

    useEffect(() => { 
        if (values.password !== "" && values.confirmPassword !== "" && !errors.password &&
            !errors.confirmPassword) {
            setIsFilled(true)
            }else setIsFilled(false)
    }, [values, errors, setIsFilled])

    useEffect(() => { 
        if (isLoading === true) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [isLoading])

  return (
    <div className={style.login}>
      <div className={style.log}>
        <div className={style.left}>
          <div className={style.contLeft}>
            <h2>Reset Password</h2>
            <p>Enter the preferred password of your choice</p>

            <div className={style.form}>
              <form autoComplete={false} action="">
                <div className={style.labelInput}>
                  <label htmlFor="first-name">New Password</label>
                  <div className={style.input}>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="password"
                      type={!visible ? "password" : "text"}
                      placeholder="Enter Your Password"
                      value={values.password}
                      className={
                        errors.password && touched.password
                          ? style.error
                          : style.notError
                      }
                    />
                    {!visible ? (
                      <Eye
                        className={style.eyeCon}
                        onClick={togglePasswordVisibility}
                        variant="Bulk"
                        size="24"
                      />
                    ) : (
                      <EyeSlash
                        className={style.eyeCon}
                        onClick={togglePasswordVisibility}
                        variant="Bulk"
                        size="24"
                      />
                    )}
                  </div>
                  {errors.password && touched.password && (
                    <p className={style.pError}>{errors.password}</p>
                  )}
                </div>

                <div className={style.labelInput}>
                  <label htmlFor="first-name">Confirm Password</label>
                  <div className={style.input}>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="confirmPassword"
                      type={!visible ? "password" : "text"}
                      placeholder="Confirm Your Password "
                      value={values.confirmPassword}
                      className={
                        errors.confirmPassword && touched.confirmPassword
                          ? style.error
                          : style.notError
                      }
                    />
                    {!visible ? (
                      <Eye
                        className={style.eyeCon}
                        onClick={togglePasswordVisibility}
                        variant="Bulk"
                        size="24"
                      />
                    ) : (
                      <EyeSlash
                        className={style.eyeCon}
                        onClick={togglePasswordVisibility}
                        variant="Bulk"
                        size="24"
                      />
                    )}
                  </div>
                  {errors.confirmPassword && touched.confirmPassword && (
                    <p className={style.pError}>{errors.confirmPassword}</p>
                  )}
                </div>

                {loading === true ? (
                  <div className={style.lottieWrap}>
                    <Lottie
                      style={{
                        width: "70px",
                        height: "70px",
                        color: "#fff",
                      }}
                      animationData={loader}
                      loop={true}
                    />
                  </div>
                ) : (
                  <button
                    onClick={handleSubmit}
                    className={style.createBtn}
                    type="submit"
                    disabled={!isFilled}
                  >
                    Reset Password
                  </button>
                )}
                <p className={style.logi}>
                  Remember your password ? <Link to="/signin">Back to login</Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default ResetPassword;
