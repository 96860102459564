import { ArrowDown2 } from 'iconsax-react';
import React, { useState } from 'react'
import style from './calc.module.css'
import amazon from "../../assets/images/cards/amazon.svg";
import amexgold from "../../assets/images/cards/amexgold.svg";
import apple from "../../assets/images/cards/apple.svg";
import ebay from "../../assets/images/cards/ebay.svg";
import google from "../../assets/images/cards/google.svg";
import itunes from "../../assets/images/cards/itunes.svg";
import nike from "../../assets/images/cards/nike.svg";
import nordstorm from "../../assets/images/cards/nordstorm.svg";
import razer from "../../assets/images/cards/razer.svg";
import sephora from "../../assets/images/cards/sephora.svg";
import steam from "../../assets/images/cards/steam.svg";
import target from "../../assets/images/cards/target.svg";
import vanilla from "../../assets/images/cards/vanilla.svg";
import visa from "../../assets/images/cards/visa.svg";
import walmart from "../../assets/images/cards/walmart.svg";
import { useEffect } from 'react';
import { getAllCards, getCardByUid, getFiatCurrencies } from '../../store/Calc/calcActions';
import {useDispatch, useSelector} from 'react-redux'
import { proceedToTrade } from '../../store/Calc/calcSlice';
import { useNavigate } from 'react-router-dom';
import { setHeader } from '../../store/settings/settingsSlice';
import CryptoCalc from './CryptoCalc';
import { browserName } from 'react-device-detect';

const currencies = [
    { id: 1, title:"NGN"},
    { id: 2, title:"USD"},
    { id: 3, title:"GBP"},
    { id: 4, title:"KWT"},
    { id: 5, title:"CEDIS"},
    { id: 6, title:"AUD"},
]

const cards = [
    {id: 1, title :"Amazon", image:amazon},
    {id: 2, title :"Amex Gold", image:amexgold},
    {id: 3, title :"Ebay", image:ebay},
    {id: 4, title :"Google Play", image:google},
    {id: 5, title :"iTunez", image:itunes},
    {id: 6, title :"Nike", image:nike},
    {id: 7, title :"Nordstorm", image:nordstorm},
    {id: 8, title :"One Vanilla", image:vanilla},
    {id: 9, title :"Steam", image:steam},
    {id: 10, title :"Walmart", image:walmart},
    {id: 11, title :"Apple Store", image:apple},
    {id: 12, title :"Target", image:target},
    {id: 13, title :"Razer Gold", image:razer},
    {id: 14, title :"Visa", image:visa},
    {id: 15, title :"Sephora", image:sephora},
]


const Calculator = ({ calcRoute, setCalcRoute }) => {
  const [currency, setCurrency] = useState("");
  const [openCurrency, setOpenCurrency] = useState(false);
  const [card, setCard] = useState("");
  const [cardImg, setCardImg] = useState("");
  const [openCard, setOpenCard] = useState(false);
  const [amount, setAmount] = useState();
  const [cardCat, setCardCat] = useState("");
  const [openCardCat, setOpenCardCat] = useState(false);
  const [rate, setRate] = useState(0);
  const [currRate, setCurrRate] = useState(0);
  const [cardRate, setCardRate] = useState("");
  const [totalRate, setTotalRate] = useState("");
  const [currNo, setCurrNo] = useState(0);

  const empty = "";

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const calcData = useSelector((state) => state.calc.data);
  const calcCards = useSelector((state) => state.calc.cards);
  const calcCardDetails = useSelector((state) => state.calc.cardDetails);
  const walletData = useSelector((state) => state.wallet.data);

  /* THIS IS WHERE THE CURRIENCIES ARE CALLED AND THIS IS HAPPENING ONCE
    THE RATE CALCULATOR PAGE IS OPENED ONR RELOADED */
  useEffect(() => {
    dispatch(getFiatCurrencies());
    dispatch(getAllCards());
  }, []);

  const toggleCurrency = () => {
    setOpenCurrency((prevCurr) => {
      if (prevCurr === false) {
        return true;
      } else if (prevCurr === true) {
        return false;
      }
    });
  };

  const handleChangeCard = () => {};

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleCurrency = (code, rate) => {
    setOpenCurrency(false);
    setCurrency(code);
    setCurrRate(rate);
  };

  const toggleCard = () => {
    setOpenCard((prevCard) => {
      if (prevCard === false) {
        return true;
      } else if (prevCard === true) {
        return false;
      }
    });
  };

  const handleCard = (image, name, uid) => {
    setOpenCard(false);
    setCard(name);
    setCardImg(image);
    dispatch(getCardByUid({ uid }));
    setCardCat(empty);
    setAmount(empty);
    // setFormPI({
    //     ...formPI, country: e.target.textContent
    // })
    // console.log(formPI)
  };

const toggleCardCat = () => {
  setOpenCardCat((prevCardCat) => {
    if (prevCardCat === false) {
      return true;
    } else if (prevCardCat === true) {
      return false;
    }
  });
};

  const handleCardCat = (desc, rate) => {
    setOpenCardCat(false);
    setCardCat(desc);
    setRate(rate);
    setAmount(empty);
  };

  const handleProceed = () => {
    navigate("/trade");
  };

  useEffect(() => {
    dispatch(setHeader("Rate Calculator"));
  }, []);

  useEffect(() => {
    setCardRate(parseInt(currRate) * rate);
    setTotalRate(cardRate * parseInt(amount));
    console.log(currRate, rate, cardRate, parseInt(amount));
  }, [rate, currRate, amount, cardRate]);

  useEffect(() => {
    dispatch(getFiatCurrencies());
  }, []);

  // HANDLE CURRENCY CLOSE ON CLICK OUT
  useEffect(() => {
    let handler = (e) => {
      if (e.target.id !== "curr") {
        setOpenCurrency(false);
      } else if (e.target.id === "curr") {
        toggleCurrency();
      }
      if (e.target.id === "currDrop") {
        setOpenCurrency(true);
      }
      // console.log(currRef.current.name)
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  // HANDLE CARD CLOSE ON CLICK OUT
  useEffect(() => {
    let handler = (e) => {
      if (e.target.id !== "card") {
        setOpenCard(false);
      } else if (e.target.id === "card") {
        toggleCard();
      }
      if (e.target.id === "cardDrop") {
        setOpenCard(true);
      }
      // console.log(currRef.current.name)
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  // HANDLE CARD CAT CLOSE ON CLICK OUT
  useEffect(() => {
    let handler = (e) => {
      if (e.target.id !== "cardCat") {
        setOpenCardCat(false);
      } else if (e.target.id === "cardCat") {
        toggleCardCat();
      }
      if (e.target.id === "cardCatDrop") {
        setOpenCardCat(true);
      }
      // console.log(currRef.current.name)
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);


  return (
    <div className={style.calc}>
      <div className={style.switch}>
        <div className={style.switchTitle}>
          <p
            className={
              calcRoute === "giftCards" ? style.switchPeeHi : style.switchPee
            }
            onClick={() => {
              setCalcRoute("giftCards");
            }}
          >
            Giftcard
          </p>
          <p
            className={
              calcRoute !== "giftCards" ? style.switchPeeHi : style.switchPee
            }
            onClick={() => {
              setCalcRoute("crypto");
            }}
          >
            Crypto
          </p>
        </div>
        <div className={style.switchSlider}>
          <div
            style={{
              left: calcRoute === "giftCards" ? "0%" : "50%",
            }}
            className={style.switchSlideBar}
          ></div>
        </div>
      </div>
      <div className={style.top}>
        {/* <h5>Rate Calculator</h5> */}
        <p>Get the current value of your transaction</p>
      </div>

      {calcRoute === "giftCards" ? (
        <div className={style.form}>
          <form action="">
            <div className={style.labelPut}>
              <label htmlFor="acct_type"> Select Currency </label>
              <div className={style.select}>
                <div id="curr"
                  name="acctType" className={style.selectInput}>
                  <input
                    style={{pointerEvents: "none"}}
                    type="text"
                    disabled={browserName === "Firefox" ? false : true}
                    value={currency}
                    placeholder="Pick a Currency"
                  />
                  <ArrowDown2
                    style={{
                      transform:
                        openCurrency === true
                          ? "translateY( -50%) rotate(-540deg)"
                          : "translateY( -50%) rotate(0deg)",
                      pointerEvents: "none",
                    }}
                    className={style.dropDown}
                    size="20"
                    color="#909090"
                    variant="Bold"
                  />
                </div>
                <div
                  style={{
                    height: openCurrency === true ? "fit-content" : "0px",
                    maxHeight: "120px",
                  }}
                  className={style.option}
                >
                  {walletData.fiatWallets ? (
                    walletData.fiatWallets.map((item) => (
                      <p
                        id="currDrop"
                        key={item.id}
                        onClick={(code, rate) => {
                          handleCurrency(
                            (code = item.Currency.code),
                            (rate = item.Currency.we_buy)
                          );
                        }}
                      >
                        {item.Currency.code} Wallet
                      </p>
                    ))
                  ) : (
                    <p>No wallets to show</p>
                  )}
                </div>
                {/* <input type="text" placeholder='Enter Last Name' /> */}
              </div>
            </div>
            <div className={style.labelPut}>
              <label htmlFor="acct_type"> Select Card</label>
              <div className={style.select}>
                <div className={style.cardImgDrop2}>
                  {cardImg ? (
                    <img src={cardImg} alt="astroafrica.site/card.png" />
                  ) : null}
                </div>
                <div id="card"
                  name="acctType" className={style.selectInput}>
                  {/* <img src={} alt="" /> */}
                  <input
                    
                    style={{ paddingLeft: cardImg ? "56px" : "12px", pointerEvents: "none" }}
                    type="text"
                    disabled={browserName === "Firefox" ? false : true}
                    value={card}
                    placeholder="Select Card"
                  />
                  <ArrowDown2
                    style={{
                      transform:
                        openCard === true
                          ? "translateY( -50%) rotate(-540deg)"
                          : "translateY( -50%) rotate(0deg)",
                      pointerEvents: "none",
                    }}
                    className={style.dropDown}
                    size="20"
                    color="#909090"
                    variant="Bold"
                  />
                </div>
                <div
                  style={{
                    height: openCard === true ? "fit-content" : "0px",
                    maxHeight: "220px",
                  }}
                  className={style.option}
                >
                  {calcCards.length > 0 ? (
                    calcCards.map((item) => (
                      <div
                        id="cardDrop"
                        key={item.id}
                        onClick={(image, name, uid) => {
                          handleCard(
                            (image = item.image),
                            (name = item.name),
                            (uid = item.uid)
                          );
                        }}
                        className={style.cardItem}
                      >
                        <div
                          style={{ pointerEvents: "none" }}
                          className={style.cardImgDrop}
                        >
                          <img
                            src={item.image}
                            alt={`astroafrica.site/${item.title}`}
                          />
                        </div>
                        <p style={{ pointerEvents: "none" }}> {item.name} </p>
                      </div>
                    ))
                  ) : (
                    <p>Cards have not loaded </p>
                  )}
                </div>
                {/* <input type="text" placeholder='Enter Last Name' /> */}
              </div>
            </div>
            <div className={style.labelPut}>
              <label htmlFor="acct_type"> Select Card Category</label>
              <div className={style.select}>
                <div id="cardCat"
                  name="acctType" className={style.selectInput}>
                  <input
                   style={{pointerEvents: "none"}}
                    type="text"
                    disabled={browserName === "Firefox" ? false : true}
                    value={cardCat}
                    placeholder="Pick a Category"
                  />
                  <ArrowDown2
                    style={{
                      transform:
                        openCardCat === true
                          ? "translateY( -50%) rotate(-540deg)"
                          : "translateY( -50%) rotate(0deg)",
                      pointerEvents: "none",
                    }}
                    className={style.dropDown}
                    size="20"
                    color="#909090"
                    variant="Bold"
                  />
                </div>
                <div
                  style={{
                    height: openCardCat === true ? "fit-content" : "0px",
                    maxHeight: "180px",
                  }}
                  className={style.option}
                >
                  {calcCardDetails.length > 0 ? (
                    calcCardDetails.map((item) => (
                      <div
                        id="cardCatDrop"
                        onClick={(desc, rate) => {
                          handleCardCat(
                            (desc = item.description),
                            (rate = item.rate)
                          );
                        }}
                        className={style.cardItem}
                      >
                        {/* <img src={item.GiftCard.image} alt={`astroafrica.site/${item.title}`} /> */}
                        <p style={{ pointerEvents: "none" }} key={item.id}>
                          {" "}
                          {item.description}{" "}
                        </p>
                      </div>
                    ))
                  ) : (
                    <p>There are no categories here</p>
                  )}
                </div>
                {/* <input type="text" placeholder='Enter Last Name' /> */}
              </div>
            </div>

            <div className={style.labelPut}>
              <label htmlFor="number"> Amount </label>
              <div className={style.input}>
                <input
                  onChange={handleAmountChange}
                  type="number"
                  name="acct_number"
                  placeholder="Enter Amount"
                  value={amount}
                />
              </div>
            </div>
          </form>
          <div className={style.rate}>
            <div className={style.eachRate}>
              <p>Current Rate</p>
              <p>
                {cardRate > 0
                  ? new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: walletData.fiatWallets[currNo].Currency.code,
                    }).format(cardRate)
                  : "0.00"}
                /$
              </p>
            </div>
            <hr className={style.erHr} />
            <div className={style.eachRate}>
              <p>
                Total
                {/* {currency === "NGN" && " ₦"}
              {currency === "GHS" && " ¢"}
              {currency === "USD" && " $"} */}
              </p>
              <span>
                {totalRate > 0
                  ? new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: currency,
                    }).format(totalRate)
                  : "0.00"}
              </span>
            </div>
          </div>
          <button onClick={handleProceed} className={style.proceed}>
            Proceed to Trade
          </button>
        </div>
      ) : (
        <CryptoCalc calcRoute={calcRoute} setCalcRoute={setCalcRoute} />
      )}
    </div>
  );
};

export default Calculator