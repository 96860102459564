import { createSlice } from "@reduxjs/toolkit";
import { getAllCards, getCardByUid, getFiatCurrencies } from "./calcActions";

const initialState = {
    id: "",
    loading: false,
    data: {},
    cards: [],
    cardDetails: [],
    tradeDets: {},
    cardCatLoading: false,
}

const calcSlice = createSlice({
    name: "calc",
    initialState,
    reducers: {
        // proceedToTrade: (state, {payload}) => {
        //     state.tradeDets = {...payload, } 
        // }
        clearCardDetails: (state) => {
            state.cardDetails = []
        },
    },
    extraReducers: {

        // ======================================================= REDUCERS TO GET ALL EXISTING FIAT CURRENCIES TRADEABLE
        
        [getFiatCurrencies.pending]: (state, { payload }) => {
            state.loading = true;
        },
        [getFiatCurrencies.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.data = payload.data.data;
        },
        [getFiatCurrencies.rejected]: (state, { payload }) => {
            state.loading = false;
        },
        
        
        // ======================================================= REDUCERS TO GET ALL EXISTING CARDS

        [getAllCards.pending]: (state, { payload }) => {
            state.loading = true;
        },
        [getAllCards.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.cards = payload.data.data.cards
        },
        [getAllCards.rejected]: (state, { payload }) => {
            state.loading = false;
        },


        // ======================================================= REDUCERS TO GET A CARD BY UID

        [getCardByUid.pending]: (state, { payload }) => {
            state.loading = true;
            state.cardCatLoading = true;
        },
        [getCardByUid.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.cardCatLoading = false;
            state.cardDetails = payload.data.data.cardRateDetails
        },
        [getCardByUid.rejected]: (state, { payload }) => {
            state.cardCatLoading = false;
            state.loading = false;
        },
    }
})

export default calcSlice.reducer
export const { proceedToTrade, clearCardDetails } = calcSlice.actions;