import React, { useEffect, useState } from 'react'
import style from './signup.module.css'
import logo from '../../../assets/images/logo.png';
import astro1 from '../../../assets/images/astro1.svg'
import astro2 from '../../../assets/images/astro2.svg'




const images = [astro1, astro2];

const ImageComp = () => {

    const [count, setCount] = useState(1);
    const [image, setImage] = useState(images[count - 1])
    
    const handleCounter = () => {
        if (count < 2) { 
            setTimeout(() => {
                setCount(count + 1)
            }, 5000)
        } else if (count + 1 > 1) {
            setTimeout(() => {
                setCount(1)
            },5000)
        }

        setImage(images[count - 1])
    }

    useEffect(() => { 
        handleCounter()
    }, [count])

  return (
    <div className={style.left} >
        <div className={style.logo} style={{zIndex:2000}} >
            <img src={logo} alt="astroafrica.site/logo.svg" />
        </div>
        <div className={style.contImg} >
            <div className={style.img} >
                <img src={image} alt="astroafrica.site/astro1.svg" />
            </div>
            <div className={style.cont} >
                <h2>Trade with Astro</h2>
                <p>Best rates, fast payment, and responsive support. <br />
                    Experience a life without limits with Astro</p>
                
                {/* these are the dots indicating the picture on display. */}
                <div className={style.slideCators} >
                      <div style={{
                          width: count === 1 ? "24px" : "6px",
                          background: count === 1 ? "#40196D" : "#EAE9ED"
                    }} className={style.dotActive} ></div>
                      <div
                        style={{
                          width: count === 2 ? "24px" : "6px",
                          background: count === 2 ? "#40196D" : "#EAE9ED"
                        }}
                          className={style.dot} ></div>
                      <div
                        style={{
                          width: count === 3 ? "24px" : "6px",
                          background: count === 3 ? "#40196D" : "#EAE9ED"
                        }}
                          className={style.dot} ></div>
                </div>
            </div>
      </div>
    </div>
  )
}

export default ImageComp