import { Icon } from '@iconify/react'
import { CallCalling, Card, Global, Monitor } from 'iconsax-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Airtime from './Airtime'
import style from './bills.module.css'
import Cable from './cable'
import Data from './Data'
import Electricity from './Electricity'

const Bills = () => {

  const navigate = useNavigate()

  return (
    <div className={style.bills} >

      <div className={style.bButtons} >

        <div
          onClick={() => {navigate('/bills')}}
          className={window.location.pathname === '/bills' ? style.buttonHi : style.button} >
          <Monitor size="20" className={style.icon} />
          <p>Cable</p>
        </div>

        <div
         onClick={() => {navigate('/bills/airtime')}}
         className={ window.location.pathname === '/bills/airtime' ? style.buttonHi : style.button } >
          <CallCalling size="20" className={style.icon} />
          <p>Airtime</p>
        </div>

        <div
         onClick={() => {navigate('/bills/data')}}
         className={ window.location.pathname === '/bills/data' ? style.buttonHi : style.button } >
          <Global size="20" className={style.icon} />
          <p>Data Subscription</p>
        </div>

        <div
         onClick={() => {navigate('/bills/electricity')}}
         className={ window.location.pathname === '/bills/electricity' ? style.buttonHi : style.button } >
          <Icon icon="tabler:bolt" className={style.icon} />
          <p>Electricity</p>
        </div>



      </div>

    {/* ============= BILLS SECTION HERE */}
      {
        window.location.pathname === '/bills' && <Cable/>
      }

      
      {/* ==================== AIRTIME SECTION HERE */}
      {
        window.location.pathname === '/bills/airtime' && <Airtime/>
      }

      {/* ======== DATA SUBSCRIPTION SECTION HERE */}
      {
        window.location.pathname === '/bills/data' && <Data/>
      }


      {/* ================= ELECTRICITY SECTION HERE */}
      {
        window.location.pathname === '/bills/electricity' && <Electricity/>
      }


      

    </div>
  )
}

export default Bills