import React, { useState } from 'react';
import style from './personal-info.module.css'
import { Icon } from '@iconify/react'
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { ArrowDown2, FlashCircle } from 'iconsax-react';
import PinInput from 'react-pin-input';

const countries = [
    { id: 1, title:"Nigeria"},
    { id: 2, title:"Korea"},
    { id: 3, title:"United States"},
    { id: 4, title:"United Kingdom"},
    { id: 5, title:"South Africa"},
    { id: 6, title:"United Arab Emirates"},
    { id: 7, title:"Zambia"},
    { id: 8, title:"Netherlands"},
    { id: 9, title:"Turkey"},
    { id: 10, title:"Canada"},
    { id: 11, title:"Philippines"},
    { id: 12, title:"Tunisha"},
    { id: 13, title:"Congo"},
    { id: 14, title:"Australia"},
]

const acctTypes = [
    { id: 1, title:"Savings"},
    { id: 2, title:"Current"},
    { id: 3, title:"Domiciliary"},
    { id: 4, title:"Salary"},
    { id: 5, title:"Fixed deposit"},
    { id: 6, title:"Diaspora"},
]

const banks = [
    { id: 1, title:"GTB"},
    { id: 2, title:"First Bank"},
    { id: 3, title:"UBA"},
    { id: 4, title:"Polaris"},
    { id: 5, title:"Stanbic"},
    { id: 6, title:"KeyStone"},
]


const PersonalInfo = ({newRoute, setNewRoute, setVerifiedKYC}) => {

    // console.log(window.location)

    const navigate = useNavigate()

    const [personalInfo, setPersonalInfo] = useState(false);
    const [accountDetails, setAccountDetails] = useState(false);
    const [transactionPin, setTransactionPin] = useState(false);

    const [filledPI, setFilledPI] = useState(false)
    const [filledAD, setFilledAD] = useState(false)
    const [filledTP, setFilledTP] = useState(false)

    const [openCountry, setOpenCountry] = useState(false)
    const [country, setCountry] = useState("")

    const [openAcctType, setOpenAcctType] = useState(false)
    const [acctType, setAcctType] = useState("")

    const [openBank, setOpenBank] = useState(false)
    const [bank, setBank] = useState("")

    const [formPI, setFormPI] = useState({
        firstname: "",
        lastname: "",
        username: "",
        phone_no: "",
        country:""
    })
    const [formAD, setFormAD] = useState({
        acct_name: "",
        acct_no: "",
        bankType: "",
        bank: ""
    })
    const [formTP, setFormTP] = useState({
        acct_name: "",
        acct_no: "",
        bank: ""
    })


    // =================================================================== FOR COUNTRY INFO
    
    const toggleCountry = () => { 
        setOpenCountry(!openCountry)
    }

    const handleCountry = (e) => { 
        setOpenCountry(false);
        setCountry(e.target.textContent);
        setFormPI({
            ...formPI, country: e.target.textContent
        })
        console.log(formPI)
    }
    
    
    // =================================================================== FOR ACCT TYPE INFO
    
    const toggleAcctType = () => { 
        setOpenAcctType(!openAcctType)
    }

    const handleAcctType = (e) => { 
        setOpenAcctType(false);
        setAcctType(e.target.textContent);
        setFormAD({
            ...formAD, bankType: e.target.textContent
        })
        console.log(formAD)
    }
    
    // =================================================================== FOR BANK INFO
    
    const toggleBank = () => { 
        setOpenBank(!openBank)
    }

    const handleBank = (e) => { 
        setOpenBank(false);
        setBank(e.target.textContent);
        setFormAD({
            ...formAD, bank: e.target.textContent
        })
        console.log(formAD)
    }
    
    
    // =================================================================== END OF BANK INFO

    const handleFillPI = (e) => {
        setFormPI({
            ...formPI, [e.target.name] : e.target.value
        })
    }

    const handleFillAD = (e) => {
        setFormAD({
            ...formAD, [e.target.name] : e.target.value
        })
        console.log(formAD)
    }
    
    const handleProceed = (e) => {
        e.preventDefault();

       navigate('/dashboard/account-details')
       console.log(formPI)
    }

    const handleProceedAD = (e) => {
        e.preventDefault();

        navigate('/dashboard/transaction-pin')
        
        console.log(accountDetails)
    }

    const handleProceedTP = (e) => {
        e.preventDefault();

        setVerifiedKYC(true);
        navigate('/dashboard')
        
        console.log(accountDetails)
    }
    
    useEffect(() => {
        if (
            formPI.firstname !== "" &&
            formPI.lastname !== "" &&
            formPI.username !== "" &&
            formPI.phone_no !== "" &&
            formPI.country !== "" 
            
            ) {
                setPersonalInfo(true)
                setFilledPI(true)
            } else {
                setFilledPI(false)
                setPersonalInfo(false)
            }
        
    } , [formPI])
    
    useEffect(() => {
        if (
            formAD.acct_name !== "" &&
            formAD.acct_no !== "" &&
            formAD.bankType !== "" &&
            formAD.bank !== ""
            
            ) {
                setFilledAD(true)
                setAccountDetails(true)
            } else {
                setAccountDetails(false)
                setFilledAD(false)
        }
        
    } , [formAD])


  return (
      <div className={style.personalInfo} >
          <div className={style.piContainer} >
              <h4 className={style.h4} >Welcome Back</h4>
              <div className={style.bottom} >
                  <div className={style.indicator} >
                      {personalInfo === true || window.location.pathname === "/dashboard/account-details" || window.location.pathname === "/dashboard/transaction-pin" ?  <Icon onClick={() => { navigate('/dashboard/personal-info') }} className={style.tickCircle} icon="mdi:tick-circle" /> : <div onClick={() => { navigate('/dashboard/personal-info') }} className={ window.location.pathname === "/dashboard/personal-info" ? style.progressCircle : style.noCircle } ></div> }
                      <div className={  window.location.pathname === "/dashboard/account-details" || window.location.pathname === "/dashboard/transaction-pin" ? style.linkageDone : style.linkage} ></div>
                      {accountDetails === true || window.location.pathname === "/dashboard/transaction-pin" ?   <Icon onClick={() => { navigate('/dashboard/account-details') }} className={style.tickCircle} icon="mdi:tick-circle" />  : <div  className={ window.location.pathname === "/dashboard/account-details" ? style.progressCircle : style.noCircle } ></div> }
                      <div className={  window.location.pathname === "/dashboard/transaction-pin" ? style.linkageDone : style.linkage}  ></div>
                      {transactionPin === true ? <Icon onClick={() => { navigate('/dashboard/transaction-pin') }} className={style.tickCircle} icon="mdi:tick-circle" />  : <div className={ window.location.pathname === "/dashboard/transaction-pin" ? style.progressCircle : style.noCircle }  ></div> }
                  </div>
                  <div className={style.dotLabels} >
                      <p>Personal Information</p>
                      <p>Account Details</p>
                      <p>Transaction Pin</p>
                  </div>
 
                  {
                      window.location.pathname === '/dashboard/personal-info' && (
                          <div className={style.form} >
                      <form action="">
                          <div className={style.flName} >
                              <div className={style.labelPut} >
                                  <label htmlFor="firstName"> First Name</label>
                                  <div className={style.input} >
                                      <input type="text" onChange={handleFillPI} name='firstname' placeholder='Enter First Name' />
                                  </div>
                              </div>
                              <div className={style.labelPut} >
                                  <label htmlFor="lastName"> Last Name</label>
                                  <div className={style.input} >
                                      <input type="text" onChange={handleFillPI} name='lastname' placeholder='Enter Last Name' />
                                  </div>
                              </div>
                          </div>
                          <div className={style.labelPut} >
                              <label htmlFor="username"> Username</label>
                              <div className={style.input} >
                                  <input type="text" onChange={handleFillPI} name='username' placeholder='Enter Username' />
                              </div>
                          </div>
                          <div className={style.labelPut} >
                              <label htmlFor="phone_no"> Phone Number</label>
                              <div className={style.input} >
                                  <input type="number" onChange={handleFillPI} name='phone_no' placeholder='Enter Phone Number' />
                              </div>
                          </div>
                          <div className={style.labelPut} >
                              <label htmlFor="country"> Country</label>
                              <div className={style.select} >
                                    <div onClick={toggleCountry} className={style.selectInput} >
                                        <input name="country" type="text" disabled={true} value={country} placeholder='Please select a country' />
                                        <ArrowDown2 
                                        style={{
                                            transform: openCountry === true ? "translateY( -50%) rotate(-540deg)" : "translateY( -50%) rotate(0deg)" 
                                        }}
                                         className={style.dropDown} size="20" color="#909090" variant="Bold"/>
                                    </div>
                                    <div 
                                    style={{
                                        height: openCountry === true ? "120px" : "0px"
                                    }}
                                    className={style.option}>
                                        {
                                            countries?.map(country => (
                                                <p key={country.id} onClick={handleCountry} > { country.title } </p>
                                            ))
                                        } 
                                    </div>
                                  {/* <input type="text" placeholder='Enter Last Name' /> */}
                              </div>
                          </div>
                          <button onClick={handleProceed} className={style.proceed} disabled={!filledPI} type='submit' >Proceed</button>
                      </form>
                  </div>
                      )
                  }

                  {/* this is for the account details */}
                  {
                      window.location.pathname === '/dashboard/account-details' && (
                          <div className={style.form} >
                      <form action="">
                          <div className={style.labelPut} >
                              <label htmlFor="acct_type"> Account Type</label>
                              <div className={style.select} >
                                    <div onClick={toggleAcctType} className={style.selectInput} >
                                        <input name="acct_type" type="text" disabled={true} value={acctType} placeholder='Select account type' />
                                        <ArrowDown2
                                        style={{
                                            transform: openAcctType === true ? "translateY( -50%) rotate(-540deg)" : "translateY( -50%) rotate(0deg)" 
                                        }}
                                         className={style.dropDown} size="20" color="#909090" variant="Bold"/>
                                    </div>
                                    <div 
                                    style={{
                                        height: openAcctType === true ? "120px" : "0px"
                                    }}
                                    className={style.option}>
                                        {
                                            acctTypes?.map(acctType => (
                                                <p key={acctType.id} onClick={handleAcctType} > { acctType.title } </p>
                                            ))
                                        }
                                    </div>
                                  {/* <input type="text" placeholder='Enter Last Name' /> */}
                              </div>
                          </div>
                          
                                  


                          <div className={style.labelPut} >
                              <label htmlFor="country"> Bank Name</label>
                              <div className={style.select} >
                                    <div onClick={toggleBank} className={style.selectInput} >
                                        <input name="bank" type="text" disabled={true} value={bank} placeholder='Please select a bank' />
                                        <ArrowDown2 
                                        style={{
                                            transform: openBank === true ? "translateY( -50%) rotate(-540deg)" : "translateY( -50%) rotate(0deg)" 
                                        }}
                                         className={style.dropDown} size="20" color="#909090" variant="Bold"/>
                                    </div>
                                    <div 
                                    style={{
                                        height: openBank === true ? "120px" : "0px"
                                    }}
                                    className={style.option}>
                                        {
                                            banks?.map(bank => (
                                                <p key={bank.id} onClick={handleBank} > { bank.title } </p>
                                            ))
                                        }
                                    </div>
                                  {/* <input type="text" placeholder='Enter Last Name' /> */}
                              </div>
                          </div>
                          
                                  


                          <div className={style.labelPut} >
                              <label htmlFor="acct_name"> Account Number</label>
                              <div className={style.input} >
                                  <input type="text" onChange={handleFillAD} name='acct_name' placeholder='Enter the name of the account' />
                              </div>
                          </div>
                          <div className={style.labelPut} >
                              <label htmlFor="acct_no">Account Name</label>
                              <div className={style.input} >
                                  <input type="number" onChange={handleFillAD} name='acct_no' placeholder='Enter the account number' />
                              </div>
                          </div>
                          <button onClick={handleProceedAD} className={style.proceed} disabled={!filledAD} type='submit' >Proceed</button>
                      </form>
                  </div>
                      )
                  }

                  {/* this is for the transaction pin */}
                  {
                      window.location.pathname === '/dashboard/transaction-pin' && (
                          <div className={style.formTP} >
                              <h4>Select a 5 digit transaction pin</h4>
                              <p>You will need this to approve your withdrawals</p>
                              <div className={style.pinInput} >
                                  <form action="">
                                      <PinInput 
                                    length={5} 
                                    initialValue=""
                                    secret={true} 
                                       onChange={(value, index) => {
                                        // setPinFilled( pinFilled === true ? false : false)  
                                    }}
                                    type="numeric" 
                                    inputMode="number"
                                    style={{padding: '10px'}}  
                                    inputStyle={{border: '1px solid', borderColor: '#909090'}}
                                    inputFocusStyle={{border: "2px solid" , borderColor: '#40196D'}}
                                    onComplete={(value, index) => {
                                         setFilledTP(true)
                                         setTransactionPin(true)
                                    }}
                                    autoSelect={true}
                                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                                      />
                                      <button onClick={handleProceedTP} className={style.proceedPin} disabled={!filledTP} >Proceed</button>
                                  </form>
                              </div>
                        </div>
                      )
                  }

              </div>
          </div>
    </div>
  )
}

export default PersonalInfo