import React,{useEffect, useState} from 'react'
import style from "./settings.module.css";
import Lottie from "lottie-react";
import loader from "../../assets/lottie/loader.json";
import Modal from '../modal/Modal';
import spin_circle from '../../assets/images/Loader anim.png'
import { useDispatch, useSelector } from "react-redux";
import { createCryptoWallet, getCryptoCoins } from '../../store/settings/settingsActions';
import { Copy } from 'iconsax-react';
import cogoToast from 'cogo-toast';

const CryptoCoinsLayout = () => {

    const dispatch = useDispatch();
    const {cryptocoins} = useSelector((state) => state.settings)
   
    const [loading, setLoading] = useState(false)
    const [btnLoader, setBtnLoader] = useState(false)
    const [isOpen, setIsOpen] = useState(false);
    const [coinId, setCoinId] = useState(null)
    const [networkId, setNetworkId] = useState(null)

    const handleGenerateAddress = (id, networkId) =>{
        setIsOpen(true)
        setCoinId(id)
        setNetworkId(networkId)
    }

    const copyRef = (wallet_address) => {
        navigator.clipboard.writeText(wallet_address);
        cogoToast.success("Copied to Clipboard", {
          position: "top-right"
        })
      }

    const generateWallet = () =>{
        setBtnLoader(true)
        dispatch(createCryptoWallet({
            coinTypeId: coinId,
            coinNetworkId: networkId
        })).then((res)=>{
            if(res?.type === "createCryptoWallet/fulfilled"){
                setBtnLoader(false)
                setIsOpen(false)
                dispatch(getCryptoCoins())
            }
            else if(res?.type === "createCryptoWallet/rejected"){
                setBtnLoader(false)
            }
        })
    }

    useEffect(()=>{
        setLoading(true)
        dispatch(getCryptoCoins()).then((res)=>{
            if(res?.type === "getCryptoCoins/fulfilled"){
                setLoading(false)
            }
            else if(res?.type === "getCryptoCoins/rejected"){
                setLoading(false)
            }
        })
    },[dispatch])

    return ( 
        <>

        {/* =========================== THE MODAL TO GENERATE WALLET ADDRESS  */}
      <Modal
        canClose={true}
        modalOpen={isOpen}
        setModalOpen={setIsOpen}
      >
        <div>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '25px'}}>
                <img src={spin_circle} alt="spin circle" className={ btnLoader ? `${style.animate_spin}` : ""} />
            </div>

            <div>
                <h4 style={{color: '#40196D', fontWeight: '600', marginTop: '25px'}}>{btnLoader ? 'Generating Address': 'Generate Address'}</h4>
               {
                btnLoader 
                ?
                <p style={{color: '#B6B6B6', maxWidth:'300px', margin: 'auto', marginTop: '15px'}}>
                  Please wait...<br/>
                 Your wallet address is being generated
                </p>
                :
                <p style={{color: '#B6B6B6', maxWidth:'300px', margin: 'auto', marginTop: '15px'}}>
                     You are about to generate a wallet address for this coin
                </p>
               } 
            </div>

            <button disabled={btnLoader} onClick={() => generateWallet()} style={{marginTop: '20px'}} className={style.save}>
               Continue
            </button>
        </div>

        </Modal>

        <div className={style.header}>
            <h2>Crypto Coins</h2>
            <p style={{marginTop: '10px'}}>
            Manage your unique Crypto wallet address
            </p>


            {(
              loading && 
              <div style={{marginTop: '10px'}} className={style.lottieWrap}>
                <Lottie
                  style={{
                    width: "70px",
                    height: "70px",
                    color: "#fff",
                    padding: "0px",
                    margin: "0px",
                  }}
                  animationData={loader}
                  loop={true}
                />
              </div>
            )}

            <div style={{marginTop: '40px'}}>
                {/* coins list */}
                {
                    cryptocoins.map((val, index)=>{
                        return(
                        <div className={`${index === cryptocoins.length - 1 ? `${style.cryptocoinsHeader}`: `${style.cryptocoinsHeader} ${style.cryptocoinsHeader_border}`}`}>
                            <div>
                                <p style={{color: '#1A1A1A', fontWeight: '500'}} className={style.coinName}>{val.name} ({val.network_name.toUpperCase()})</p>
                               {val.wallet_address && 
                                <div style={{display: 'flex', alignItems: 'center', gap: '10px', marginTop: '3px'}}>
                                        <p>{val.wallet_address.slice(0, 16)}...</p>
                                        <Copy onClick={() => copyRef(val.wallet_address)} style={{cursor: 'pointer', width: '18px', height: '18px'}} />
                                    </div>
                                 }
                            </div>
                            <div>
                                <button
                                disabled={val.isActive}
                                 onClick={()=> handleGenerateAddress(val.id, val.networkId)} className={ val.isActive ? `${style.coinBtn} ${style.coinBtn_generate}` : `${style.coinBtn} ${style.coinBtn_notgenerate}`}>
                                {val.isActive ? "Address Generated": "Generate Address"}
                                </button>
                            </div>
                        </div>  
                    )
                })
                }
            </div>

        </div>
        </>
     );
}
 
export default CryptoCoinsLayout;