import { Copy, Image } from 'iconsax-react';
import React, { useEffect, useRef, useState } from 'react'
import style from './settings.module.css';
import {useDispatch, useSelector} from 'react-redux'
import cogoToast from 'cogo-toast';
import { removePhoto, uploadProfilePic } from '../../store/settings/settingsSlice';
import { baseUrl } from '../../store/config';
import avatar from '../../assets/images/user1.svg'
import profile_deleteImg from '../../assets/images/profile-delete.svg'
import Lottie from "lottie-react";
import loader from "../../assets/lottie/loader.json";
import { updateUserDetails } from '../../store/auth/authActions';
import Modal from '../modal/Modal';
import spin_circle from '../../assets/images/Loader anim.png'
import { deleteProfileAccount } from '../../store/settings/settingsActions';
import { logout } from '../../store/auth/authSlice';
import userImg from '../../assets/images/prompt.svg'


const ProfileInfo = () => {


  const dispatch = useDispatch();
  const fileInputRef = useRef();

  const userData = useSelector(state => state.auth.user)
  const isLoading = useSelector(state => state.auth.loading)
  const email = useSelector(state => state.auth.fullUser.email)
  const refLink = useSelector(state => state.auth.fullUser.referral_code)
  const token = useSelector((state) => state.auth.token);
  const profileImg = useSelector((state) => state.settings.profileImage);
  const [progressLevel, setProgressLevel] = useState(0);
  const [imageLoading, setImageLoading] = useState(false);

  const[firstName, setFirstName] = useState("")
  const[lastName, setLastName] = useState("")
  const[emailAddr, setEmailAddr] = useState("")
  const [phoneNo, setPhoneNo] = useState("")
  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false)


  const refRef = useRef()

    const getToken = () => {
      if (token !== "") {
        return token;
      } else {
        cogoToast.error("Could Not get Token");
      }
  };
  
    const changedHandler = () => {
      let files = fileInputRef.current.files[0];
      setImageLoading(true);
      uploadFile(files);
      console.log(files);
  };
  
  const copyRef = () => {
    let refToCopy = refRef.current
    refToCopy.select();
    navigator.clipboard.writeText(refToCopy.value);
    cogoToast.success("Copied to Clipboard", {
      position: "top-right"
    })
  }

  const uploadFile = (file) => {
    // - the base url plus the api
    const url = `${baseUrl}/misc/upload-file`;
    const xhr = new XMLHttpRequest();
    const fd = new FormData();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    // --(This is for the token passed on headers check yours)
    xhr.setRequestHeader("x-auth-token", `${getToken()}`);

    // Update progress (can be used to show progress indicator)
    xhr.upload.addEventListener("progress", (e) => {
      setProgressLevel(Math.round((e.loaded * 100.0) / e.total));
    });

    xhr.onreadystatechange = (e) => {
      // console.log(xhr)
      if (xhr.readyState === 4 && xhr.status === 201) {
        const response = JSON.parse(xhr.responseText);
        setImageLoading(false);
        cogoToast.success("Image uploaded successfully!", {
          position: "top-right",
        });
        // dispatch and add card images - i dipatched here to my redux store for the response gotten from the backend api
        console.log(response);
        dispatch(uploadProfilePic(response));

        dispatch(updateUserDetails({ firstName, lastName, phoneNo }));
        setProgressLevel(0);


      }
      //   cors errors
      if (xhr.readyState === 4 && xhr.status === 0) {
        console.log(xhr);
        setImageLoading(false);
        cogoToast.warn(
          "There might be an issue with this image, please optimize or try another image."
        );
        setProgressLevel(0);
      }
      if (xhr.status === 413 || xhr.status === 422) {
        cogoToast.warn(
          "There might be an issue with this image, please optimize or try another image."
        );
        setImageLoading(false);
        setProgressLevel(0);
      }
    };

    fd.append("tags", "browser_upload");
    fd.append("file", file);
    xhr.send(fd);
  };

  const handleSaveProfile = (e) => {
    e.preventDefault()
    dispatch(updateUserDetails({firstName, lastName, phoneNo}))
    console.log(
      firstName, lastName, emailAddr, phoneNo
    )
   }

   const handleDeleteProfile = () =>{
    setIsOpen(true)
   }

   const processDelete = () =>{
    setBtnLoader(true)
    dispatch(deleteProfileAccount()).then((res)=>{
      if(res?.type === "deleteProfileAccount/fulfilled"){
          setBtnLoader(false)
          setIsOpen(false)
          dispatch(logout())
      }
      else if(res?.type === "deleteProfileAccount/rejected"){
          setBtnLoader(false)
      }
    })
   }

  useEffect(() => {
    setFirstName(userData ? userData.first_name : "");
    setLastName(userData ? userData.last_name : "")
    setEmailAddr(email ? email : "")
    setPhoneNo(userData ? userData.phone : "");
  }, [userData, email])

  useEffect(() => {
    if (isLoading === true) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [isLoading])
  

  return (
    <>
      {/* =========================== THE MODAL TO DELETE PROFILE  */}
      <Modal
        canClose={true}
        modalOpen={isOpen}
        setModalOpen={setIsOpen}
      >
        <div>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '25px'}}>
                <img src={userImg} alt="spin circle" />
            </div>

            <div>
                <h4 style={{color: '#40196D', fontWeight: '600', marginTop: '25px'}}>Delete Profile</h4>
               
                <p style={{color: '#B6B6B6', maxWidth:'300px', margin: 'auto', marginTop: '15px'}}>
                 Are you sure you want to delete your Astro account?
                </p>
               
            </div>

            <button disabled={btnLoader} onClick={() => processDelete()} style={{marginTop: '20px'}} className={style.save}>
               Proceed
            </button>
        </div>

        </Modal>

    <div className={style.profile}>
      <div className={style.header}>
        <h2>Edit Profile</h2>
      </div>
      <div className={style.photo}>
        <div className={style.image}>
          {imageLoading === true && (
            <div className={style.lottieWrapDrop2}>
              <Lottie
                style={{
                  width: "70px",
                  height: "70px",
                  color: "#fff",
                  padding: "0px",
                  margin: "0px",
                }}
                animationData={loader}
                loop={true}
              />
            </div>
          )}
          <img
            src={
              profileImg && profileImg.length > 0
                ? profileImg[0].publicUrl || profileImg
                : avatar
            }
            alt=""
          />
        </div>
        <button style={{ position: "relative" }} className={style.changePhoto}>
          <Image size="20" variant="Outline" />
          {profileImg.length > 0 ? "Change Photo" : "Add Photo"}
          <input
            type="file"
            ref={fileInputRef}
            accept="image/png, image/jpg, image/jpeg"
            onChange={() => changedHandler()}
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              width: "100%",
              height: "100%",
              background: "skyblue",
              opacity: "0",
            }}
          />
        </button>
        <p
          // onClick={() => {
          //   dispatch(removePhoto());
          // }}
          className={style.remove}
        >
          {/* Remove Photo */}
        </p>
      </div>
      <div className={style.form}>
        <form action="">
          <div className={style.labelPut}>
            <label htmlFor="firstname"> First Name</label>
            <div className={style.input}>
              <input
                type="text"
                name="firstname"
                value={firstName}
                placeholder="Please enter yout First Name"
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
            </div>
          </div>
          <div className={style.labelPut}>
            <label htmlFor="lastname"> Last Name</label>
            <div className={style.input}>
              <input
                type="text"
                name="lastname"
                value={lastName}
                placeholder="Please enter yout First Name"
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
            </div>
          </div>
          <div className={style.labelPut}>
            <label htmlFor="email"> Email Address</label>
            <div className={style.input}>
              <input
                type="text"
                disabled={true}
                name="email"
                value={emailAddr}
                placeholder="Please enter your email"
                onChange={(e) => {
                  setEmailAddr(e.target.value);
                }}
              />
            </div>
          </div>
          <div className={style.labelPut}>
            <label htmlFor="phone_no"> Phone Number</label>
            <div className={style.input}>
              <input
                type="text"
                name="phone_no"
                value={phoneNo}
                placeholder="Please enter yout Phone Number"
                onChange={(e) => {
                  setPhoneNo(e.target.value);
                }}
              />
            </div>
          </div>
          <div className={style.labelPut}>
            <label htmlFor="phone_no"> Referral Link</label>
            <div className={style.input}>
              <input
                type="text"
                ref={refRef}
                name="ref_link"
                value={`https://app.astroafrica.site/signup?ref=${
                  refLink ? refLink : "Loading..."
                  }`}
                disabled
                placeholder="Please enter yout First Name"
                onChange={(e) => {
                  setPhoneNo(e.target.value);
                }}
              />
                <Copy onClick={copyRef} className={style.copyRef} />
            </div>
          </div>
          {loading === true ? (
            <div className={style.lottieWrap}>
              <Lottie
                style={{
                  width: "70px",
                  height: "70px",
                  color: "#fff",
                  padding: "0px",
                  margin: "0px",
                }}
                animationData={loader}
                loop={true}
              />
            </div>
          ) : (
            <button
              type="submit"
              onClick={handleSaveProfile}
              className={style.save}
              disabled={false}
            >
              Save Changes
            </button>
          )}
        </form>

      <div style={{marginTop: '10px'}}>
        <button
              type="submit"
              className={style.save}
              onClick={handleDeleteProfile}
              style={{backgroundColor: '#F7F3FB', color: '#40196D', display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'center'}}
              disabled={false}
            >
              <img style={{width: '20px', height:'20px'}} src={profile_deleteImg} alt="profile icon" />
              Delete Profile
        </button>
        </div>

      </div>
    </div>
    </>
  );
}

export default ProfileInfo