import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../config";
import cogoToast from "cogo-toast";





// ================================================================= VERIFY BVN NUMBER
export const verifyBvn = createAsyncThunk(
    'verifyBvn',
    async ({dob, bvnNumber, middleName}, { rejectWithValue, dispatch, getState }) => {
        const id = getState().auth.user.user_id;
        const name = getState().auth.user.first_name;
        const lastName = getState().auth.user.last_name;
        const code = getState().auth.user.country_tag;
        try { 
            const res = await axios.post(
              `${baseUrl}/kyc/verify-bvn/${id}`,
              {
                firstName: name.toLowerCase(),
                lastName: lastName.toLowerCase(),
                dob: dob,
                bvn: bvnNumber,
                countryCode: code,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  "x-auth-token": getState().auth.token.toString(),
                },
              }
            );
            if (res.status === 200 || res.status === 201) {
                console.log(res)
                cogoToast.success("BVN verified successfully");
                dispatch(getKycStatusById())
            }
        }
        catch (error) {
            if (error.response.status === 401) {
              // dispatch({ type: "auth/Logout" });
              return rejectWithValue(error.response);
            } else if (error.response.status === 400) {
                console.log(error)
              cogoToast.error(error.response.data.message)
              return rejectWithValue(error.response);
            } else if (error.response.status === 401) {
              dispatch({ type: "auth/logout" });
              return rejectWithValue(error.response);
            } else if (error.response.status === 500) {
              cogoToast.error("Error, please try again");
              return rejectWithValue(error.response);
            } else {
              return rejectWithValue(error.response);
            }
        }
    }
)



// ================================================================= GET ALL KYC SETTINGS
export const getAllSettings = createAsyncThunk(
    "getAllSettings",
    async (arg, { rejectWithValue, dispatch, getState }) => {
        const id = getState().auth.user.user_id;
    // const currency = getState().auth.currency;
    console.log(id);
    try {
      const res = await axios.get(`${baseUrl}/kyc/settings`, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token":   getState().auth.token.toString(),
        },
      });
        if (res.status === 200) {
            console.log(res);
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 401) {
        // dispatch({ type: "auth/Logout" });
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);


// ================================================================= GET USER KYC STATUS
export const getKycStatusById = createAsyncThunk(
    "getKycStatusById",
    async (arg, { rejectWithValue, dispatch, getState }) => {
        const id = getState().auth.user.user_id;
    // const currency = getState().auth.currency;
    console.log(id);
    try {
      const res = await axios.get(`${baseUrl}/kyc/user/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token":   getState().auth.token.toString(),
        },
      });
        if (res.status === 200) {
            console.log(res);
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 401) {
        // dispatch({ type: "auth/Logout" });
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

// ================================================================= GET USER DAILY WITHDRAWALS
export const getUserDailyWithdrawal = createAsyncThunk(
    "getUserDailyWithdrawal",
    async (arg, { rejectWithValue, dispatch, getState }) => {
        const id = getState().auth.user.user_id;
    // const currency = getState().auth.currency;
    console.log(id);
    try {
      const res = await axios.get(`${baseUrl}/kyc/user/limit-used/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": getState().auth.token.toString(),
        },
      });
        if (res.status === 200) {
            console.log(res);
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 401) {
        // dispatch({ type: "auth/Logout" });
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

// ================================================================= GET USER CURRENT KYC SETTINGS
export const getKycSettingsById = createAsyncThunk(
    "getKycSettingsById",
    async (arg, { rejectWithValue, dispatch, getState }) => {
        const id = getState().auth.user.user_id;
        const setId = getState().userKyc.kycStats.userkyc.kyc_setting_id;
    // const currency = getState().auth.currency;
    console.log(id);
    try {
      const res = await axios.get(`${baseUrl}/kyc/settings/${setId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": getState().auth.token.toString(),
        },
      });
        if (res.status === 200) {
            console.log(res);
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 401) {
        // dispatch({ type: "auth/Logout" });
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);


// ================================================================= VERIFY IDENTITY
export const verifyIdentity = createAsyncThunk(
  "verifyIdentity",
  async (
    { idNumber, idParam, selfieLink, idLink },
    { rejectWithValue, dispatch, getState }
  ) => {
      const id = getState().auth.user.user_id;
      const code = getState().auth.user.country_tag;
    try {
      const res = await axios.post(
        `${baseUrl}/kyc/verify-id-selfie/${id}`,
        {
          idNumber: idNumber,
          idType: idParam,
          selfieUrl: selfieLink,
          idUrl:idLink,
          countryCode: code,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": getState().auth.token.toString(),
          },
        }
      );
      if (res.status === 200 || res.status === 201) {
        console.log(res);
        cogoToast.success(res.data.message);
        dispatch(getKycStatusById());
      } 
    } catch (error) {
      if (error.response.status === 401) {
        // dispatch({ type: "auth/Logout" });
        return rejectWithValue(error.response);
      } else if (error.response.status === 400) {
        console.log(error);
        cogoToast.error(error.response.data.message);
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else if (error.response.status === 500) {
        cogoToast.error("Error, please try again");
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);


// ================================================================= VERIFY ADDRESS
export const verifyAddress = createAsyncThunk(
  "verifyAddress",
  async ({metadata, verificationUrl, referenceId},
    { rejectWithValue, dispatch, getState }
  ) => {
      const id = getState().auth.user.user_id;
    try {
      const res = await axios.post(
        `${baseUrl}/kyc/address-details/${id}`,
        {
          metadata,
          verificationUrl,
          referenceId
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": getState().auth.token.toString(),
          },
        }
      );
      if (res.status === 200 || res.status === 201) {
        console.log(res);
        cogoToast.success(res.data.message);
        dispatch(getKycStatusById());
      } 
    } catch (error) {
      if (error.response.status === 401) {
        // dispatch({ type: "auth/Logout" });
        return rejectWithValue(error.response);
      } else if (error.response.status === 400) {
        cogoToast.error(error.response.data.message);
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else if (error.response.status === 409) {
        console.log(error);
        cogoToast.error(error.response.data.message);
        return rejectWithValue(error.response);
      } else if (error.response.status === 500) {
        cogoToast.error("Error, please try again");
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);
