import { Icon } from "@iconify/react";
import { CardReceive, CardSend, WalletAdd1 } from "iconsax-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setHeader } from "../../store/settings/settingsSlice";
import Fund from "./Fund";
import Send from "./Send";
import style from "./wallet.module.css";
import WalletCard from "./WalletCard";
import Withdraw from "./Withdraw";
import Lottie from "lottie-react";
import loader from "../../assets/lottie/loader.json";
import {
  fetchWithdrawalSettings,
  getFiatCurrencies,
} from "../../store/wallet/walletActions";
import {
  getBankAccounts,
  getCryptoAccounts,
} from "../../store/settings/settingsActions";
import {
  getKycSettingsById,
  getKycStatusById,
  getUserDailyWithdrawal,
} from "../../store/KYC/kycAction";
import { current } from "@reduxjs/toolkit";

const acctTypes = [
  { id: 1, title: "Savings" },
  { id: 2, title: "Current" },
  { id: 3, title: "Domiciliary" },
  { id: 4, title: "Salary" },
  { id: 5, title: "Fixed deposit" },
  { id: 6, title: "Diaspora" },
];

const actions = [
  // {
  //   id: 1,
  //   title: "Send",
  //   icon: <CardSend size="32" color="#40196D" />,
  // },
  // { id: 2, title: "Withdraw", icon: <CardReceive size="32" color="#40196D" /> },
  // {
  //   id: 3,
  //   title: "Fund",
  //   icon: <WalletAdd1 size="32" color="#40196D" />,
  //   // icon: <Icon className={style.actionCons} icon="bi:send" />,
  // },
  // { id: 4, title: "Trade", icon: <ArrowRotateRight size="32" color='#40196d' /> },
  // { id: 5, title: "Bills", icon: <Icon className={style.actionCons} icon="ph:television-simple" /> },
];

const Wallets = () => {
  const dispatch = useDispatch();

  const [action, setAction] = useState("Withdraw");
  const [dailyLoading, setDailyLoading] = useState(false);
  const [dailyVal, setDailyVal] = useState(0);
  const [dailyWithdrawal, setDailyWithdrawal] = useState(0);
  const [curr, setCurr] = useState("");
  const [multiplier, setMultiplier] = useState(0);
  const [difference, setDifference] = useState(0);
  const [cryptoDifference, setCryptoDifference] = useState(0);

  const isDailyLoading = useSelector((state) => state.userKyc.dailyStatLoading);
  const dailyStat = useSelector((state) => state.userKyc.dailyStat);
  const currentSetting = useSelector((state) => state.userKyc.currentSettings.kycSetting);

  useEffect(() => {
    dispatch(setHeader("Wallets"));
    dispatch(getFiatCurrencies());
    dispatch(getBankAccounts());
    dispatch(getCryptoAccounts());
    dispatch(fetchWithdrawalSettings());
    dispatch(getUserDailyWithdrawal());
    dispatch(getKycStatusById());
    dispatch(getKycSettingsById());
  }, []);

  useEffect(() => {
    if (isDailyLoading === true) {
      setDailyLoading(true);
    } else {
      setDailyLoading(false);
    }
  }, [isDailyLoading]);

  useEffect(() => {
    setDailyVal(dailyStat.userwithdrawndaily ? dailyStat.userwithdrawndaily : 0);
    setDailyWithdrawal(currentSetting ? currentSetting.daily_withdrawer_limit : 0);
  }, [
    curr,
    multiplier,
    dailyStat.userwithdrawndaily ? dailyStat.userwithdrawndaily : null,
    currentSetting && currentSetting.daily_withdrawer_limit,
  ]);

  useEffect(() => {
    setDifference(
      multiplier > 0
        ? dailyWithdrawal * parseFloat(multiplier) -
            dailyVal * parseFloat(multiplier)
        : 0
    );
    console.log(difference);
  }, [curr, multiplier]);

  useEffect(() => {
    setCryptoDifference(multiplier > 0 ? dailyWithdrawal - dailyVal : 0);
    console.log(difference);
  }, [curr, multiplier]);

  return (
    <div className={style.wallet}>
      <div className={style.left}>
        <div className={style.leftWrap}>
          <div className={style.top}>{/* <h5>Wallet Balance</h5> */}</div>

          <WalletCard />

          {/* THE DISPLAY OF THE WITHDRAWAL LIMITS */}
          {/* {dailyLoading === true ? (
            <div className={style.lottieWrap}>
              <Lottie
                style={{
                  width: "70px",
                  height: "70px",
                  color: "#fff",
                  padding: "0px",
                  margin: "0px",
                }}
                animationData={loader}
                loop={true}
              />
            </div>
          ) : (
            <div className={style.limit}>
              <div className={style.dailyLimit}>
                <p>Daily Limit:</p>
                <span>
                  {new Intl.NumberFormat("NG", {
                    style: "currency",
                    currency: curr ? curr : "USD",
                  }).format(
                    multiplier > 0
                      ? dailyWithdrawal * parseFloat(multiplier)
                      : currentSetting ? currentSetting.daily_withdrawer_limit : 0
                  )}
                </span>
              </div>
              <hr />
              <div className={style.dailyLimit}>
                <p>Withdrawn today:</p>
                <span>
                  {new Intl.NumberFormat("NG", {
                    style: "currency",
                    currency: curr ? curr : "USD",
                  }).format(
                    multiplier > 0
                      ? dailyVal * parseFloat(multiplier)
                      : dailyStat.userwithdrawndaily ? dailyStat.userwithdrawndaily : 0
                  )}
                </span>
              </div>
            </div>
          )} */}
          {/* <div className={style.quickActions}>
            <div className={style.qWrapper}>
              {actions &&
                actions.map((item) => (
                    <div
                        onClick={() => {
                            setAction(item.title)
                        }}
                        key={item.id} className={style.qAction}>
                    <div
                      style={{
                        border:
                          action === item.title
                            ? "1px #40196D solid"
                            : "1px rgba(64, 25, 109, 0.1) solid",
                      }}
                      className={style.qIconWrap}
                    >
                      {item.icon}
                    </div>
                    <p>{item.title}</p>
                  </div>
                ))}
            </div>
          </div> */}

          {action === "Send" && <Send />}
          {action === "Withdraw" && (
            <Withdraw
              setMultiplier={setMultiplier}
              difference={difference}
              cryptoDifference={cryptoDifference}
              setCurr={setCurr}
            />
          )}
          {action === "Fund" && <Fund />}
        </div>
      </div>
    </div>
  );
};

export default Wallets;
